<template>
  <div class="custom-pagination">
    <el-pagination
      :current-page="currentPage || 1"
      :page-size="pageSize || 10"
      :total="total || 0"
      :layout="layout"
      :page-sizes="pageSizes"
      @current-change="handlePageChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'

// 接收外部传入的 props
const props = defineProps<{
  total: number
  pageSize: number
  currentPage: number
}>()

const emit = defineEmits<{
  (e: 'page-change', page: number): void
  (e: 'size-change', size: number): void
}>()

const layout = ref('total, sizes, ->, prev, pager, next, jumper')
const pageSizes = ref([10, 20, 30, 50])

const handleSizeChange = (size: number) => {
  emit('size-change', size)
}
// 处理页码变化
const handlePageChange = (page: number) => {
  emit('page-change', page)
}
</script>

<style scoped>
.custom-pagination {
  margin-top: 20px;
  width: 100%;
}
</style>
