import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ElMessage, type MessageBoxState } from 'element-plus'

import { type GenerateVideoTask, regenerateVideoTask, cancelGenerateVideoTask } from '@/api'
import { costQuotasMap } from '@/config'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { showSubmitConfirm } from '@/utils/creditsCostConfirm'

export const useClips2VideoOpts = (item: GenerateVideoTask) => {
  const { t } = useI18n()
  const userStore = useUserStore()
  const projStore = useProjStore()

  const submitId = ref('')
  const cancelTask = async () => {
    submitId.value = item.id

    try {
      const res = await cancelGenerateVideoTask({
        id: item.id,
        projId: item.projId
      })
      if (res.data.code) {
        ElMessage.error(res.data.message || t('取消异常'))
        return
      }
      ElMessage.success(res.data.message || t('取消成功'))
      item.state = res.data.data.state
    } catch (err: any) {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('取消失败'))
    } finally {
      submitId.value = ''
    }
  }

  const redoTask = async () => {
    if (submitId.value) {
      return
    }

    const costQuotas = costQuotasMap['clips-to-videos']

    if (costQuotas <= 0) {
      doCreateAction(costQuotas)
      return
    }

    if (projStore.total_remaining < costQuotas) {
      submitId.value = item.id
      await fetchSubscriptionPlanAndCreditDetails(projStore)
      submitId.value = ''
    }

    showSubmitConfirm(
      {
        title: t('将剪辑提交到视频任务'),
        costQuotas,
        totalRemaining: projStore.total_remaining,
        feedbackTypes: userStore.feedbackTypes,
        from: 'clips-to-videos-redo'
      },
      {
        beforeClose(action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true

            doCreateAction(costQuotas, instance, done)
          } else {
            done()
          }
        }
      }
    )
  }
  const doCreateAction = (costQuotas: number, instance?: MessageBoxState, done?: () => void) => {
    submitId.value = item.id
    regenerateVideoTask({
      id: item.id,
      projId: item.projId
    })
      .then((res) => {
        if (res.data.code) {
          ElMessage.error(res.data.message || t('重新生成异常'))
          return
        }
        ElMessage.success(res.data.message || t('重新生成成功'))
        item.state = res.data.data.state
        item.createTime = res.data.data.createTime
        if (costQuotas > 0) {
          projStore.$patch({
            total_remaining: projStore.total_remaining - costQuotas
          })
          fetchSubscriptionPlanAndCreditDetails(projStore)
        }
        if (done) {
          done()
        }
      })
      .catch((err) => {
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          return
        }
        ElMessage.error(err.message || t('重新生成失败'))
      })
      .finally(() => {
        if (instance) {
          instance.confirmButtonLoading = false
        }

        submitId.value = ''
      })
  }

  return { cancelTask, redoTask }
}
