<script setup lang="ts">
import LoadingV2 from '@/views/Dashboard/components/LoadingV2.vue'

defineProps<{ description?: string }>()
</script>

<template>
  <div class="loading-animation-global">
    <LoadingV2 />
    <div>loading...</div>
  </div>
</template>

<style scoped lang="less">
.loading-animation-global {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000; // top
  background-color: white;

  width: 100%;
  height: 100%;
  //height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: var(--my-color-black-50);
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
