<script setup lang="ts">
import { type Component, defineProps, ref } from 'vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import IconClips2Video from '@/components/icons/IconClips2Video.vue'
import IconVT from '@/components/icons/IconVT.vue'
import VideoPlayMask from '@/views/Dashboard/components/VideoPlayMask.vue'
import VideoPreview from '@/views/Dashboard/components/VideoPreview.vue'
import router from '@/router'
import IconTagNew from '@/components/icons/IconTagNew.vue'
import IconTagHot from '@/components/icons/IconTagHot.vue'

// 定义 props 类型
const props = defineProps<{
  imageUrl: string
  link: string
  title: string
  description: string
  icon?: string
  introVideoUrl?: string
  tag?: 'new' | 'hot' //
  type: number // 1: c2v, 2: vt
}>()

const showPreview = ref(false)

const typeIconMap = ref<Record<string, Component>>({
  1: IconClips2Video,
  2: IconVT
})

const exploreTextMap = ref<Record<string, string>>({
  1: 'Explore Clips To Videos',
  2: 'Explore Video Translation'
})

const { isMobile } = useClientInfo()

const handleExplore = () => {
  router.push({ path: props.link })
}

const handleProductClick = () => {
  // router.push({ path: props.link })
  showPreview.value = true
}
</script>

<template>
  <div class="product-item" :class="{ mobile: isMobile }" @click="handleProductClick">
    <div class="thumbnail" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
      <div :class="`tag ${tag}`" v-if="tag">
        <IconTagNew v-if="tag === 'new'" />
        <IconTagHot v-if="tag === 'hot'" />
      </div>
      <!--      <VideoPlayMask @click.stop="" v-if="introVideoUrl" />-->
    </div>
    <div class="intro">
      <div class="title">
        <component :is="icon || typeIconMap[type]" alt="" class="icon" :key="type" />
        {{ title }}
      </div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
  <VideoPreview
    v-if="showPreview"
    :title="title"
    :show="showPreview"
    :video-url="introVideoUrl"
    @close="showPreview = false"
    :description="description"
    object-fit="cover"
    :styles="{
      width: '100%',
      aspectRatio: '1.77'
    }"
  >
    <template #footer>
      <el-button type="primary" @click="handleExplore">Explore {{ title }}</el-button>
    </template>
  </VideoPreview>
</template>

<style scoped lang="less">
.product-item {
  width: 256px;
  //width: 24%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  .thumbnail {
    width: 100%;
    aspect-ratio: calc(256 / 160);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    .tag {
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;

      &.hot {
        width: 68px;
      }

      &.new {
        width: 58px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .intro {
    height: 88px;
    box-sizing: border-box;
    padding: 14px 12px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }

    .description {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mobile.product-item {
  width: 100%;

  .thumbnail {
    .tag {
      &.hot {
        width: 92px;
      }

      &.new {
        width: 74px;
      }
    }
  }
}
</style>
