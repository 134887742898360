<script setup lang="ts"></script>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5002 10.2165C13.7857 10.5021 13.7857 10.9651 13.5002 11.2507C13.2146 11.5362 12.7516 11.5362 12.466 11.2507L9.00018 7.78481L5.53432 11.2507C5.24875 11.5362 4.78575 11.5362 4.50018 11.2507C4.21461 10.9651 4.21461 10.5021 4.50018 10.2165L8.61425 6.10245C8.82739 5.88931 9.17296 5.88931 9.38611 6.10245L13.5002 10.2165Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="less"></style>
