<script setup lang="ts">
import type { GoodsInfo } from '@/api'
import { getGoodsMonthlyPrice } from '../../../utils/price'
import i18nHome from '@/locales/i18n-home'

defineProps<{ goodsInfo: GoodsInfo }>()
const { t } = i18nHome.global
</script>

<template>
  <div class="price-wrapper-with-cutoff">
    <div class="price-pay">
      <div class="price">
        <small>{{ goodsInfo.packageConfig.currencySymbol }}</small
        >{{ getGoodsMonthlyPrice(goodsInfo.price, goodsInfo.packagePeriod) }}
      </div>
      <div class="period">/{{ t('mo') }}</div>
    </div>
    <div class="price-origin" v-if="goodsInfo.packageConfig?.originPrice">
      {{ goodsInfo.packageConfig.currencySymbol
      }}{{ getGoodsMonthlyPrice(goodsInfo.packageConfig.originPrice, goodsInfo.packagePeriod) }}
    </div>
  </div>
</template>

<style scoped lang="less">
.price-wrapper-with-cutoff {
  height: 48px;
  margin: 20px 0 16px;
  display: flex;
  align-items: baseline;

  .price-pay {
    display: flex;
    align-items: baseline;
    gap: 4px;

    .price {
      font-size: 44px;
      font-weight: 700;
      line-height: 48px;

      small {
        font-size: 30px;
      }
    }

    .period {
      font-family:
        SF Pro Text,
        var(--el-font-family);
      font-size: 22px;
      font-weight: 500;
      line-height: 22px;
      color: #999999;
      white-space: nowrap;
    }
  }

  .price-origin {
    margin-left: 16px;
    color: rgba(#000, 0.5);
    font-size: 20px;
    text-decoration: line-through;
  }
}
</style>
