<script setup lang="ts"></script>

<template>
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110.122 58.7751C117.383 63.3175 116.237 74.2252 108.19 77.1587L19.5245 109.482C12.3802 112.087 4.97734 106.306 5.7722 98.7435L14.4302 16.3681C15.2251 8.8055 23.668 4.69032 30.1147 8.72331L110.122 58.7751Z"
      fill="#EAEAEA"
    />
    <mask
      id="mask0_12225_16455"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="5"
      y="0"
      width="120"
      height="111"
    >
      <path
        d="M124.005 60.081C126.177 65.4746 123.449 71.5948 117.987 73.5863L19.5235 109.482C12.3792 112.086 4.97639 106.305 5.77125 98.7428L15.1227 9.77026C15.6978 4.29825 20.4774 0.247887 25.9696 0.578255L95.3915 4.75412C99.3574 4.99268 102.831 7.49401 104.315 11.1796L124.005 60.081Z"
        fill="#4E6AFF"
      />
    </mask>
    <g mask="url(#mask0_12225_16455)">
      <path
        d="M25.1375 25.1354C25.3198 21.8268 28.1499 19.2924 31.4586 19.4747L81.0761 22.2095C84.3847 22.3919 86.9191 25.2219 86.7368 28.5306L82.8986 98.1664C82.7163 101.475 79.8862 104.01 76.5775 103.827L26.96 101.092C23.6514 100.91 21.117 98.0799 21.2993 94.7713L25.1375 25.1354Z"
        fill="#D0D0D0"
      />
      <path
        d="M31.7409 100.933L78.4174 105.838C80.7771 106.086 82.8892 104.37 83.1292 102.01L86.5714 68.1454L66.8571 72.0025L45.4285 65.5739L28.2857 68.574L25.6392 93.3856C25.2371 97.1555 27.9704 100.536 31.7409 100.933Z"
        fill="#F9F9F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.5273 79.6424C39.6263 78.7008 40.4698 78.0177 41.4114 78.1167L59.3128 79.9982C60.2544 80.0972 60.9375 80.9407 60.8385 81.8823C60.7396 82.8239 59.896 83.507 58.9544 83.408L41.053 81.5265C40.1115 81.4275 39.4284 80.584 39.5273 79.6424Z"
        fill="#CCCCCC"
      />
      <path
        d="M46.805 59.8432L67.8287 69.3191L87 66.8578L90.3902 36.538C90.813 32.7567 88.0763 29.3542 84.2923 28.9564L40.1619 24.3181C36.4078 23.9236 33.0409 26.6372 32.6289 30.3894L29.1428 62.144L46.805 59.8432Z"
        fill="#F9F9F9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.4729 40.9119C43.5719 39.9703 44.4154 39.2873 45.357 39.3862L74.3402 42.4325C75.2818 42.5314 75.9649 43.375 75.8659 44.3166C75.767 45.2582 74.9234 45.9412 73.9819 45.8423L44.9986 42.796C44.0571 42.697 43.374 41.8535 43.4729 40.9119Z"
        fill="#CCCCCC"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.3078 50.2791C42.4067 49.3375 43.2503 48.6544 44.1918 48.7534L73.1751 51.7997C74.1166 51.8986 74.7997 52.7422 74.7008 53.6838C74.6018 54.6253 73.7583 55.3084 72.8167 55.2095L43.8335 52.1632C42.8919 52.0642 42.2088 51.2207 42.3078 50.2791Z"
        fill="#CCCCCC"
      />
    </g>
    <path
      d="M117.429 13.7137C117.429 11.8202 115.894 10.2852 114 10.2852H99.4286C97.535 10.2852 96 11.8202 96 13.7137V24.8566C96 26.7501 97.535 28.2852 99.4286 28.2852H102V30.8851C102 31.5861 102.796 31.9907 103.362 31.5776L107.878 28.2852H114C115.894 28.2852 117.429 26.7501 117.429 24.8566V13.7137Z"
      fill="#D0D0D0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.169 15.8289C103.918 15.5779 103.511 15.5779 103.26 15.8289C103.009 16.08 103.009 16.487 103.26 16.7381L106.019 19.4978L103.26 22.2575C103.009 22.5085 103.009 22.9156 103.26 23.1666C103.511 23.4177 103.918 23.4177 104.169 23.1666L106.929 20.4069L109.688 23.1666C109.939 23.4177 110.346 23.4177 110.597 23.1666C110.848 22.9156 110.848 22.5085 110.597 22.2575L107.838 19.4978L110.597 16.7381C110.848 16.487 110.848 16.08 110.597 15.8289C110.346 15.5779 109.939 15.5779 109.688 15.8289L106.929 18.5886L104.169 15.8289Z"
      fill="white"
    />
  </svg>
</template>

<style scoped lang="less"></style>
