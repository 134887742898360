<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import TaskList from '@/views/Dashboard/components/TaskList.vue'
import QueryFilter, {
  type FilterValue
} from '@/views/Dashboard/pages/TasksView/modules/QueryFilter.vue'
import { getGenerateTaskList, type GetTaskListReq, type VideoTranslateTask } from '@/api'
import { ElMessage } from 'element-plus'
import { useProjStore } from '@/stores/proj'
import BizPager from '@/views/Dashboard/components/BizPager.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { type UseScrollReturn } from '@vueuse/core'
import { vScroll } from '@vueuse/components'
import LoadingMoreAnimation from '@/views/Dashboard/components/LoadingMoreAnimation.vue'
import TaskListSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TaskListSkeleton.vue'
import { useI18n } from 'vue-i18n'
import LoadErrorRetry from '@/views/Dashboard/components/LoadErrorRetry.vue'
import { useTaskList } from '@/views/Dashboard/hooks/useTaskList'

const projStore = useProjStore()
const productFilter = ref<FilterValue>({
  product: '',
  status: ''
})

const scrollContainer = ref<HTMLElement>()
const { isMobile } = useClientInfo()

const page = reactive<{ total?: number; pageSize: number; currentPage: number }>({
  pageSize: 10,
  currentPage: 1
})

const { tasks, fetchData, isLoading, loadError, isLoadingMore, loadMore, total, hasMore } =
  useTaskList()

const resetPage = () => {
  Object.assign(page, {
    pageSize: 10,
    currentPage: 1
  })
}

const handleFetchData = async () => {
  return await fetchData({
    ...commonQueryParams.value,
    currentPage: page.currentPage
  })
}

const handleFilterChange = (option: FilterValue) => {
  if (isLoading.value) {
    return
  }
  if (JSON.stringify(productFilter.value) === JSON.stringify(option)) {
    return
  }

  productFilter.value = option

  resetPage()
  handleFetchData()
}

const handleSizeChange = () => {
  resetPage()
  handleFetchData()
}
const handlePageChange = (p: number) => {
  if (isLoading.value) {
    return
  }

  if (page.currentPage === p) {
    return
  }

  page.currentPage = p
  handleFetchData()
}

const commonQueryParams = computed<
  Pick<GetTaskListReq, 'projId' | 'product' | 'state' | 'pageSize' | 'order' | 'orderBy'>
>(() => {
  return {
    projId: projStore.projId,
    product: productFilter.value.product,
    state: productFilter.value.status,
    pageSize: page.pageSize,
    orderBy: 'id',
    order: 'desc'
  }
})

// 移动端置地loadmore
const onScroll = (state: UseScrollReturn) => {
  if (isMobile && state.arrivedState.bottom) {
    loadMore({
      ...commonQueryParams.value,
      currentPage: page.currentPage + 1
    })
  }
}

onMounted(() => {
  handleFetchData()
})
</script>

<template>
  <div class="tasks-view-container" :class="{ mobile: isMobile }" v-scroll="onScroll">
    <div class="filter-container">
      <QueryFilter :value="productFilter" @change="handleFilterChange" />
    </div>
    <div class="task-area" ref="scrollContainer">
      <TaskListSkeleton :length="10" v-if="isLoading" />
      <LoadErrorRetry :on-retry="handleFetchData" v-else-if="loadError" />
      <TaskList :tasks="tasks" v-else />
    </div>
    <div class="page-container" v-if="!isMobile">
      <BizPager
        :total="total || 0"
        :page-size="page.pageSize || 10"
        :current-page="page.currentPage || 1"
        @page-change="handlePageChange"
        @size-change="handleSizeChange"
      />
    </div>
    <div v-if="isMobile && !isLoading">
      <LoadingMoreAnimation :loading="isLoadingMore" :has-more="hasMore" />
    </div>
  </div>
</template>

<style scoped lang="less">
.tasks-view-container {
  height: 100%;
  overflow: scroll;
  margin: 0 40px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
  }

  --filter-height: 86px;

  .filter-container {
    height: var(--filter-height);
    margin-bottom: 28px;
    position: absolute;
    z-index: 2;
    background-color: white;
    width: 100%;
  }

  .task-area {
    padding-top: var(--filter-height);
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-container {
    height: 50px;
    display: flex;
    align-items: center;
  }
}

.mobile.tasks-view-container {
  overflow: auto;
  display: block;
  margin: 0;
  padding: 0 16px;
}
</style>
