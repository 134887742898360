<script setup lang="ts">
defineProps<{ withRightAction?: boolean }>()
</script>

<template>
  <div class="skeleton-title">
    <el-skeleton animated>
      <template #template>
        <el-skeleton-item style="height: 20px; width: 134px" />
        <el-skeleton-item style="height: 20px; width: 58px; float: right" v-if="withRightAction" />
      </template>
    </el-skeleton>
  </div>
</template>

<style scoped lang="less">
.skeleton-title {
  height: 20px;
  overflow: hidden;
  margin-bottom: 24px;
}
</style>
