<script lang="ts" setup>
import { RouterView, useRoute } from 'vue-router'
import DashboardMenu from '@/views/Dashboard/components/DashboardMenu.vue'
import DashboardTopbar from '@/views/Dashboard/components/DashboardTopbar.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { useUserStore } from '@/stores/user'
import { inject, onMounted, ref } from 'vue'
import router from '@/router'
import { vScroll } from '@vueuse/components'
import type { UseScrollReturn } from '@vueuse/core'
import { useSignCheck } from '@/views/Dashboard/hooks/useSignCheck'
import GloablLoadingAnimation from '@/views/Dashboard/components/GloablLoadingAnimation.vue'
import NeedHelp from '@/components/NeedHelp.vue'

const route = useRoute()
const { isMobile } = useClientInfo()
const userStore = useUserStore()
const menuShowShadow = ref(false)
const cachedViews = inject<any>('cachedViews')
const { checkSign } = useSignCheck()

const onScroll = (state: UseScrollReturn) => {
  menuShowShadow.value = !state.arrivedState.top
}

const check = async () => {
  if (!userStore.id) {
    try {
      await checkSign()
    } catch (err: any) {
      if (!userStore.id) {
        // 因为全局有一个getSigned，所以跳转前还是要再检查一次
        router.push({ name: 'signin', params: { from: route.fullPath } })
      }
    }
  }
}

onMounted(() => {
  if (!userStore.id) {
    setTimeout(() => {
      check()
    }, 3000)
  }
})
</script>

<template>
  <GloablLoadingAnimation v-if="!userStore.id" />
  <div id="home-root" class="home-container" :class="{ mobile: isMobile }" v-else>
    <DashboardMenu />
    <NeedHelp />
    <div class="main-content">
      <DashboardTopbar :menu-show-shadow="menuShowShadow"></DashboardTopbar>
      <div class="route-view" v-scroll="onScroll">
        <RouterView v-slot="{ Component }">
          <KeepAlive :include="cachedViews">
            <component :is="Component" :key="route.path" v-if="route.meta.keepAliveView" />
          </KeepAlive>
          <component :is="Component" v-if="!route.meta.keepAliveView" />
        </RouterView>
      </div>
    </div>
  </div>
</template>

<!--框架样式-->
<style lang="less" scoped>
.home-container {
  position: fixed;
  display: flex;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .home-menu {
    flex-basis: 200px;
    height: 100%;
  }

  .main-content {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .route-view {
      flex: 1;
      overflow-y: auto;
      /* 在 Webkit 浏览器（如 Chrome 和 Safari）中 */

      &::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
      }
    }
  }
}

.mobile.home-container {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
</style>
