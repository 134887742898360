<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type GenerateVideoTask } from '@/api'
import { useProjStore } from '@/stores/proj'
import IconPoints from '@/components/icons/IconPoints.vue'
import { type TaskOpConfig, useTaskInfo } from '@/views/Dashboard/hooks/useTaskInfo'
import { useEventBus } from '@vueuse/core'
import { EventBusType } from '@/constrants/vars'

const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()
const { t } = useI18n()

const showMenu = ref(false)

const regenerateBus = useEventBus<string>(EventBusType.regenerateTask)

// 定义 props 类型
const props = defineProps<{
  item: GenerateVideoTask
}>()

const { optConfigs } = useTaskInfo(props.item)

const handleOp = async (e: Event, c: TaskOpConfig) => {
  await c.op(e)

  if (c.type === 'regenerate') {
    regenerateBus.emit()
  }
}
</script>

<template>
  <el-dropdown
    class="task-op-container"
    @mouseenter="showMenu = true"
    @mouseleave="showMenu = false"
    popper-class="task-op-sub-menu"
    placement="bottom-end"
    v-if="optConfigs?.length"
  >
    <IconPoints />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="c in optConfigs"
          :key="c.name"
          @click="(e: any) => handleOp(e, c)"
          >{{ c.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style lang="less">
.task-op-container {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .dropdown-menu {
    position: absolute;
    top: 100%; /* 在头像下方显示 */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* 确保菜单在其他元素上方 */
  }

  svg:focus {
    outline: none !important;
  }
}

.task-op-sub-menu {
  // override elements style
  --el-dropdown-menuItem-hover-fill: #f3f3f3;

  .base-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .email {
      color: #999;
    }
  }
}
</style>
