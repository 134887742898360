<script setup lang="ts">
import IconLoadingMore from '@/assets/dashboard/loading-blue.png'

import { useI18n } from 'vue-i18n'

defineProps<{ loading?: boolean; hasMore?: boolean }>()
const { t } = useI18n()
</script>

<template>
  <div class="loading-more-animation">
    <template v-if="loading"><img :src="IconLoadingMore" alt="" /> {{ t('加载中') }}...</template>
    <template v-else-if="!loading && !hasMore">{{ t('没有更多') }}</template>
    <template v-else>{{ t('加载更多') }}</template>
  </div>
</template>

<style scoped lang="less">
.loading-more-animation {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 14px;
  color: var(--my-color-black-40);

  img {
    width: 16px;
    height: 16px;
    animation: loading-rotate 1s linear infinite;
    transform-origin: center;
    margin-right: 4px;
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
