<script setup lang="ts"></script>

<template>
  <div class="swiper-skeleton-container">
    <el-skeleton animated>
      <template #template>
        <el-skeleton-item style="height: 220px" />
      </template>
    </el-skeleton>
  </div>
</template>

<style scoped lang="less">
.swiper-skeleton-container {
  width: 100%;
  height: 220px;
}
</style>
