import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  createWebHashHistory,
  type RouteRecordRaw
} from 'vue-router'
import { config, setMenuPath } from '@/config'
import i18n from '@/locales/i18n'

import HomeView from '../views/HomeView.vue'
import PricingView from '@/views/PricingView.vue'

import CreateVideoView from '@/views/CreateVideoView.vue'
import GenerateTaskDetailsView from '@/views/GenerateTaskDetailsView.vue'
import VideoTranslationView from '@/views/VideoTranslationView.vue'
import VideoTranslationTasksView from '@/views/VideoTranslationTasksView.vue'

import SignInView from '@/views/SignInView.vue'
import SignUpView from '@/views/SignUpView.vue'
import DashboardLayout from '@/views/Dashboard/DashboardLayout.vue'
import DashboardView from '@/views/Dashboard/pages/DashboardView/DashboardView.vue'
import MainLayout from '@/views/MainLayout.vue'
import TasksView from '@/views/Dashboard/pages/TasksView/TasksView.vue'

const { t } = i18n.global

export const routeNameMap = {
  home: 'home',
  oldTaskList: 'vmeg.my-tasks',
  vtTaskList: 'vmeg.video-translation.my-tasks',
  pricing: {
    path: '/pricing',
    name: 'pricing'
  },
  adminUserProjects: {
    path: '/admin/user-projects',
    name: 'admin.user-projects'
  },
  adminRedeemManage: {
    path: '/admin/redeem-manage',
    name: 'admin.redeem-manage'
  },
  dashboardHome: {
    path: '/home',
    name: 'dashboard'
  },
  dashboardTasks: {
    path: '/home/my-tasks',
    name: 'tasks'
  },
  dashboardTaskDetail: {
    path: '/home/my-tasks/:projId/:taskId',
    name: 'vmeg.my-tasks.details'
  },
  createVideo: {
    path: '/create-video/:projId',
    name: 'vmeg.create-video'
  },
  videoTranslation: {
    path: '/video-translation/:projId',
    name: 'vmeg.video-translation'
  },
  subscriptionPlan: {
    path: '/subscription-plan/:projId',
    name: 'vmeg.subscription-plan'
  },
  creditList: {
    path: '/credit-details/:projId',
    name: 'vmeg.credit-details'
  },
  user: {
    path: '/user/:from',
    name: 'user'
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: routeNameMap.home,
        meta: {
          title: () => t('首页'),
          intro: () => t('无限AI视频，释放营销想象'),
          bodyClasses: ['width-1120-full-layout'],
          hideMenu: true
        },
        component: HomeView
      },
      {
        path: '/my-tasks/:projId',
        name: routeNameMap.oldTaskList,
        redirect: { name: 'tasks' } // v2版本更新了路由，但是为了避免直接下掉以后用户的老链接不可用，所以使用重定向来过渡=
      },
      {
        path: '/my-tasks/:projId/:taskId',
        redirect: (to) => {
          return {
            path: `/home/my-tasks/${to.params.projId}/${to.params.taskId}`
          }
        }
      },
      {
        path: '/video-translation-tasks/:projId',
        alias: '/video-translation-tasks/:projId/:taskId/:requestId', // requestId 用于关联邮件
        name: routeNameMap.vtTaskList,
        redirect: () => {
          return {
            path: `/home/my-tasks`
          }
        }
      },
      {
        path: routeNameMap.pricing.path,
        name: routeNameMap.pricing.name,
        meta: {
          title: () => t('定价'),
          bodyClasses: ['width-1120-full-layout']
        },
        component: PricingView
      },
      {
        path: routeNameMap.adminUserProjects.path,
        name: routeNameMap.adminUserProjects.name,
        meta: {
          title: () => '管理员-用户项目',
          keepAliveView: 'AdminUserProjectsView',
          requiresAuth: true
        },
        component: () => import('@/views/AdminUserProjectsView.vue')
      },
      {
        path: routeNameMap.adminRedeemManage.path,
        name: routeNameMap.adminRedeemManage.name,
        meta: {
          title: () => '管理员-兑换项目',
          keepAliveView: 'RedeemManageView',
          requiresAuth: true
        },
        component: () => import('@/views/RedeemManageView.vue')
      },
      {
        path: '/error/:message',
        name: 'error',
        meta: {
          title: () => t('异常信息')
        },
        component: () => import('@/views/ErrorView.vue')
      },
      {
        path: '/terms-of-service',
        name: 'terms-of-service',
        meta: {
          title: () => t('服务条款')
        },
        component: () => import('@/views/TermsOfServiceView.vue')
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        meta: {
          title: () => t('隐私政策')
        },
        component: () => import('@/views/PrivacyPolicyView.vue')
      },
      {
        path: '/signin/:from',
        alias: '/signin',
        name: 'signin',
        meta: {
          title: () => t('登录'),
          showAsideLarge: true
        },
        component: SignInView
      },
      {
        path: '/signout',
        name: 'signout',
        component: SignInView
      },
      {
        path: '/signup/:from',
        alias: '/signup',
        name: 'signup',
        meta: {
          title: () => t('注册'),
          showAsideLarge: true
        },
        // component: () => import('@/views/SignUpView.vue')
        component: SignUpView
      },
      {
        path: '/code-redeem/:codeType?/:code?/',
        alias: '/code-redeem',
        name: 'code-redeem',
        meta: {
          // showAside: true,
          title: () => t('权益兑换'), // TODO：确认文案
          bodyClasses: ['single-page-layout', 'without-header'],
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (UserView.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/ReedemView.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'dashboardRoot',
    component: DashboardLayout,
    meta: {
      // root配置了下面的会检索matched route来判断是否需要鉴权
      requiresAuth: true
    },
    children: [
      {
        path: routeNameMap.dashboardHome.path,
        name: routeNameMap.dashboardHome.name,
        meta: {
          title: () => t('控制台')
        },
        component: DashboardView
      },
      {
        path: routeNameMap.dashboardTasks.path,
        name: routeNameMap.dashboardTasks.name,
        meta: {
          title: () => t('我的任务')
        },
        component: TasksView
      },
      {
        path: routeNameMap.dashboardTaskDetail.path,
        name: routeNameMap.dashboardTaskDetail.name,
        alias: '/my-tasks/:projId/:taskId/:requestId', // requestId 用于关联邮件
        meta: {
          title: () => t('任务详情')
        },
        component: GenerateTaskDetailsView
      },
      {
        path: routeNameMap.createVideo.path,
        name: routeNameMap.createVideo.name,
        meta: {
          title: () => t('切片到视频'),
          keepAliveView: CreateVideoView.__name
        },
        component: CreateVideoView
      },
      {
        path: routeNameMap.videoTranslation.path,
        name: routeNameMap.videoTranslation.name,
        meta: {
          title: () => t('视频翻译'),
          keepAliveView: VideoTranslationView.__name
        },
        component: VideoTranslationView
      },
      {
        path: routeNameMap.subscriptionPlan.path,
        name: routeNameMap.subscriptionPlan.name,
        meta: {
          title: () => t('订阅计划')
        },
        component: () => import('@/views/SubscriptionPlanListView.vue')
      },
      {
        path: routeNameMap.creditList.path,
        name: routeNameMap.creditList.name,
        meta: {
          title: () => t('积分详情')
        },
        component: () => import('@/views/CreditDetailListView.vue')
      },
      {
        path: routeNameMap.user.path,
        name: routeNameMap.user.name,
        alias: '/user',
        meta: {
          title: () => t('个人资料')
        },
        component: () => import('@/views/UserView.vue')
      }
    ]
  }
]

setMenuPath(routes)

const router = createRouter({
  history: import.meta.env.SSR
    ? createMemoryHistory()
    : config.useHashHistory
      ? createWebHashHistory()
      : createWebHistory(import.meta.env.BASE_URL),
  routes
})

export const getDefaultStartRoute = () => {
  return routeNameMap.dashboardHome
}

export default router
