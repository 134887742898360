<script setup lang="ts"></script>

<template>
  <div class="main-title"><slot></slot></div>
</template>

<style scoped lang="less">
*,
.title,
.main-title {
  font-family:
    SF Pro Display,
    var(--el-font-family);
}

//.subtitle {
//  font-family:
//    SF Pro,
//    var(--el-font-family);
//}

.main-title {
  padding: 80px 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
  color: var(--my-color-black-89-90);
}

@media screen and (max-width: 768px) {
  .main-title {
    font-size: 28px;
    line-height: 36px;
    padding: 50px 0 20px;
  }
}
</style>
