<script setup lang="ts"></script>

<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 3.625H19C19.8975 3.625 20.625 4.35254 20.625 5.25V19.75C20.625 20.6475 19.8975 21.375 19 21.375H7C6.10254 21.375 5.375 20.6475 5.375 19.75V5.25C5.375 4.35254 6.10254 3.625 7 3.625H8V5C8 6.10457 8.89543 7 10 7H16C17.1046 7 18 6.10457 18 5V3.625ZM16.375 3.625H9.625V5C9.625 5.20711 9.79289 5.375 10 5.375H16C16.2071 5.375 16.375 5.20711 16.375 5V3.625ZM18 2H19C20.7949 2 22.25 3.45507 22.25 5.25V19.75C22.25 21.5449 20.7949 23 19 23H7C5.20507 23 3.75 21.5449 3.75 19.75V5.25C3.75 3.45507 5.20507 2 7 2H8H9.625H16.375H18ZM8 11.3125C8 10.8638 8.36377 10.5 8.8125 10.5H17.1875C17.6362 10.5 18 10.8638 18 11.3125C18 11.7612 17.6362 12.125 17.1875 12.125H8.8125C8.36377 12.125 8 11.7612 8 11.3125ZM8.8125 15.5C8.36377 15.5 8 15.8638 8 16.3125C8 16.7612 8.36377 17.125 8.8125 17.125H17.1875C17.6362 17.125 18 16.7612 18 16.3125C18 15.8638 17.6362 15.5 17.1875 15.5H8.8125Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="less"></style>
