<script setup lang="ts">
import { computed } from 'vue'
import i18nHome from '@/locales/i18n-home'
import BlockTitle from '@/views/Home/components/Pricing/modules/BlockTitle.vue'

const { t } = i18nHome.global

const faqs = computed(() => {
  // @ts-expect-error
  let message = i18nHome.global.messages.value[i18nHome.global.locale.value]
  if (!message) {
    // @ts-expect-error
    message = i18nHome.global.messages.value[i18nHome.global.fallbackLocale.value]
  }
  return message.pricing.faqs || []
})
</script>

<template>
  <div class="block block-faq">
    <div class="content">
      <BlockTitle>{{ t('Frequently Asked Questions') }}</BlockTitle>
      <el-collapse :model-value="[0]">
        <el-collapse-item
          v-for="(faq, index) in faqs"
          :key="index"
          :title="t(`pricing.faqs[${index}].question`)"
          :name="index"
        >
          <div>{{ t(`pricing.faqs[${index}].answer`) }}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<style scoped lang="less">
.block-faq {
  padding: 40px 0 23px;

  .content {
    width: 1120px;
    margin: 0 auto;

    .el-collapse {
      border-top: 0;

      .el-collapse-item {
        font-family:
          SF Pro Text,
          var(--el-font-family);

        :deep(.el-collapse-item__header) {
          font-size: 24px;
          line-height: 32px;
          font-weight: 500;

          --el-collapse-header-height: 100px;
        }

        :deep(.el-collapse-item__content) {
          font-size: 18px;
          line-height: 28px;
          color: var(--my-color-black-50);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block {
    .content {
      width: calc(100% - var(--el-main-padding) * 2);
      padding-left: var(--el-main-padding);
      padding-right: var(--el-main-padding);
    }
  }

  .block-faq {
    padding: 20px 0 80px;

    .content {
      .el-collapse {
        .el-collapse-item {
          :deep(.el-collapse-item__header) {
            font-size: 20px;
            line-height: 30px;
            text-align: left;
          }

          :deep(.el-collapse-item__content) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
