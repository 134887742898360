<script setup lang="ts"></script>

<template>
  <svg width="91" height="45" viewBox="0 0 91 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_11846_13485)">
      <path
        d="M15.8047 0H91V27.3594H26.5234C20.6036 27.3594 15.8047 22.5604 15.8047 16.6406V0Z"
        fill="url(#paint0_linear_11846_13485)"
        shape-rendering="crispEdges"
      />
      <path
        d="M15.8047 -1.00488H14.7998V0V16.6406C14.7998 23.1154 20.0487 28.3643 26.5234 28.3643H91H92.0049V27.3594V0V-1.00488H91H15.8047Z"
        stroke="white"
        stroke-opacity="0.6"
        stroke-width="2.00977"
        shape-rendering="crispEdges"
      />
      <path
        d="M38.5853 9.21346C38.4792 9.10786 38.3025 9.17826 38.3025 9.31906C38.3025 10.199 38.656 11.4662 37.2065 12.0294C37.2065 12.0294 37.4186 6.11596 30.9844 5.20078C30.843 5.16558 30.737 5.30638 30.7723 5.44718C31.0198 6.29195 31.4794 8.89667 29.1815 10.5158C29.04 10.6214 28.934 10.6918 28.7926 10.7974C28.1209 9.95264 27.131 9.63585 26.7068 9.56545C26.6361 9.53025 26.5654 9.63585 26.6007 9.70625C27.2371 11.4662 25.9997 13.4021 25.9997 13.4021H26.0351C25.2219 14.5989 24.9038 16.0069 25.4694 18.0132C25.4694 18.0132 26.6007 21.3571 30.843 22.1667L30.7016 22.0259C30.7016 22.0259 28.1209 19.4212 29.2522 16.394C29.8885 14.7749 31.2319 13.8949 31.2319 13.8949C31.2319 13.8949 30.5956 16.0069 32.0804 16.6404C32.0804 16.6404 31.8329 13.4725 34.5551 12.3462C34.5551 12.3462 34.2369 14.0005 35.6157 15.9365C37.0298 17.9076 37.9843 20.3363 36.1106 21.9203C36.0399 21.9555 36.0045 22.0259 35.9338 22.0611C38.0196 21.5331 39.3277 20.3363 40.2115 19.1396C41.5549 17.274 42.1559 13.0853 38.5853 9.21346Z"
        fill="white"
      />
      <path
        d="M48.1871 8.19991H49.9396V13.0394H55.9528V8.19991H57.7054V19.6797H55.9528V14.5347H49.9396V19.6797H48.1871V8.19991ZM65.0438 7.97481C66.7802 7.97481 68.1629 8.53755 69.1758 9.67909C70.1405 10.7563 70.6229 12.1873 70.6229 13.9559C70.6229 15.7084 70.1405 17.1233 69.1758 18.2166C68.1629 19.342 66.7802 19.9048 65.0438 19.9048C63.2912 19.9048 61.9085 19.326 60.9117 18.2005C59.947 17.1072 59.4807 15.6923 59.4807 13.9559C59.4807 12.2034 59.947 10.7885 60.9117 9.69517C61.9085 8.53755 63.2912 7.97481 65.0438 7.97481ZM65.0438 9.51831C63.8218 9.51831 62.8732 9.92026 62.1979 10.7563C61.5548 11.5442 61.2332 12.6053 61.2332 13.9559C61.2332 15.2904 61.5548 16.3515 62.1979 17.1393C62.8732 17.9432 63.8218 18.3613 65.0438 18.3613C66.2657 18.3613 67.2143 17.9593 67.8735 17.1715C68.5166 16.3997 68.8543 15.3225 68.8543 13.9559C68.8543 12.5732 68.5166 11.4959 67.8735 10.7081C67.2143 9.90419 66.2657 9.51831 65.0438 9.51831ZM71.5142 8.19991H80.9199V9.69517H77.0933V19.6797H75.3569V9.69517H71.5142V8.19991Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_11846_13485"
        x="0.396484"
        y="-8.70703"
        width="100.652"
        height="52.8125"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.67969" dy="4.01953" />
        <feGaussianBlur stdDeviation="5.35938" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11846_13485" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11846_13485"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_11846_13485"
        x1="15.8047"
        y1="-2.05192"
        x2="79.0759"
        y2="-8.79327"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF6F27" />
        <stop offset="1" stop-color="#FFA436" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped lang="less"></style>
