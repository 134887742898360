<script setup lang="ts">
import { ref } from 'vue'

import { Right } from '@element-plus/icons-vue'

import IconVideoPlay from '@/components/icons/IconVideoPlay.vue'

import IconSubtitle from '@/components/home/IconSubtitle.vue'
import IconTitle from '@/components/home/IconTitle.vue'
import IconSchedule from '@/components/home/IconSchedule.vue'
import IconShare from '@/components/home/IconShare.vue'
import IconCorp from '@/components/home/IconCorp.vue'
import IconTemplate from '@/components/home/IconTemplate.vue'
import IconWave from '@/components/home/IconWave.vue'
import IconEdit from '@/components/home/IconEdit.vue'
import IconAiGenerate from '@/components/home/IconAiGenerate.vue'

import PageFooter from '@/components/PageFooter.vue'
import SignInDialog from '@/components/SignInDialog.vue'

import i18nHome from '@/locales/i18n-home'
import {
  getBanner,
  type Banner,
  getVideoCases,
  type VideoCase,
  getUserComments,
  type UserComments
} from '@/api'
import router, { getDefaultStartRoute, routeNameMap } from '@/router'
import { isMobile } from '@/utils'
import { useUserStore } from '@/stores/user'
import { useProjStore } from '@/stores/proj'

const { t } = i18nHome.global

const userStore = useUserStore()
const projStore = useProjStore()

const mainTitleHeight = 131 // 40 + 66 + 25
const blockIntroTitlePaddingTop = 85
const introTitleOffsetTop = mainTitleHeight - blockIntroTitlePaddingTop

const signInDialogVisible = ref(false)

const gotoWorkspace = () => {
  if (userStore.roles?.includes('admin')) {
    router.push({ name: 'admin.user-projects' })
    return
  }

  // router.push({ name: 'vmeg.create-video', params: { projId: projStore.projId || '-' } })
  // router.push({ name: 'vmeg.video-translation', params: { projId: projStore.projId || '-' } })
  router.push({ name: getDefaultStartRoute().name })
}

const banner = ref<Banner>()
const fetchBanner = () => {
  getBanner()
    .then((res) => {
      banner.value = res
    })
    .catch((err) => {
      console.debug(err.message)
    })
}
fetchBanner()

const videoCases = ref<VideoCase[]>([])
const fetchVideoCases = () => {
  getVideoCases()
    .then((res) => {
      videoCases.value = res
    })
    .catch((err) => {
      console.debug(err.message)
    })
}
fetchVideoCases()
const carouselTrigger: string = isMobile() ? 'click' : 'hover'
const userComments = ref<UserComments[]>([])
const fetchUserComments = () => {
  getUserComments()
    .then((res) => {
      userComments.value = res
    })
    .catch((err) => {
      console.debug(err.message)
    })
}
fetchUserComments()
const playItem = ref<VideoCase | null>()
const playVideo = (index: number) => {
  playItem.value = videoCases.value[index]
}
</script>

<template>
  <div class="block-banner" v-if="banner && (banner.title || banner.subtitle || banner.html)">
    <div class="content" v-if="banner.title || banner.subtitle">
      <div class="title" v-if="banner.title">
        <span v-if="banner.emoji">{{ banner.emoji }}</span>
        <div class="colorful">{{ banner.title }}</div>
      </div>
      <div class="subtitle colorful" v-if="banner.subtitle">
        <a :href="banner.link" target="_parent" v-if="banner.link">{{ banner.subtitle }}</a>
        <span v-else>{{ banner.subtitle }}</span>
      </div>
    </div>
    <div class="contents" v-if="banner.html" v-html="banner.html"></div>
  </div>
  <div class="block-slogan">
    <div class="content">
      <div class="title">
        <div>Market Anything with Infinite</div>
        <div>AI-crafted Videos</div>
      </div>
      <div class="subtitle">
        {{
          t(
            'Transform your inventory and idea into vivid, dynamic and diverse short videos instantly for your brand, product, etc.'
          )
        }}
      </div>
      <div class="actions">
        <el-button @click="signInDialogVisible = true" v-if="!userStore.id">
          {{ t('Try for free') }}<el-icon><Right /></el-icon>
        </el-button>
        <el-button @click="gotoWorkspace" v-else>
          {{ t('Try for free') }}<el-icon><Right /></el-icon>
        </el-button>
      </div>
    </div>
  </div>
  <div class="block-video-cases">
    <div class="content">
      <div class="videos-wrapper g-scrollbar">
        <div class="videos">
          <div class="video-wrapper" v-for="(item, index) in videoCases" :key="index">
            <div class="video">
              <video
                controls
                autoplay
                playsinline
                :src="item.url"
                :poster="item.poster"
                v-if="playItem?.url == item.url"
              ></video>
              <el-image loading="lazy" :src="item.poster" v-else />
              <el-icon @click="playVideo(index)" v-if="playItem?.url != item.url">
                <IconVideoPlay />
              </el-icon>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="block-support-platform">
    <div class="content">
      <el-divider class="intro">{{ t('Natively Supports Multi-platforms') }}</el-divider>
      <div class="images"></div>
    </div>
  </div>
  <div class="block-screenshot">
    <div class="content">
      <div class="images"></div>
    </div>
  </div>
  <div class="block-intro-container block-intro-affix">
    <el-affix target=".block-intro-affix" :z-index="101">
      <div class="block-intro-head">
        <div class="content">
          <div class="main-title">{{ t('How VMEG Works') }}</div>
        </div>
      </div>
    </el-affix>
    <div class="block-intro block-intro-how-works">
      <div class="content g-scrollbar">
        <div class="cases">
          <div class="case">
            <div class="step">
              <div class="step-number">01</div>
              <div class="step-name">{{ t('Input') }}</div>
              <div class="step-desc">{{ t('Video/image/Text/URL') }}</div>
            </div>
            <div class="pic-1">
              <el-text>https://yourshop.com/</el-text>
            </div>
          </div>
          <div class="case">
            <div class="step">
              <div class="step-number">02</div>
              <div class="step-name">{{ t('AI crafting') }}</div>
              <div class="step-desc">{{ t('Understanding, generation and x-effects') }}</div>
            </div>
            <div class="pic-2"></div>
          </div>
          <div class="case">
            <div class="step">
              <div class="step-number">03</div>
              <div class="step-name">{{ t('Output') }}</div>
              <div class="step-desc">{{ t('Instant, vivid, diverse and editable videos') }}</div>
            </div>
            <div class="pic-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-intro block-intro-clips-to-videos">
      <el-affix target=".block-intro-clips-to-videos" :offset="introTitleOffsetTop">
        <div class="block-intro-title">
          <div class="content">
            <div class="title">{{ t('Clips to Videos') }}</div>
            <div class="subtitle">
              {{ t('Instantly mix your scattered product clips to elegant marketing videos') }}
            </div>
          </div>
        </div>
      </el-affix>
      <div class="content">
        <div class="intro">
          <div class="intro-txt">
            <div class="steps">
              <div class="step">
                <div class="step-number">01</div>
                <div class="step-desc">{{ t('Upload your raw product clips') }}</div>
              </div>
              <div class="step">
                <div class="step-number">02</div>
                <div class="step-desc">
                  {{ t('Fill in the product name and unique selling points') }}
                </div>
              </div>
              <div class="step">
                <div class="step-number">03</div>
                <div class="step-desc">
                  <span>{{ t('One click') }}</span> -
                  {{
                    t(
                      'Platform compatible, diverse and audio-vision matched videos will be available instantly'
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="intro-pic">
            <div class="pic-1"></div>
            <div class="pic-2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-intro block-intro-video-translation">
      <el-affix target=".block-intro-video-translation" :offset="introTitleOffsetTop">
        <div class="block-intro-title">
          <div class="content">
            <div class="title">{{ t('Video Translation') }}</div>
            <div class="subtitle">
              {{
                t(
                  'Break Down Language Barriers with Video Translation: Connect, Engage, and Expand Globally'
                )
              }}
            </div>
          </div>
        </div>
      </el-affix>
      <div class="content">
        <div class="intro">
          <div class="intro-pic">
            <div class="pic-1"></div>
          </div>
          <div class="intro-txt">
            <div class="steps">
              <div class="step">
                <div class="step-number">01</div>
                <div class="step-desc">{{ t('Upload your original video') }}</div>
              </div>
              <div class="step">
                <div class="step-number">02</div>
                <div class="step-desc">
                  {{ t('Choose the language you want to translate into') }}
                </div>
              </div>
              <div class="step">
                <div class="step-number">03</div>
                <div class="step-desc">
                  {{ t('Preview or edit the translated text of the video') }}
                </div>
              </div>
              <div class="step">
                <div class="step-number">04</div>
                <div class="step-desc">
                  {{ t('Select a suitable AI voice or clone voice') }}
                </div>
              </div>
              <div class="step">
                <div class="step-number">05</div>
                <div class="step-desc">
                  <span>{{ t('One click') }}</span>
                  {{
                    t(
                      'on Generate, and wait just a few minutes for the video translation to be completed'
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-intro block-intro-product-url-to-videos">
      <el-affix target=".block-intro-product-url-to-videos" :offset="introTitleOffsetTop">
        <div class="block-intro-title">
          <div class="content">
            <div class="title">
              {{ t('Product URL to Videos') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="subtitle">
              {{
                t('Instantly turn your product webpage into dynamic marketing videos effortlessly')
              }}
            </div>
          </div>
        </div>
      </el-affix>
      <div class="content">
        <div class="steps">
          <div class="step">
            <div class="step-number">01</div>
            <div class="step-desc">{{ t('Enter the link to the product page') }}</div>
          </div>
          <div class="step">
            <div class="step-number">02</div>
            <div class="step-desc">
              {{ t('Confirm or edit the product details extracted by AI') }}
            </div>
          </div>
          <div class="step">
            <div class="step-number">03</div>
            <div class="step-desc">
              <span>{{ t('One click') }}</span> -
              {{
                t('Vibrant advertisement videos will be generated specifically for your product')
              }}
            </div>
          </div>
        </div>
        <div class="images">
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">01</div>
                <div class="step-desc">{{ t('Enter the link to the product page') }}</div>
              </div>
            </div>
            <div class="pic-1">
              <el-text>https://yourproduct.com</el-text>
            </div>
          </div>
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">02</div>
                <div class="step-desc">
                  {{ t('Confirm or edit the product details extracted by AI') }}
                </div>
              </div>
            </div>
            <div class="pic-2"></div>
          </div>
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">03</div>
                <div class="step-desc">
                  <span>{{ t('One click') }}</span> -
                  {{
                    t(
                      'Vibrant advertisement videos will be generated specifically for your product'
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="pic-3"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-intro block-intro-long-video-to-shorts">
      <el-affix target=".block-intro-long-video-to-shorts" :offset="introTitleOffsetTop">
        <div class="block-intro-title">
          <div class="content">
            <div class="title">
              {{ t('Long Video to Shorts') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="subtitle">
              {{
                t(
                  'Automatically spot the highlights of your long live video and revitalize it with shorts'
                )
              }}
            </div>
          </div>
        </div>
      </el-affix>
      <div class="content">
        <div class="pics">
          <div class="pic">
            <div class="steps">
              <div class="step">
                <div class="step-number">01</div>
                <div class="step-desc">
                  {{ t('Upload your local file or Enter the video link') }}
                </div>
              </div>
            </div>
            <div class="pic-1">
              <el-text>https://youtube.com/yourevent</el-text>
            </div>
          </div>
          <div class="pic">
            <div class="steps">
              <div class="step">
                <div class="step-number">02</div>
                <div class="step-desc">
                  <span>{{ t('One click') }}</span> -
                  {{
                    t(
                      'Massive short but topic-focused videos will be generated for easier sharing and spreading'
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="pic-2">
              <div class="ai-generate-btn"><IconAiGenerate /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="block-intro-container">
    <div class="block-intro block-intro-images-to-videos">
      <div target=".block-intro-images-to-videos" :offset="introTitleOffsetTop">
        <div class="block-intro-title">
          <div class="content">
            <div class="title">
              {{ t('Images to Videos') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="subtitle">
              {{ t('Revitalize your photos in the era of short video popularity') }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="images">
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">01</div>
                <div class="step-desc">{{ t('Upload your product images') }}</div>
              </div>
            </div>
            <div class="pic-1"></div>
          </div>
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">02</div>
                <div class="step-desc">{{ t('Fill in the product name and selling points') }}</div>
              </div>
            </div>
            <div class="pic-2">
              <div class="input">{{ t('Product Name') }}✨</div>
              <div class="tags">
                <el-tag type="info" effect="plain" round>{{ t('spacious') }}</el-tag>
                <el-tag type="info" effect="plain" round>{{ t('versatile') }}</el-tag>
                <el-tag type="info" effect="plain" round>{{ t('stylish') }}</el-tag>
                <el-tag type="info" effect="plain" round>{{ t('comfortable') }}</el-tag>
              </div>
            </div>
          </div>
          <div class="image">
            <div class="steps">
              <div class="step">
                <div class="step-number">03</div>
                <div class="step-desc">
                  <span>{{ t('One click') }}</span> -
                  {{ t('Unlimited original marketing videos will be delivered') }}
                </div>
              </div>
            </div>
            <div class="pic-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="block-features">
    <div class="content">
      <div class="main-title">{{ t('Product Features') }}</div>
      <div class="value-group">
        <div class="values">
          <div class="value">
            <div class="value-icon"><IconSubtitle /></div>
            <div class="value-name">{{ t('Automatic Video Subtitle') }}</div>
            <div class="value-desc">
              {{
                t(
                  'Automatically generate subtitles for your videos, making them more accessible and understandable.'
                )
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconTitle /></div>
            <div class="value-name">{{ t('Eye-catching Titles') }}</div>
            <div class="value-desc">
              {{
                t(
                  'Generate captivating titles with One click, ensuring your content grabs attention effortlessly.'
                )
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconWave /></div>
            <div class="value-name">{{ t('Align Audio&Vision Precisely') }}</div>
            <div class="value-desc">
              {{
                t(
                  'Videos are generated using the correlations of clips and subtitles by the cutting-edge multimodal LLM.'
                )
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconShare /></div>
            <div class="value-name">
              {{ t('One-click Post') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="value-desc">
              {{
                t(
                  'With a simple click, distribute your ad videos to various video platforms, effortlessly expanding your reach.'
                )
              }}
            </div>
          </div>
        </div>
        <div class="values">
          <div class="value">
            <div class="value-icon"><IconSchedule /></div>
            <div class="value-name">
              {{ t('Post on Schedule') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="value-desc">
              {{
                t('Set ad video delivery schedules to ensure timely reach out to target audience.')
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconCorp /></div>
            <div class="value-name">
              {{ t('Multi-platform Compatible') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="value-desc">
              {{
                t(
                  'Intelligently adjust ad video sizes for perfect presentation on platforms like TikTok, YouTube, and more.'
                )
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconTemplate /></div>
            <div class="value-name">
              {{ t('Top-notch Video Templates') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="value-desc">
              {{
                t(
                  'Access a wide range of video templates to create professional-level video content quickly.'
                )
              }}
            </div>
          </div>
          <div class="value">
            <div class="value-icon"><IconEdit /></div>
            <div class="value-name">
              {{ t('Video Editing Like MS Word') }}<span>{{ t('Comming Soon') }}</span>
            </div>
            <div class="value-desc">
              {{
                t(
                  'Access a simple and user-friendly video editing tool that allows you to edit videos quickly.'
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="block-user-comments">
    <div class="content">
      <div class="main-title">{{ t('User Comments') }}</div>
      <el-carousel
        class="x2"
        height="380px"
        :trigger="carouselTrigger"
        indicator-position="outside"
      >
        <template v-for="index in Math.ceil(userComments.length / 2)" :key="index">
          <el-carousel-item>
            <div class="user-comment">
              <div class="user">
                <el-image loading="lazy" :src="userComments[(index - 1) * 2].avatar" />
                <div class="name">{{ userComments[(index - 1) * 2].name }}</div>
              </div>
              <div class="comment">{{ userComments[(index - 1) * 2].comment }}</div>
            </div>
            <div class="user-comment" v-if="userComments[(index - 1) * 2 + 1]">
              <div class="user">
                <el-image loading="lazy" :src="userComments[(index - 1) * 2 + 1].avatar" />
                <div class="name">{{ userComments[(index - 1) * 2 + 1].name }}</div>
              </div>
              <div class="comment">{{ userComments[(index - 1) * 2 + 1].comment }}</div>
            </div>
          </el-carousel-item>
        </template>
      </el-carousel>
      <el-carousel
        class="x1"
        height="380px"
        :trigger="carouselTrigger"
        indicator-position="outside"
      >
        <el-carousel-item v-for="(item, index) in userComments" :key="index">
          <div class="user-comment">
            <div class="user">
              <el-image loading="lazy" :src="item.avatar" />
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="comment">{{ item.comment }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="block-values">
    <div class="content">
      <div class="main-title">{{ t('Product Values') }}</div>
      <div class="values">
        <div class="value">
          <div class="value-number">{{ t('{value}x', { value: '17.5' }) }}</div>
          <div class="value-name">{{ t('Efficiency improvement') }}</div>
          <div class="value-desc">
            {{
              t(
                'Compare the quantity of short videos produced by VMEG within a timeframe to those created purely through manual labor.'
              )
            }}
          </div>
        </div>
        <div class="value">
          <div class="value-number">94.3%</div>
          <div class="value-name">{{ t('Labor cost reduction') }}</div>
          <div class="value-desc">
            {{
              t(
                'When considering the production of advertising videos of comparable quality, evaluate the cost difference between creating a single short video using the VMEG tool versus employing solely manual methods.'
              )
            }}
          </div>
        </div>
        <div class="value">
          <div class="value-number">{{ t('{value}x', { value: '15' }) }}</div>
          <div class="value-name">{{ t('Effectiveness increase') }}</div>
          <div class="value-desc">
            {{
              t(
                'Compare the usage and impact of short video content created by VMEG across various advertising platforms to that of manually produced content within a specific timeframe.'
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <PageFooter />
  <SignInDialog v-model="signInDialogVisible" />
</template>

<style scoped lang="less">
*,
.block-slogan .subtitle,
.el-button,
.el-text {
  font-family: var(--el-font-family);
}

.el-tag,
.pic-1 .el-text, // like input
.images .el-text, // like input
.input,
.value-number,
.step-number,
.title,
.main-title {
  font-family:
    SF Pro Display,
    var(--el-font-family);
}

.subtitle {
  font-family:
    SF Pro,
    var(--el-font-family);
}

.title span, // comming soon
.step-desc {
  font-family:
    SF Pro Text,
    var(--el-font-family);
}

.main-title {
  padding: 40px 0 25px;
  height: 66px; // 40 + 66 + 25 = 131

  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
  color: var(--my-color-black-89-90);
}

.block-banner {
  margin-bottom: -60px;

  .content {
    width: 982px;
    margin: 0 auto 30px;

    border-radius: 8px;
    padding: 12px 24px;
    background: linear-gradient(
      88.97deg,
      #ffc4531f -18.53%,
      #ff8d3a1f 16.36%,
      #ff5fbf1f 44.18%,
      #7b4dff1f 86.83%
    );

    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: var(--my-color-black-90);

      display: flex;
      //justify-content: center;
      gap: 5px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      //text-align: center;
      color: var(--my-color-black-100);
    }

    .colorful {
      background: linear-gradient(
        91.83deg,
        #ff8123 12.9%,
        #ff3bb3 35.36%,
        #7b4dff 57.42%,
        #5f2bfa 79.24%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
    }
  }

  .contents {
    width: 1120px;
    margin: 0 auto;
    text-align: center;
  }
}

.block-slogan {
  padding: 90px 0 30px;

  .content {
    width: 1120px;
    margin: 0 auto;

    .title {
      font-size: 60px;
      font-weight: 700;
      line-height: 66px;
      text-align: center;
      color: var(--my-color-black-89-90);

      &,
      > div {
        font-family:
          Alimama FangYuanTi VF,
          '阿里妈妈方圆体 VF',
          var(--el-font-family);
      }

      background: linear-gradient(
        89.99deg,
        #ffc453 14.56%,
        #ff8d3a 25.15%,
        #ff5fbf 33.59%,
        #7b4dff 46.54%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;

      background: url(@/assets/home/heading@2x.webp) no-repeat center;
      background-size: 76.8%;
    }

    .subtitle {
      width: 500px;
      margin: 30px auto 50px;

      font-size: 24px;
      line-height: 34px;
      opacity: 0.6;
      color: var(--my-color-black-89-90);
      text-align: center;
    }

    .actions {
      display: flex;
      justify-content: center;

      .el-button {
        height: 56px;
        padding: 16px 32px 16px 38px;
        border-radius: 62px;
        background: var(--el-color-primary);
        border-color: var(--my-color-primary-60);

        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: var(--my-color-white-100);

        &:hover {
          opacity: 0.9;
        }

        .el-icon {
          margin-left: 20px;
          font-size: 28px;
        }
      }
    }
  }
}

.block-video-cases {
  padding: 0 0 10px;
  //background: var(--my-color-y2);

  .content {
    width: 1120px;
    margin: 0 auto;

    .videos-wrapper {
      margin-top: 50px;
      overflow-x: scroll;

      .videos {
        width: min-content;
        display: flex;
        gap: 10px;

        .video-wrapper {
          width: 190px;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .video {
            width: 100%;
            height: 338px;
            border-radius: 12px;
            overflow: hidden;
            background: var(--my-color-black-100);
            position: relative;

            .el-image,
            video {
              width: 100%;
              height: 100%;
            }

            .el-icon {
              position: absolute;
              --icon-size: 50px;
              top: calc((100% - var(--icon-size)) / 2);
              left: calc((100% - var(--icon-size)) / 2);
              width: var(--icon-size);
              height: var(--icon-size);
              border-radius: var(--icon-size);
              background: var(--my-color-white-60);
              font-size: 40px;

              &:hover {
                cursor: pointer;

                svg {
                  color: var(--el-color-primary);
                }
              }
            }
          }

          .title {
            font-family:
              SF Pro Text,
              var(--el-font-family);
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: var(--my-color-black-50);
          }
        }
      }
    }
  }
}

.block-support-platform {
  .content {
    width: 1120px;
    height: 176px;
    margin: 65px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    .intro.el-divider {
      border-top: 1px var(--my-color-black-10) var(--el-border-style);

      :deep(.el-divider__text) {
        background-color: var(--my-color-white-100);
        padding: 0 60px;
        font-weight: normal;
        color: var(--my-color-black-60);
        font-size: 24px;
        line-height: 32px;
      }
    }

    .images {
      height: 40px;
      background: url(@/assets/home/logos@2x.webp) no-repeat center;
      background-size: contain;
    }
  }
}

.block-screenshot {
  padding: 10px 0 60px;

  .content {
    width: 1120px;
    margin: 0 auto;

    /*.images {
      height: 750px;
      background: url(@/assets/home/screenshot@2x.webp) no-repeat center;
      background-size: contain;
    }*/
  }
}

// 实现最后一个元素小间距顶住主标题 start
.block-intro-container.block-intro-affix {
  &:after {
    content: '';
    display: block;
    height: 85px;
    //background: var(--my-color-y2);
    background: var(--el-bg-color);
  }
}

.block-intro-container:not(.block-intro-affix) {
  .block-intro-images-to-videos {
    .block-intro-title {
      padding-top: 15px;
    }
  }
}
// 实现最后一个元素小间距顶住主标题 start

.block-intro-affix {
  :deep(.el-affix--fixed) {
    // .block-intro-head,
    .block-intro-title {
      box-shadow: 0 4px 4px 0 var(--my-color-x2);
    }
  }
}

.block-intro-container {
  .block-intro-title {
    padding: calc(85px + 15px) 0 15px; // 85 + 15 = 100 // blockIntroTitlePaddingTop
    background: var(--el-bg-color);
  }

  .title {
    height: 44px;

    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    color: var(--my-color-black-89-90);

    span {
      margin-left: 15px;

      font-size: 20px;
      line-height: 28px;
      color: var(--my-color-black-50);
    }
  }

  .subtitle {
    height: 28px;
    margin-top: 12px;

    font-size: 20px;
    line-height: 28px;
    color: var(--my-color-black-89-90);
  }

  .steps {
    margin-top: 65px;

    .step {
      .step-number {
        width: 28px;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        color: var(--my-color-black-89-90);
      }

      .step-desc {
        font-size: 24px;
        line-height: 28px;
        color: var(--my-color-black-89-90);

        span {
          color: var(--el-color-primary);
        }
      }
    }
  }

  .block-intro-head {
    background: var(--el-bg-color);

    .content {
      width: 1120px;
      margin: 0 auto;
    }
  }

  .block-intro-how-works {
    padding: 75px 0 20px; // 75 + 25 = 100

    .content {
      width: 1120px;
      margin: 0 auto;

      .cases {
        display: flex;
        gap: 35px;

        height: 500px;

        .case {
          width: 33.333%;
          background: var(--my-color-y1);
          border-radius: 16px;
          overflow: hidden;

          position: relative;

          .pic-1 {
            position: absolute;
            bottom: 32px;
            width: 350px;
            height: 282px;
            background: url(@/assets/home/how-works-1@2x.webp) no-repeat;
            background-size: contain;

            .el-text {
              position: absolute;
              left: 210px;
              top: 168px;
              width: 300px;

              font-size: 20px;
              font-weight: 500;
              line-height: 28px;
              color: var(--my-color-black-89-90);
            }
          }

          .pic-2 {
            position: absolute;
            left: 30px;
            bottom: 11px;
            width: 320px;
            height: 297px;
            background: url(@/assets/home/how-works-2.svg) no-repeat;
          }

          .pic-3 {
            position: absolute;
            bottom: 0;
            width: 350px;
            height: 309px;
            background: url(@/assets/home/how-works-3@2x.webp) no-repeat;
            background-size: contain;
          }

          .step {
            margin: 35px 30px 0 40px;
            text-transform: uppercase;

            .step-number {
              margin-bottom: 5px;

              font-size: 50px;
              font-weight: 700;
              line-height: 60px;
              color: var(--my-color-black-89-90);
            }

            .step-desc,
            .step-name {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 1px;
              color: var(--my-color-black-60);
            }

            .step-name {
              font-weight: 600;
              color: var(--my-color-black-89-90);
            }
          }
        }
      }
    }
  }

  .block-intro-clips-to-videos {
    padding-bottom: 120px;

    .content {
      width: 1120px;
      margin: 0 auto;

      .intro {
        display: flex;
        justify-content: space-between;

        .intro-txt {
          margin-top: 75px;

          .steps {
            width: 450px;

            .step {
              display: flex;
              gap: 20px;

              &:not(:first-child) {
                margin-top: 50px;
              }
            }
          }
        }

        .intro-pic {
          position: relative;
          margin-top: 80px;

          .pic-1 {
            width: 594px;
            height: 392px;
            background: url(@/assets/home/clips2videos-1@2x.webp) no-repeat;
            background-size: cover;

            border-radius: 16px;
            box-shadow: 0 3.93px 39.34px 0 var(--my-color-x2);
          }

          .pic-2 {
            width: 594px;
            height: 497px;
            background: url(@/assets/home/clips2videos-2@2x.webp) no-repeat;
            background-size: cover;

            position: absolute;
            left: -10px;
            top: -86px;
          }
        }
      }
    }
  }

  .block-intro-video-translation {
    padding-bottom: 120px;

    &,
    .block-intro-title {
      background: var(--my-color-y2);
    }

    .content {
      width: 1120px;
      margin: 0 auto;

      .intro {
        display: flex;
        justify-content: space-between;

        .intro-txt {
          margin-top: 75px;

          .steps {
            width: 535px;

            .step {
              display: flex;
              gap: 20px;

              &:not(:first-child) {
                margin-top: 50px;
              }
            }
          }
        }

        .intro-pic {
          position: relative;
          margin-top: 80px;

          .pic-1 {
            width: 505px;
            height: 600px;
            background: url(@/assets/home/video-translation@2x.webp) no-repeat;
            background-size: cover;

            border-radius: 16px;
          }
        }
      }
    }
  }

  .block-intro-long-video-to-shorts {
    padding-bottom: 120px;

    &,
    .block-intro-title {
      background: var(--my-color-y2);
    }

    .content {
      width: 1120px;
      margin: 0 auto;

      .steps {
        display: flex;
        gap: 120px;
        margin: 65px 0 60px;

        .step {
          display: flex;
          gap: 20px;
        }
      }

      .pics {
        display: flex;
        gap: 120px;

        .pic {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 500px;

          .pic-1 {
            width: 100%;
            height: 300px;
            border-radius: 16px;
            background: var(--el-bg-color) url(@/assets/home/long2shorts-1@2x.webp) no-repeat;
            background-size: 96%;
            background-position: -10px -6px;

            position: relative;

            .el-text {
              position: absolute;
              left: 100px;
              top: 39px;
              width: 240px;
              height: 28px;
              overflow: hidden;

              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              color: var(--my-color-black-89-90);
            }
          }

          .pic-2 {
            width: 100%;
            height: 300px;
            border-radius: 16px;
            background: var(--el-bg-color) url(@/assets/home/long2shorts-2@2x.webp) no-repeat;
            background-size: 86%;
            background-position: 40px 20px;

            position: relative;

            .ai-generate-btn {
              position: absolute;
              left: -30px;
              top: 24px;

              width: 124px;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .block-intro-product-url-to-videos {
    padding-bottom: 120px;

    .content {
      width: 1120px;
      margin: 0 auto;

      .steps {
        .step {
          display: flex;
          gap: 20px;
          height: 28px;

          &:not(:first-child) {
            margin-top: 20px;
          }
        }
      }

      .images {
        margin-top: 60px;
        display: flex;
        position: relative;

        .image {
          &:not(:first-child) {
            &:after {
              position: absolute;
              top: 50%;
              content: '';
              width: 36px;
              height: 16px;
              background: url(@/assets/home/url2videos@2x.svg) no-repeat;
              background-size: cover;
            }
          }

          .steps {
            display: none;
          }

          .pic-1,
          .pic-2,
          .pic-3 {
            width: 373.333333px;
            height: 400px;
            background: var(--my-color-y2, #ffffff) url(@/assets/home/url2videos@2x.webp) no-repeat;
            background-size: cover;
          }

          .pic-1 {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            position: relative;

            .el-text {
              position: absolute;
              left: 66px;
              top: 52px;
              font-weight: 500;
              font-size: 20px;
              color: var(--my-color-black-89-90);
            }
          }

          .pic-2 {
            background-position: -373.333333px;
          }

          .pic-3 {
            background-position: -746.666666px;
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }

  .block-intro-images-to-videos {
    padding-bottom: 120px;

    .content {
      width: 1120px;
      margin: 0 auto;

      .steps {
        display: flex;
        gap: 30px;
        margin: 65px 0 60px;

        .step {
          display: flex;
          gap: 20px;
        }
      }

      .images {
        display: flex;
        gap: 36px;

        .image {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .pic-1,
          .pic-2,
          .pic-3 {
            border-radius: 20px;
            height: 300px;
            background: var(--my-color-y2) url(@/assets/home/images2videos@2x.webp) no-repeat;
            background-size: cover;
          }

          .pic-2 {
            background-position: 50% 0;

            position: relative;

            .input {
              position: absolute;
              top: 65px;
              left: 70px;

              font-size: 20px;
              font-weight: 500;
              line-height: 28px;
              color: var(--my-color-black-89-90);

              &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 22px;
                margin: -5px 0 0 5px;
                vertical-align: middle;
                animation: blink 1s step-end infinite;
              }

              @keyframes blink {
                0%,
                to {
                  background: var(--my-color-black-89-90);
                }

                50% {
                  background: transparent;
                }
              }
            }

            .tags {
              position: absolute;
              top: 140px;
              left: 45px;
              width: 260px;

              .el-tag {
                border-color: var(--my-color-black-10);
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                color: var(--my-color-black-100);
                height: 34px;
                padding: 6px 16px;

                margin: 5px;
              }
            }
          }

          .pic-3 {
            background-position: 100% 0;
          }
        }
      }
    }
  }
}

.block-features {
  padding: 0 0 110px;

  .content {
    width: 1120px;
    margin: 0 auto;

    .value-group {
      padding-top: 25px;

      .values {
        display: flex;
        gap: 40px;

        margin-top: 50px;

        .value {
          width: 25%;
          height: 192px;

          .value-icon {
            width: 48px;
            height: 48px;
          }

          .value-name {
            margin-top: 16px;

            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            color: var(--my-color-black-89-90);

            span {
              font-size: 12px;
              line-height: 18px;
              color: var(--my-color-black-50);
            }
          }

          .value-desc {
            height: 66px;
            margin-top: 10px;

            font-size: 14px;
            line-height: 22px;
            color: var(--my-color-black-50);
          }
        }

        &:first-child {
          .value {
            .value-name {
              span {
                margin-left: 10px;
              }
            }
          }
        }

        &:last-child {
          .value {
            .value-name {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.block-user-comments {
  padding: 65px 0 80px;
  background: var(--my-color-y2);

  .content {
    width: 1120px;
    margin: 0 auto;

    .main-title {
      padding-bottom: 70px;
    }

    .el-carousel {
      --el-carousel-indicator-width: 12px;
      --el-carousel-indicator-height: 12px;
      --el-carousel-indicator-padding-horizontal: 10px;
      --el-carousel-indicator-padding-vertical: 10px;

      &.x1 {
        display: none;
      }

      &.x2 {
        display: block;
      }

      .el-carousel__item {
        display: flex;
        gap: 60px;

        .user-comment {
          width: 530px;
          padding: 40px;
          border-radius: 16px;
          background: var(--my-color-white-100);

          .user {
            display: flex;
            align-items: center;
            gap: 24px;

            .el-image {
              width: 72px;
              height: 72px;
              border-radius: 100%;
            }

            .name {
              font-size: 28px;
              font-weight: 700;
              line-height: 34px;
              color: var(--my-color-x3);
            }
          }

          .comment {
            font-size: 20px;
            font-style: italic;
            line-height: 30px;
            color: var(--my-color-x3);
            margin-top: 30px;

            &::after,
            &::before {
              font-style: normal;
              line-height: unset;
              font-weight: 700;
              color: var(--my-color-primary-50);
            }

            &::before {
              content: '“';
            }

            &::after {
              content: '”';
              margin-left: 5px;
            }
          }
        }
      }

      :deep(.el-carousel__indicators) {
        margin-top: 50px;

        .el-carousel__indicator {
          .el-carousel__button {
            --el-carousel-indicator-out-color: #d0d0d0;
            opacity: 1;
            border-radius: var(--el-carousel-indicator-height);
          }

          &.is-active {
            .el-carousel__button {
              background-color: var(--my-color-primary-60);
            }
          }
        }
      }
    }
  }
}

.block-values {
  padding: 65px 0 23px;

  .content {
    width: 1120px;
    margin: 0 auto;

    .values {
      display: flex;
      gap: 38px;

      margin-top: 55px;

      .value {
        width: 33.333%;
        height: 290px;
        padding: 50px 40px 36px 40px;
        border-radius: 16px;
        background: var(--my-color-y2);

        .value-number {
          height: 60px;

          font-size: 50px;
          font-weight: 700;
          line-height: 60px;
          color: var(--my-color-x3);
        }

        .value-name {
          width: 50%;
          height: 56px;
          margin-top: 10px;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 24px;
          letter-spacing: 1px;
          color: var(--my-color-x4);
        }

        .value-desc {
          margin-top: 32px;

          font-size: 16px;
          line-height: 24px;
          color: var(--my-color-x3);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  * {
    --my-device-max-width: 397px;
  }

  .main-title {
    font-size: 28px;
    padding: 0;
  }

  .block-banner {
    padding: 0 var(--el-main-padding);
    margin-bottom: -20px;

    .content {
      width: unset;
      margin: 0 auto 10px;

      .title {
        font-size: 16px;

        div:not(.colorful) {
          display: flex;
          align-items: center;
        }
      }

      .subtitle {
        font-size: 12px;
      }
    }

    .contents {
      width: unset;
    }
  }

  .block-video-cases {
    padding: 0;

    .content {
      width: unset;
      padding: 0 var(--el-main-padding);

      .videos-wrapper {
        margin-top: 10px;

        .videos {
          margin-bottom: 10px;
        }
      }
    }
  }

  .block-slogan {
    padding: 38px 0;

    .content {
      width: unset;

      .title {
        max-width: var(--my-device-max-width);
        margin: 0 auto;
        font-size: 24px;
        line-height: 1;
      }

      .subtitle {
        width: unset;
        margin: 0 auto;
        font-size: 14px;
        line-height: 20px;
        padding: var(--el-main-padding);
      }

      .actions {
        .el-button {
          height: 40px;
          padding: 8px 20px;
          font-size: 16px;

          .el-icon {
            margin-left: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }

  .block-support-platform {
    .content {
      width: unset;
      height: 142px;
      margin: 0 auto;
      padding: 0 var(--el-main-padding);

      .intro.el-divider {
        margin: 0 0 10px;

        :deep(.el-divider__text) {
          padding: 0 12px;
          font-size: 14px;
          white-space: nowrap;
        }
      }

      .images {
        height: 24px;
      }
    }
  }

  .block-screenshot {
    padding: 0;

    .content {
      width: unset;
    }
  }

  .block-intro-container {
    overflow: hidden;

    .block-intro-title {
      padding: 30px 0 20px;

      .content {
        height: 84px;
      }
    }

    .title {
      font-size: 21px; // font-size: 24px;

      span {
        font-size: 12px; // font-size: 14px;
        margin-left: 12px;
      }
    }

    .subtitle {
      height: unset;
      margin-top: 0;
      font-size: 16px;
      line-height: 18px; // line-height: 24px;
    }

    .steps {
      .step {
        .step-number,
        .step-desc {
          width: unset;
          font-size: 18px;
        }
      }
    }

    .block-intro-head {
      .content {
        width: unset;
        padding: 0 var(--el-main-padding);
      }
    }

    .block-intro-how-works {
      padding: 10px 0 0; // 75 + 25 = 100

      .content {
        width: unset;
        margin: 0 var(--el-main-padding);
        padding-bottom: var(--el-main-padding);
        overflow-x: scroll;

        .cases {
          --my-gap: 16px;
          width: calc(238px * 3 + var(--my-gap) * 2);
          height: 340px;
          gap: var(--my-gap);

          .case {
            border-radius: 11px;

            .step {
              margin: 20px 10px 0 27px;

              .step-number {
                font-size: 34px;
                line-height: 41px;
              }

              .step-name,
              .step-desc {
                font-size: 12px;
                line-height: 18px;
              }
            }

            .pic-3,
            .pic-2,
            .pic-1 {
              height: 192px;
              bottom: 22px;
            }

            .pic-1 {
              .el-text {
                left: 138px;
                top: 110px;
                font-size: 12px;
              }
            }

            .pic-2 {
              width: 100%;
              left: 20px;
              bottom: 16px;
              background-size: 90%;
            }

            .pic-3 {
              width: 100%;
              height: 210px;
              bottom: 0;
              background-size: cover;
            }
          }
        }
      }
    }

    .block-intro-images-to-videos,
    .block-intro-product-url-to-videos,
    .block-intro-long-video-to-shorts,
    .block-intro-video-translation,
    .block-intro-clips-to-videos {
      padding-bottom: 40px;

      .content {
        width: unset;
        padding: 0 var(--el-main-padding);
      }
    }

    .block-intro-video-translation,
    .block-intro-clips-to-videos {
      .content {
        .intro {
          flex-direction: column;

          .intro-txt {
            margin-top: 5px;

            .steps {
              width: unset;
              margin: 0;

              .step {
                &:not(:first-child) {
                  margin-top: 15px;
                }
              }
            }
          }

          .intro-pic {
            margin-top: 60px;

            .pic-1 {
              width: 335px;
              height: 222px;
            }

            .pic-2 {
              width: 335px;
              height: 280px;
              left: -5px;
              top: -50px;
            }
          }
        }
      }
    }

    .block-intro-video-translation {
      .content {
        .intro {
          .intro-pic {
            margin: 30px 0;

            .pic-1 {
              width: 100%;
              height: calc((100vw - var(--el-main-padding) * 2) * 1.18811881);
            }
          }
        }
      }
    }

    .block-intro-long-video-to-shorts {
      .content {
        .steps {
          margin: 24px 0;
        }

        .pics {
          flex-direction: column;
          gap: 0;
          margin: -20px 0 0;

          .pic {
            width: 100%;

            .pic-2,
            .pic-1 {
              width: 100%;
              max-width: var(--my-device-max-width);
              height: 206px;
              margin: 0;
              border-radius: 16px;
            }

            .pic-1 {
              background-size: contain;
              background-position: -12px -10px;

              .el-text {
                left: 66px;
                top: 19px;
                width: 170px;
                font-size: 12px;
              }
            }

            .pic-2 {
              background-position: center;

              .ai-generate-btn {
                left: 10px;
                top: 10px;
                width: 85px;
                height: 33px;

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }

    .block-intro-product-url-to-videos {
      .content {
        .steps {
          display: none;
        }

        .images {
          flex-direction: column;
          margin-top: 0;

          .image {
            display: flex;
            flex-direction: column;

            &:after {
              display: none;
            }

            .steps {
              display: block;
              margin: 24px 0;

              .step {
                height: unset;
              }
            }

            &:first-child {
              .steps {
                margin: 10px 0 24px;
              }
            }

            .pic-1,
            .pic-2,
            .pic-3 {
              width: 100%;
              max-width: var(--my-device-max-width);
              height: 368px;
              border-radius: 16px;
            }

            .pic-1 {
              background-position: -4px;

              .el-text {
                font-size: 18px;
                left: 56px;
                top: 48px;
              }
            }

            .pic-2 {
              background-position: -362px;
            }

            .pic-3 {
              background-position: -712px;
            }
          }
        }
      }
    }

    .block-intro-images-to-videos {
      .content {
        .steps {
          margin: 24px 0;
        }

        .images {
          height: unset;
          margin: 0;
          gap: 0;

          flex-direction: column;

          .image {
            &:first-child {
              .steps {
                margin: 10px 0 24px;
              }
            }

            .pic-1,
            .pic-2,
            .pic-3 {
              max-width: var(--my-device-max-width);
              height: 294px;
              border-radius: 20px;
            }

            .pic-2 {
              background-position: -376px 0;
            }

            .pic-3 {
              background-position: -752px 0;
            }
          }
        }
      }
    }

    &.block-intro-affix {
      &:after {
        height: 15px;
      }
    }
  }

  .block-features {
    padding: 0 0 50px;

    .content {
      width: unset;

      .main-title {
        padding: 0 var(--el-main-padding);
      }

      .value-group {
        padding: 0;

        .values {
          flex-wrap: wrap;
          padding: 0 var(--el-main-padding);
          --my-gap: 20px;
          margin-top: var(--my-gap);
          gap: var(--my-gap);

          .value {
            width: calc((100% - var(--my-gap)) / 2);
            height: unset;

            .value-name {
              font-size: 16px;
            }

            .value-desc {
              height: unset;
            }
          }
        }
      }
    }
  }

  .block-user-comments {
    padding: 0 0 20px;

    .content {
      width: unset;

      .main-title {
        padding: var(--el-main-padding);
      }

      .el-carousel {
        --el-carousel-indicator-padding-horizontal: 5px;
        --el-carousel-indicator-padding-vertical: 5px;

        &.x1 {
          display: block;
        }

        &.x2 {
          display: none;
        }

        .el-carousel__item {
          display: block;
          height: 100%;

          .user-comment {
            height: calc(100% - var(--el-main-padding) * 2);
            width: unset;
            padding: var(--el-main-padding);

            .user {
              gap: 16px;

              .el-image {
                width: 50px;
                height: 50px;
              }

              .name {
                font-size: 20px;
              }
            }

            .comment {
              font-size: 18px;
            }
          }
        }

        :deep(.el-carousel__indicators) {
          margin-top: 20px;
        }
      }
    }
  }

  .block-values {
    padding: 20px 0 40px;

    .content {
      width: unset;

      .main-title {
        padding: 0 var(--el-main-padding);
      }

      .values {
        gap: 24px;
        margin-top: 20px;
        align-items: center;
        flex-direction: column;
        padding: 0 var(--el-main-padding);

        .value {
          width: unset;
          height: unset;
          padding: 40px 30px 26px 30px;

          .value-number {
            font-size: 36px;
          }

          .value-name {
            height: unset;
            width: unset;
          }

          .value-desc {
            font-size: 14px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
