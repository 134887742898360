<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11537_12426)">
      <path
        d="M4.065 11.5748C4.065 10.8038 4.43013 10.0784 5.04936 9.61908L11.5494 4.79776C12.4109 4.15873 13.5891 4.15873 14.4506 4.79776L20.9506 9.61908C21.5699 10.0784 21.935 10.8038 21.935 11.5748V19.7488C21.935 21.0936 20.8448 22.1838 19.5 22.1838H6.5C5.15519 22.1838 4.065 21.0936 4.065 19.7488V11.5748Z"
        stroke="currentColor"
        stroke-width="1.63"
      />
      <path
        d="M12.1851 15.815C12.1851 15.3649 12.5499 15 13.0001 15C13.4502 15 13.8151 15.3649 13.8151 15.815V23H12.1851V15.815Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11537_12426">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
