// useCounter.js
import { computed } from 'vue'
import type { GenerateVideoTask, VideoTranslateTask } from '@/api'
import { useI18n } from 'vue-i18n'
import { useClips2VideoOpts } from '@/views/Dashboard/hooks/useClips2VideoOpts'
import { useVideoTranslationOpts } from '@/views/Dashboard/hooks/useVideoTranslationOpts'

export interface TaskOpConfig {
  name: string
  type: string // 'cancel' | 'regenerate' | 'download'
  op: (...args: any) => Promise<any>
}
export const useTaskInfo = (task: GenerateVideoTask) => {
  const { t } = useI18n()
  const clips2VideoOpts = useClips2VideoOpts(task)
  const vtOpts = useVideoTranslationOpts(task as VideoTranslateTask)

  const isErrorState = computed(() => ['pending', 'canceled', 'failed'].includes(task.state))
  const isFinished = computed(() => ['finished'].includes(task.state))
  const isClips2Video = computed(() => 'clips-to-videos' === task.product)
  const isVT = computed(() => 'video-translation' === task.product)

  const optConfigs = computed<TaskOpConfig[]>(() => {
    const c = []

    switch (task.state) {
      case 'pending':
        if (isClips2Video.value) {
          c.push({
            name: t('取消'),
            type: 'cancel',
            op: async () => {
              await clips2VideoOpts.cancelTask()

              // TODO vt没有cancel
              // isVT.value && vtOpts.cancelTask()
            }
          })
        }
        break
      case 'canceled':
      case 'failed':
        // c.push({
        //   name: t('重新生成'),
        //   type: 'regenerate',
        //   op: async () => {
        //     isClips2Video.value && (await clips2VideoOpts.redoTask())
        //     isVT.value && (await vtOpts.redoTask())
        //   }
        // })
        break
      case 'finished':
        if (isVT.value) {
          c.push({
            name: t('下载'),
            type: 'download',
            op: async (event: MouseEvent) => {
              await vtOpts.downloadVideo(event)
            }
          })
        }
    }

    return c || []
  })

  return {
    isErrorState,
    isFinished,
    isClips2Video,
    isVT,
    optConfigs
  }
}
