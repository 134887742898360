<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4.60449 5.95703C4.60449 4.61084 5.6958 3.51953 7.04199 3.51953H18.9587C20.3049 3.51953 21.3962 4.61084 21.3962 5.95703V16.2904C21.3962 19.7076 18.6259 22.4779 15.2087 22.4779H7.04199C5.6958 22.4779 4.60449 21.3866 4.60449 20.0404V5.95703Z"
        stroke="currentColor"
        stroke-width="1.625"
      />
      <path
        d="M12.9331 8.66797L17.3335 8.66798"
        stroke="currentColor"
        stroke-width="1.625"
        stroke-linecap="round"
      />
      <path
        d="M8.66699 13L17.3337 13"
        stroke="currentColor"
        stroke-width="1.625"
        stroke-linecap="round"
      />
      <path
        d="M8.66699 17.332L17.334 17.332"
        stroke="currentColor"
        stroke-width="1.625"
        stroke-linecap="round"
      />
      <path
        d="M8 8.07637L9.15273 9.2291L10.8818 7.5"
        stroke="currentColor"
        stroke-width="1.63"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
