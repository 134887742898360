<script setup lang="ts">
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  onActivated,
  computed,
  watch,
  type Component,
  getCurrentInstance
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type {
  ElSelect,
  FormInstance,
  FormRules,
  UploadInstance,
  UploadFile,
  UploadUserFile
} from 'element-plus'

import { ElMessage, ElMessageBox, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

//import { DArrowRight } from '@element-plus/icons-vue'
/*import {
  Delete as IconDelete,
} from '@element-plus/icons-vue'*/
import { Plus, InfoFilled } from '@element-plus/icons-vue'

import IconDelete from '@/components/icons/IconDelete.vue'
import IconDiamond from '@/components/icons/IconDiamond.vue'
import IconDArrowDown from '@/components/icons/IconDArrowDown.vue'
import IconDeviceVertical from '@/components/icons/IconDeviceVertical.vue'
import IconDeviceHorizontal from '@/components/icons/IconDeviceHorizontal.vue'
import IconGenerateFunc from '@/components/icons/IconGenerateFunc.vue'
import IconGenerateStar from '@/components/icons/IconGenerateStar.vue'

import UploadMaterialWrapper from '@/components/UploadMaterialWrapper.vue'
import UploadAttachmentWrapper from '@/components/UploadAttachmentWrapper.vue'
import SoundPlayer from '@/components/SoundPlayer.vue'
import VoiceTemplateSelect from '@/components/VoiceTemplateSelect.vue'
import VideoTemplateSelect from '@/components/VideoTemplateSelect.vue'

import {
  type Material,
  type Attachment,
  type VoiceTemplate,
  type VideoTemplate,
  getDemoData,
  genRecommendProductFeatures,
  createGenerateVideoTask
} from '@/api'
import { getLanguages, getLanguage, type LanguageOption } from '@/common'
import { costQuotasMap } from '@/config'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import {
  generateConfirmMessage,
  generateFreeUserGuideMessage,
  showFreeUserGuide,
  showSubmitConfirm
} from '@/utils/creditsCostConfirm'
import FeedbackGuideInfo from '@/components/feedback/FeedbackGuideInfo/FeedbackGuideInfo.vue'

const { t, locale: i18nLocale } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

onActivated(() => {
  replaceUrlParams()
})

let requestTask: AbortController | null = null
const isLoading = ref(false)

const isSubmitting = ref(false)
const uploadRef = ref<typeof UploadMaterialWrapper>()
const uploadProgress = ref(0)
const collapsedItem = ref('')
const recommendProductFeatures = ref<string[]>([])
const isRecommendProductFeaturesLoading = ref(false)
const refProductFeatures = ref<InstanceType<typeof ElSelect>>()

const formItemVoiceListError = ref('')
const soundPlayerRef = ref<typeof SoundPlayer>()
//const voicePlayerRef = ref<typeof SoundPlayer>()
const voicePlayerRef = ref<HTMLAudioElement>()
const dialogVisibleChooseVoice = ref(false)
const dialogVisibleVideoTemplate = ref(false)

const currencies: {
  label: string
  value: string
}[] = [
  {
    label: '$',
    value: 'USD'
  },
  {
    label: '¥',
    value: 'RMB'
  }
]
const videoDurations: {
  label: string
  value: string
}[] = [
  {
    label: t('任意'), // Any
    value: 'any'
  },
  {
    label: '15s',
    value: '10-20s'
  },
  {
    label: '30s',
    value: '20-40s'
  },
  {
    label: '45s',
    value: '30-60s'
  }
]
const videoRatios: {
  icon: Component
  label: string
  value: string
}[] = [
  {
    icon: IconDeviceVertical,
    label: '9:16',
    value: '9:16'
  },
  {
    icon: IconDeviceHorizontal,
    label: '16:9',
    value: '16:9'
  }
]
const videoResolutions = ref<
  {
    label: string
    value: string
  }[]
>([
  {
    label: '720P',
    value: '720P'
  }
])
if (projStore.res_1080p) {
  videoResolutions.value.push({
    label: '1080P',
    value: '1080P'
  })
}
watch(
  () => projStore.res_1080p,
  () => {
    if (projStore.res_1080p) {
      videoResolutions.value = [
        {
          label: '720P',
          value: '720P'
        },
        {
          label: '1080P',
          value: '1080P'
        }
      ]
    } else {
      videoResolutions.value = [
        {
          label: '720P',
          value: '720P'
        }
      ]
    }
  }
)
const videoRatioResolutionMap: {
  [key: string]: {
    [key: string]: string
  }
} = {
  '9:16': {
    '720P': '720x1280',
    '1080P': '1080x1920'
  },
  '16:9': {
    '720P': '1280x720',
    '1080P': '1920x1080'
  }
}
const videoLanguages = getLanguages('clips-to-videos')

const formRef = ref<FormInstance>()
interface Form {
  materialList: Material[]
  productName: string
  productFeatures: string[]
  currency: (typeof currencies)[0]
  salePrice: string
  promotion: string
  brandName: string
  brandLogo: string
  brandLogoUrl: string
  videoDuration: string
  videoRatio: string
  videoResolution: string
  videoLanguage: LanguageOption
  voiceList: VoiceTemplate[]
  bgmAutoAdd: boolean
  videoTemplates: VideoTemplate[]
  videoAnimation: VideoTemplate['animations'][0] | null
}
const form = reactive<Form>({
  materialList: [],
  productName: '',
  productFeatures: [],
  currency: currencies[0],
  salePrice: '',
  promotion: '',
  brandName: '',
  brandLogo: '',
  brandLogoUrl: '',
  videoDuration: videoDurations[0].value,
  videoRatio: videoRatios[0].value,
  videoResolution: videoResolutions.value[0].value,
  videoLanguage: videoLanguages[0],
  voiceList: [],
  bgmAutoAdd: true,
  videoTemplates: [],
  videoAnimation: null
})
const getVideoDurationDesc = () => {
  let desc = ''
  videoDurations.some((videoDuration) => {
    if (videoDuration.value === form.videoDuration) {
      desc = videoDuration.label
    }
    return !!desc
  })
  return desc || form.videoDuration // any
}
const checkProductName = (rule: object, value: string, callback: (error?: Error) => void) => {
  if (!value || !value.trim()) {
    callback(new Error(t('{object}必填', { object: t('产品名称') })))
    return
  }
  callback()
}
const checkProductFeatures = (rule: object, value: string[], callback: (error?: Error) => void) => {
  const val = value.join(',')
  if (val.length > 200) {
    callback(new Error(t('{object}不能超过{max}个字符', { object: t('产品特性'), max: 200 })))
    return
  }
  callback()
}
const checkSalePrice = (rule: object, value: string, callback: (error?: Error) => void) => {
  const val = String(value).trim()
  const precision = 2
  if (val.includes('.') && val.split('.')[1].length > precision) {
    callback(
      new Error(t('{object}小数后不能超过{precision}位', { object: t('销售价格'), precision }))
    )
    return
  }
  callback()
}
const checkVideoDuration = (rule: object, value: string, callback: (error?: Error) => void) => {
  let withImage = false
  let duration = 0
  form.materialList.some((material) => {
    if (!material.duration) {
      withImage = true
    }
    duration += material.duration / 1000
    return withImage
  })
  if (withImage || value === 'any') {
    callback()
    return
  }
  const getRange = (key: string) => {
    return value
      .replace(/(s|秒)/i, '')
      .split(key)
      .map((str) => {
        return Number(str.trim())
      })
  }
  let min = Infinity
  if (value.includes('-')) {
    min = getRange('-')[0]
  } else if (value.includes('<')) {
    min = 0.1
  } else if (value.includes('>')) {
    min = getRange('>')[1]
  }
  if (duration < min) {
    callback(new Error(t('上传的视频片段长度不足以生成符合要求的完整视频，请添加更多视频片段')))
    return
  }
  callback()
}
const rules = reactive<FormRules<Form>>({
  // age: [{ validator: checkAge, trigger: 'blur' }],
  // projId: [],
  materialList: [],
  productName: [
    { required: true, message: t('{object}必填', { object: t('产品名称') }) },
    { validator: checkProductName },
    { max: 50, message: t('{object}不能超过{max}个字符', { object: t('产品名称'), max: 50 }) }
  ],
  productFeatures: [
    { required: true, message: t('{object}必填', { object: t('产品特性') }) },
    { validator: checkProductFeatures }
  ],
  currency: [],
  salePrice: [{ validator: checkSalePrice }],
  promotion: [
    { max: 200, message: t('{object}不能超过{max}个字符', { object: t('推广信息'), max: 200 }) }
  ],
  brandName: [
    { max: 20, message: t('{object}不能超过{max}个字符', { object: t('品牌名称'), max: 20 }) }
  ],
  brandLogo: [],
  // brandLogoUrl: [],
  videoDuration: [{ validator: checkVideoDuration }],
  videoRatio: [],
  // videoResolution: [],
  videoLanguage: []
})

const replaceUrlParams = () => {
  //router.replace({ params: { projId: projStore.projId || '-' } })
  history.replaceState(
    {},
    '',
    location.hash
      .replace(/\/-$/, `/${projStore.projId || '-'}`)
      .replace(/\/\d+$/, `/${projStore.projId || '-'}`)
  )
}

onMounted(() => {
  // https://github.com/element-plus/element-plus/blob/2.6.1/packages/components/select-v2/src/useSelect.ts#L702
  // handleClickOutside
  // https://github.com/element-plus/element-plus/blob/2.6.1/packages/components/select-v2/src/useSelect.ts#L782
  // node_modules/element-plus/es/components/select/src/useSelect.mjs
  watch(
    () => refProductFeatures.value?.expanded,
    (val) => {
      if (!val) {
        const value = refProductFeatures.value?.states.inputValue.trim()
        if (value) {
          addToProductFeatures(value)
        }
      }
    }
  )

  watch(
    () => form.materialList.length,
    (val) => {
      if (!val) {
        uploadProgress.value = 0
      }
    }
  )

  watch(
    () => form.materialList,
    () => {
      formRef.value?.validateField('videoDuration')
    }
  )

  watch(
    () => form.videoLanguage,
    () => {
      form.videoTemplates = []
      form.videoAnimation = null
      form.voiceList = []
    }
  )

  watch(
    () => form.videoRatio,
    () => {
      form.videoTemplates = []
    }
  )

  watch(
    () => form.voiceList.length,
    (val) => {
      if (val) {
        formItemVoiceListError.value = ''
      }
    }
  )
})

const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
})

const addToProductFeatures = (item: string) => {
  if (!form.productFeatures.includes(item)) {
    form.productFeatures.push(item)
  }
  formRef.value?.validateField('productFeatures')
}
const recommendProductFeaturesLoad = () => {
  if (!projStore.autogen_feature) {
    showFreeUserGuide(userStore.feedbackTypes)
    return
  }
  if (isRecommendProductFeaturesLoading.value || !form.productName.trim()) {
    return
  }

  abortRequest()
  isRecommendProductFeaturesLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  const lang = getLanguage('clips-to-videos', i18nLocale.value)
  let language = ''
  let languageName = ''
  let languageNativeName = ''
  if (lang) {
    language = lang.value
    languageName = lang.label()
    languageNativeName = lang.nativeName
  }
  genRecommendProductFeatures(
    {
      projId: projStore.projId,
      productName: form.productName,
      language,
      languageName,
      languageNativeName
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      recommendProductFeatures.value = res.data.data || []
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isRecommendProductFeaturesLoading.value = false
    })
}

const selectAttachment = (attachments: Attachment[]) => {
  form.brandLogo = attachments[0].address
  form.brandLogoUrl = attachments[0].url
}

const onUploadProgress = (percentage: number) => {
  uploadProgress.value = percentage
}
const getUploadingState = () => {
  return uploadProgress.value > 0 && uploadProgress.value !== 100
}
const isUploading = computed(getUploadingState)
const doCreate = async () => {
  if (isSubmitting.value || getUploadingState()) {
    return
  }

  const costQuotas = costQuotasMap['clips-to-videos']

  if (costQuotas <= 0) {
    doCreateAction(costQuotas)
    return
  }

  if (projStore.total_remaining < costQuotas) {
    isSubmitting.value = true
    await fetchSubscriptionPlanAndCreditDetails(projStore)
    isSubmitting.value = false
  }

  showSubmitConfirm(
    {
      title: t('将剪辑提交到视频任务'),
      costQuotas,
      totalRemaining: projStore.total_remaining,
      feedbackTypes: userStore.feedbackTypes,
      from: 'clips-to-videos-create'
    },
    {
      beforeClose(action, instance, done) {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true

          doCreateAction(costQuotas, instance, done)
        } else {
          done()
        }
      }
    }
  )
}
const doCreateAction = (costQuotas: number, instance?: MessageBoxState, done?: () => void) => {
  if (isSubmitting.value) {
    return
  }

  const mediaUrls: string[] = form.materialList
    .map((material) => {
      return material.address
    })
    .filter((url) => {
      return !!url
    })
  if (!mediaUrls.length) {
    return
  }

  abortRequest()
  isSubmitting.value = true

  const controller = new AbortController()
  requestTask = controller
  createGenerateVideoTask(
    {
      projId: projStore.projId,
      title: form.productName.trim(),
      config: {
        request_id: '',
        request_source: 'web',
        proj_id: projStore.projId,
        parent_task_id: '',
        task_id: '',
        task_type: 'clips2video',
        user_id: userStore.id,
        task_params: {
          media_urls: mediaUrls,
          product_params: {
            product_name: form.productName.trim(),
            product_features: form.productFeatures,
            price: !form.salePrice || !form.salePrice.trim() ? null : Number(form.salePrice.trim()),
            currency: form.currency.value,
            currency_symbol: form.currency.label,
            promotion: form.promotion.trim(),
            brand: {
              name: form.brandName.trim(),
              logo: form.brandLogo
            }
          },
          video_params: {
            video_resolution_ratio: form.videoRatio,
            video_resolution: videoRatioResolutionMap[form.videoRatio][form.videoResolution],
            video_locale: form.videoLanguage.value,
            video_language: form.videoLanguage.nativeName,
            video_duration: form.videoDuration,
            video_duration_desc: getVideoDurationDesc(),
            video_numbers: {
              ae: 2,
              mix: 2
            },
            speech_mute: !form.voiceList.length,
            speech: {
              provider: form.voiceList[0]?.provider || '',
              voice_id: form.voiceList[0]?.voiceId || '',
              speed: 1.0,
              tags: form.voiceList[0]?.tags || []
            },
            text: {
              font_path: 's3://s3-vmeg-pro/web/fonts/',
              template: form.videoTemplates[0]?.config || {},
              title: form.productName.trim(),
              subtitle: !!form.videoTemplates.length,
              animation: form.videoAnimation?.name
            },
            video_bgm: form.bgmAutoAdd ? 'auto' : ''
          }
        },
        config: {
          env: '',
          version: '1.0'
        }
      }
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('任务提交异常'))
        return
      }
      ElMessage.success(res.data.message || t('任务提交成功'))
      resetForm(formRef.value)
      if (costQuotas > 0) {
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })
        fetchSubscriptionPlanAndCreditDetails(projStore)
      }
      router.push({
        name: 'vmeg.my-tasks.details',
        params: { projId: projStore.projId, taskId: res.data.data.id }
      })

      if (done) {
        done()
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('任务提交失败'))
    })
    .finally(() => {
      if (instance) {
        instance.confirmButtonLoading = false
      }

      isSubmitting.value = false
    })
}
const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return
  }
  // @ts-ignore
  formEl.validate((isValid, invalidFields) => {
    if (isValid) {
      /*if (!form.voiceList.length) {
        formItemVoiceListError.value = t('{object}必选', { object: t('AI声音') })
        return false
      }*/
      doCreate()
      return Promise.resolve()
    }
    let withHideField = false
    ;['salePrice', 'promotion', 'brandName', 'brandLogo'].some((field: string) => {
      if (!invalidFields) {
        return Promise.reject()
      }
      withHideField = !!invalidFields[field]
      if (withHideField) {
        return Promise.resolve()
      }
      return Promise.reject()
    })
    if (withHideField) {
      collapsedItem.value = '#1'
    }
    return Promise.reject()
  })
}
const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return
  }

  const ref = uploadRef.value?.getUploadRef()?.value as UploadInstance | null
  if (ref) {
    form.materialList.forEach((material) => {
      const file = material as UploadUserFile
      ref.abort(file as UploadFile)
    })
    ref.clearFiles()
  }

  formEl.resetFields()
  formItemVoiceListError.value = ''

  form.currency = currencies[0]
  form.brandLogoUrl = ''
  form.videoResolution = videoResolutions.value[0].value

  isLoading.value = false
  isSubmitting.value = false
  uploadProgress.value = 0
  collapsedItem.value = ''
  recommendProductFeatures.value = []
  isRecommendProductFeaturesLoading.value = false

  abortRequest()
}

const demoData = ref<{
  materialList: Material[]
  productName: string
  productFeatures: string[]
}>({
  materialList: [],
  productName: '',
  productFeatures: []
})
const useDemo = () => {
  Object.assign(form, {
    ...demoData.value,
    materialList: [...demoData.value.materialList],
    productFeatures: [...demoData.value.productFeatures],
    projId: projStore.projId
  })
}
getDemoData()
  .then((res: any) => {
    demoData.value = res
  })
  .catch((err) => {
    if (err.code === 'ERR_CANCELED' || err.status === 401) {
      return
    }
    console.error(err.message || t('加载失败'))
  })

const selectedVoice = (materials: VoiceTemplate[]) => {
  form.voiceList = materials
}
const selectedSubtitle = (
  materials: VideoTemplate[],
  animation: VideoTemplate['animations'][0]
) => {
  form.videoTemplates = materials
  if (isSubtitleStyle) {
    form.videoAnimation = animation
  }
}
const stopAllSound = () => {
  soundPlayerRef.value?.stop()
  //voicePlayerRef.value?.stop()
  voicePlayerRef.value?.pause()
}
const getMediaSrc = (material: Material) => {
  if (!material) {
    return ''
  }
  const { url, audio, address } = material
  if (!audio && !address) {
    return url
  }
  if (audio && (audio.includes('https://') || audio.includes('http://'))) {
    return audio
  }
  const filename = (audio ? audio : address).split('/').slice(-1).join('')
  return `${url.split('/').slice(0, -1).join('/')}/${filename}`
}
const onSoundPlay = (id: string) => {
  if (id === soundPlayerRef.value?.id) {
    voicePlayerRef.value?.pause()
  } else if (id === voicePlayerRef.value?.id) {
    soundPlayerRef.value?.pause()
  }
}
const isNoTitle = true
const isSubtitleStyle = true
const getThumbnailUrl = (url: string) => {
  if (isSubtitleStyle) {
    return url
      .replace('/templates/full-style/', '/templates/subtitle-style/')
      .replace('/1x/H', '/3x/')
      .replace('/1x/W', '/3x/')
  }
  return url.replace('/1x/', '/2x/')
}
const getBgUrl = (url: string) => {
  return `${url.split('/templates/')[0]}/templates/full-bg/1x/${url.split('/').slice(-1)}`.replace(
    '.png',
    '.webp'
  )
}
</script>

<template>
  <el-form
    :class="{ 'form-wrapper-mini': form.materialList.length }"
    ref="formRef"
    :model="form"
    :rules="rules"
    label-position="top"
    require-asterisk-position="right"
    scroll-to-error
    :scroll-into-view-options="{ behavior: 'smooth' }"
  >
    <el-form-item class="choose-file-form-item steps">
      <el-steps :active="form.materialList.length ? 1 : 0" finish-status="success">
        <el-step :title="t('上传素材')" />
        <el-step :title="t('填写信息')" />
        <el-step :title="t('生成视频')" />
      </el-steps>
    </el-form-item>

    <el-form-item class="choose-file-form-item" :label="t('添加素材')" prop="materialList">
      <UploadMaterialWrapper
        ref="uploadRef"
        v-model="form.materialList"
        @upload:progress="onUploadProgress"
        :file-format="t('MP4、MOV、WEBM、WEBP、JPG、JPEG、PNG')"
        :limit="10"
        :demo-data="demoData"
        :use-demo="useDemo"
      />
    </el-form-item>
    <el-form-item :label="t('产品名称')" prop="productName" required>
      <el-input v-model="form.productName" :placeholder="t('手机壳')" />
    </el-form-item>
    <el-form-item :label="t('产品特性')" prop="productFeatures" required>
      <el-select
        class="input-product-features"
        v-model="form.productFeatures"
        multiple
        :placeholder="t('时尚、耐用、防水')"
        filterable
        allow-create
        popper-class="el-popper-hidden"
        :reserve-keyword="false"
        default-first-option
        suffix-icon=""
        ref="refProductFeatures"
      />
    </el-form-item>
    <el-form-item class="recommend-product-features">
      <el-button
        type="primary"
        link
        :disabled="!form.productName?.trim()"
        :icon="IconGenerateFunc"
        :class="{ loading: isRecommendProductFeaturesLoading }"
        @click="recommendProductFeaturesLoad"
      >
        {{ t('自动生成') }}
      </el-button>
      <div class="tags">
        <template v-for="(item, index) in recommendProductFeatures" :key="index">
          <el-tag
            type="info"
            effect="plain"
            round
            @click="addToProductFeatures(item)"
            v-if="!form.productFeatures.includes(item)"
          >
            {{ item }}
          </el-tag>
        </template>
      </div>
    </el-form-item>
    <el-collapse accordion v-model="collapsedItem">
      <el-collapse-item name="#1">
        <template #title>
          <div>{{ collapsedItem != '#1' ? t('更多详情') : t('隐藏详情') }}</div>
          <!-- <el-icon class="reverse-90" :class="{ 'reverse-270': collapsedItem == '#1' }"><DArrowRight /></el-icon> -->
          <el-icon :class="{ 'reverse-180': collapsedItem == '#1' }"><IconDArrowDown /></el-icon>
        </template>
        <el-form-item class="align-items-flex-start">
          <el-col :span="7" :xs="11">
            <el-form-item :label="t('销售价格')" prop="salePrice">
              <el-input type="number" v-model="form.salePrice">
                <template #prepend>
                  <el-select v-model="form.currency">
                    <el-option
                      v-for="item in currencies"
                      :key="item.value"
                      :label="item.label"
                      :value="item"
                      :value-key="item.value"
                    />
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" />
          <el-col :span="16" :xs="24">
            <el-form-item :label="t('推广信息')" prop="promotion">
              <el-input type="textarea" v-model="form.promotion" :placeholder="t('推广信息')" />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item class="align-items-flex-start">
          <el-col :span="7" :xs="11">
            <el-form-item :label="t('品牌名称')" prop="brandName">
              <el-input v-model="form.brandName" :placeholder="t('品牌')" />
            </el-form-item>
          </el-col>
          <el-col :span="1" />
          <el-col :span="16" :xs="12">
            <el-form-item :label="t('品牌图标')" prop="brandLogo" style="display: none">
              <UploadAttachmentWrapper
                @upload:change="selectAttachment"
                :file-format="t('WEBP、JPG、JPEG、PNG')"
                :url="form.brandLogoUrl"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
    <el-form-item>
      <el-col :span="5" :xs="11" style="display: none">
        <el-form-item :label="t('视频时长')" prop="videoDuration">
          <el-select v-model="form.videoDuration">
            <el-option
              v-for="item in videoDurations"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" style="display: none" />
      <el-col :span="5" :xs="11">
        <el-form-item :label="t('视频比例')" prop="videoRatio">
          <el-select v-model="form.videoRatio">
            <el-option
              class="g-display-flex g-align-items-center g-gap-6"
              v-for="item in videoRatios"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <el-icon><component :is="item.icon" /></el-icon>
              <span>{{ item.label }}</span>
            </el-option>
            <template #prefix>
              <template v-for="item in videoRatios" :key="item.value">
                <el-icon v-if="form.videoRatio == item.value">
                  <component :is="item.icon" />
                </el-icon>
              </template>
            </template>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" />
      <el-col :span="5" :xs="11">
        <el-form-item :label="t('视频分辨率')" prop="videoResolution">
          <el-select v-model="form.videoResolution">
            <el-option
              v-for="item in videoResolutions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" />
      <el-col :span="6" :xs="11">
        <el-form-item :label="t('视频语言')" prop="videoLanguage">
          <el-select v-model="form.videoLanguage">
            <el-option
              v-for="item in videoLanguages"
              :key="item.value"
              :label="item.label()"
              :value="item"
              :value-key="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item prop="voiceList" :error="formItemVoiceListError">
      <template #label>
        <span>{{ t('选择AI声音') }}</span>
        <el-icon style="display: none"
          ><IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true"
        /></el-icon>
        <el-tooltip :content="t('如果没有选择声音，输出视频将不会被配音')" effect="light">
          <el-icon><InfoFilled /></el-icon>
        </el-tooltip>
      </template>
      <el-icon
        class="add-plus"
        @click="dialogVisibleChooseVoice = true"
        v-if="!form.voiceList.length"
      >
        <Plus />
      </el-icon>
      <div class="add-done" v-else>
        <div class="list-item-wrapper">
          <SoundPlayer
            ref="soundPlayerRef"
            :src="getMediaSrc(form.voiceList[0])"
            id="soundPlayerRef"
            @play="onSoundPlay('soundPlayerRef')"
          />
          <div class="list-item-content">
            <div class="list-item-title">{{ form.voiceList[0].name }}</div>
            <div class="list-item-tags">{{ form.voiceList[0].tags?.join(', ') }}</div>
          </div>
        </div>
        <el-icon class="del-icon" @click="form.voiceList = []"><IconDelete /></el-icon>
      </div>
    </el-form-item>
    <el-form-item prop="bgmAutoAdd">
      <template #label>
        {{ t('背景音乐') }}
        <el-icon style="display: none"
          ><IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true"
        /></el-icon>
      </template>
      <el-checkbox
        class="my-checkbox g-checkbox"
        v-model="form.bgmAutoAdd"
        :label="t('自动添加')"
      />
    </el-form-item>
    <el-form-item prop="videoTemplates">
      <template #label>
        <span>{{ t('添加字幕') }}</span>
        <el-icon style="display: none"
          ><IconDiamond :id="`id_linear_${Math.random()}`" :highlight="true"
        /></el-icon>
        <el-tooltip :content="t('如果未选择模板，输出视频将不会显示字幕')" effect="light">
          <el-icon><InfoFilled /></el-icon>
        </el-tooltip>
      </template>
      <el-icon
        class="add-plus"
        @click="dialogVisibleVideoTemplate = true"
        v-if="!form.videoTemplates.length"
      >
        <Plus />
      </el-icon>
      <div class="add-done" v-else>
        <div class="list-item-image">
          <el-image
            fit="contain"
            :src="getBgUrl(form.videoTemplates[0].thumbnail)"
            v-if="!isSubtitleStyle"
          />
          <el-image
            :class="{
              'is-subtitle-style': isSubtitleStyle,
              'is-no-title': !isSubtitleStyle && isNoTitle
            }"
            :preview-src-list="
              isSubtitleStyle
                ? [getThumbnailUrl(form.videoTemplates[0].thumbnail)].concat(
                    form.videoAnimation?.image ? [form.videoAnimation.image] : []
                  )
                : []
            "
            :preview-teleported="!isSubtitleStyle"
            fit="contain"
            :src="getThumbnailUrl(form.videoTemplates[0].thumbnail)"
          />
        </div>
        <div class="list-item-image" v-if="form.videoAnimation?.image">
          <el-image
            class="is-subtitle-style"
            :preview-src-list="[
              form.videoAnimation.image,
              getThumbnailUrl(form.videoTemplates[0].thumbnail)
            ]"
            fit="contain"
            :src="form.videoAnimation.image"
          />
        </div>
        <el-icon class="del-icon" @click="form.videoTemplates = []"><IconDelete /></el-icon>
      </div>
    </el-form-item>

    <el-form-item class="fixed-shadow"></el-form-item>
    <el-form-item class="fixed-main">
      <el-button
        type="primary"
        :icon="IconGenerateStar"
        :loading="isSubmitting"
        :disabled="isUploading"
        @click="submitForm(formRef)"
      >
        {{ t('生成') }}
      </el-button>
      <el-button text @click="resetForm(formRef)">
        {{ t('取消') }}
      </el-button>
    </el-form-item>
  </el-form>

  <VoiceTemplateSelect
    v-model="dialogVisibleChooseVoice"
    @selected:done="selectedVoice"
    @stop-all-sound="stopAllSound"
    :language="form.videoLanguage.value"
  />

  <VideoTemplateSelect
    v-model="dialogVisibleVideoTemplate"
    @selected:done="selectedSubtitle"
    :language="form.videoLanguage.value"
    :resolution="videoRatioResolutionMap[form.videoRatio][form.videoResolution]"
    :is-no-title="isNoTitle"
    :is-subtitle-style="isSubtitleStyle"
  />
</template>

<style lang="less">
.el-popper.el-select__popper.el-popper-hidden {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .el-popper.no-tap-display {
    display: none;
  }
}
</style>

<style scoped lang="less">
.el-form {
  --my-max-upload-width: 800px;
  --my-el-steps-height: 32px;
  width: 100%;
  height: calc(100% - var(--my-el-steps-height));
  padding: 16px;
  box-sizing: border-box;

  .el-form-item {
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    &:not(.choose-file-form-item),
    :deep(.el-form-item__label) {
      display: none;
    }

    :deep(.el-form-item__content) {
      width: 100%;
      height: 100%;

      .add-plus {
        font-size: 20px;
        color: var(--el-color-primary);
        width: 58px;
        height: 58px;
        border: 1px solid var(--my-color-primary-50);
        border-radius: 8px;
        cursor: pointer;
      }

      .add-done {
        display: flex;
        align-items: center;
        gap: 12px;

        .list-item-wrapper {
          display: flex;
          align-items: center;
          height: 58px;
          border: 1px solid var(--my-color-black-20);
          border-radius: 8px;
          gap: 12px;
          padding: 0 14px;

          .list-item-content {
            font-family:
              SF Pro Text,
              var(--el-font-family);

            .list-item-title {
              font-size: 16px;
              line-height: 22px;
              color: var(--my-color-black-89-90);
            }

            .list-item-tags {
              font-size: 14px;
              line-height: 20px;
              color: var(--my-color-black-40);
            }
          }
        }

        .list-item-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 58px;
          height: 58px;
          border: 1px solid var(--my-color-black-20);
          border-radius: 8px;

          position: relative;

          .el-image {
            position: absolute;

            width: 56px;
            height: 56px;
            border-radius: 6px;

            &.is-no-title {
              clip: rect(20px, 56px, 56px, 0);
            }

            &.is-subtitle-style {
              .el-image-viewer__wrapper {
                .el-image-viewer__canvas {
                  .el-image-viewer__img {
                    border-radius: 26px;
                  }
                }
              }
            }
          }
        }

        .del-icon {
          font-size: 16px;
          color: var(--el-color-primary);
          cursor: pointer;
        }
      }

      .my-checkbox.el-checkbox {
        --el-border-color-my-1: var(--my-color-x16);
        --el-checkbox-border-radius: 3px;

        .el-checkbox__input {
          position: relative;
          width: unset;
          //height: unset;

          .el-checkbox__inner {
            position: relative;
            top: unset;
            right: unset;
            --el-checkbox-input-width: 16px;
            --el-checkbox-input-height: 16px;
          }

          &.is-checked,
          &:not(.is-disabled) {
            .el-checkbox__inner {
              &:after {
                left: 5px;
                top: 1px;
              }
            }
          }
        }
      }
    }

    &.steps {
      height: var(--my-el-steps-height);

      display: flex;
      justify-content: center;

      :deep(.el-form-item__content) {
        max-width: var(--my-max-upload-width);

        display: block;
        height: var(--my-el-steps-height);
      }

      .el-steps {
        height: var(--my-el-steps-height);
        overflow: hidden;
        justify-content: center;

        .el-step {
          flex-basis: 33.3333% !important;

          &:last-child {
            flex-basis: content !important;

            :deep(.el-step__main) {
              .el-step__title {
                padding-right: 0;
              }
            }
          }

          :deep(.el-step__head) {
            .el-step__line {
              height: 1px;
              top: 15px;
              right: 16px;
            }

            .el-step__icon {
              width: 28px;
              height: 28px;
              font-size: 18px;
            }

            &.is-process {
              color: var(--my-color-white-100);
              border-color: var(--el-color-primary);

              .el-step__icon {
                background: var(--el-color-primary);
              }
            }

            &.is-success {
              color: var(--el-color-primary);
              border-color: var(--el-color-primary);

              .el-step__icon {
                .el-step__icon-inner.is-status {
                  margin-top: -4px;
                  transform: translateY(2px);
                }
              }
            }
          }

          :deep(.el-step__main) {
            position: relative;
            left: 24px;
            top: -32px;

            .el-step__title {
              display: inline-block;
              font-size: 18px;
              padding-left: 12px;
              padding-right: 16px;
              background: var(--my-color-white-100);
              line-height: var(--my-el-steps-height);

              &.is-process {
                font-weight: 500;
                //color: var(--el-text-color-placeholder);
              }

              &.is-success {
                font-weight: 500;
                color: var(--my-color-black-89-90);
              }
            }
          }
        }
      }
    }
  }

  .el-collapse {
    display: none;
  }
}

.el-form.form-wrapper-mini {
  height: unset;

  .el-form-item {
    height: unset;
    margin-bottom: 18px;

    --my-form-footer-height: 100px;

    &.steps {
      margin-bottom: 32px;

      :deep(.el-form-item__content) {
        max-width: unset;
      }

      .el-steps {
        justify-content: left;
      }
    }

    &.fixed-shadow {
      height: calc(var(--my-form-footer-height) - var(--el-main-padding));
      margin-bottom: 0;
    }

    &.fixed-main {
      position: fixed;
      bottom: 0;
      height: var(--my-form-footer-height);
      margin-bottom: 0;
      background-color: var(--el-bg-color);
      margin-left: calc(1px - var(--el-main-padding)); // 1px: .app-aside .aside-fixed border-right
      padding-left: var(--el-main-padding);
      box-shadow: 0 -4px 12px 0 var(--my-color-black-6);
      z-index: 2;

      :deep(.el-form-item__content) {
        margin-top: 24px;

        gap: 24px;
      }
    }

    &:not(.choose-file-form-item),
    :deep(.el-form-item__label) {
      display: block;
    }

    :deep(.el-form-item__label) {
      > span,
      .el-icon {
        vertical-align: middle;
        margin-right: 5px;
      }
    }

    :deep(.el-form-item__content) {
      height: unset;
    }
  }

  .el-collapse {
    display: block;
  }
}

.el-form {
  .el-collapse {
    border: none;

    :deep(.el-collapse-item__header) {
      border-bottom: none;
      --el-collapse-header-text-color: var(--my-color-black-89-90);
      --el-collapse-header-height: 22px;
      margin-bottom: 22px;
      width: unset;

      .el-icon:not(.el-collapse-item__arrow) {
        margin-left: 4px;
        color: var(--my-color-black-40);

        /*&.reverse-90 {
          transform: rotate(90deg);
        }

        &.reverse-270 {
          transform: rotate(270deg);
        }*/

        &.reverse-180 {
          transform: rotate(180deg);
        }
      }

      .el-collapse-item__arrow {
        display: none;
      }
    }

    :deep(.el-collapse-item__wrap) {
      border-bottom: none;

      .el-collapse-item__content {
        padding-bottom: 0;
      }
    }

    .el-col {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .el-input-group--prepend {
    :deep(.el-input-group__prepend) {
      background-color: var(--el-fill-color-blank);

      .el-select {
        --el-select-width: 50px;

        .el-select__wrapper {
          padding-right: 10px;
          gap: 2px;
        }
      }
    }
  }

  .el-form-item {
    .input-product-features {
      :deep(.el-select__wrapper) {
        align-items: flex-start;
        min-height: 80px;

        --el-tag-font-size: 12px;
        font-size: var(--el-tag-font-size);

        .el-select__selection {
          .el-select__selected-item {
            &.el-select__input-wrapper {
              .el-select__input {
                &:focus {
                  --el-tag-text-color: var(--el-color-info);

                  --el-tag-border-radius: 4px;
                  --el-tag-border-color: var(--el-color-primary-light-8);

                  border: 1px dashed var(--el-tag-border-color);
                  border-radius: var(--el-tag-border-radius);
                  color: var(--el-tag-text-color);
                  height: 22px;
                  padding: 0 25px 0 9px; // 6 + 14 + 5
                }
              }
            }
          }
        }
      }
    }

    &.recommend-product-features {
      :deep(.el-form-item__content) {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 16px;

        .el-button {
          .el-icon {
            font-size: 18px;
          }
        }

        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;

          .el-tag {
            cursor: pointer;
          }
        }
      }
    }

    &.align-items-flex-start {
      :deep(.el-form-item__content) {
        align-items: flex-start;
      }
    }

    :deep(.el-form-item__content) {
      .el-select__wrapper {
        .el-select__prefix {
          color: var(--el-input-icon-color, --el-text-color-placeholder);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .el-form {
    &.form-wrapper-mini {
      .el-form-item {
        &.steps {
          margin-bottom: 18px;
        }
      }
    }

    .el-form-item {
      &.steps {
        .el-steps {
          .el-step {
            flex-basis: content !important;
            flex-grow: 1;

            :deep(.el-step__head) {
              .el-step__line {
                display: none;
              }

              .el-step__icon {
                width: 20px;
                height: 20px;
                font-size: 12px; // font-size: 14px;
              }
            }

            :deep(.el-step__main) {
              left: 20px;

              .el-step__title {
                font-size: 12px; // font-size: 14px;
                padding-left: 6px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }

  .el-form.form-wrapper-mini {
    .el-form-item {
      --my-form-footer-height: 72px;

      &.fixed-main {
        :deep(.el-form-item__content) {
          margin-top: 20px;
        }
      }
    }
  }

  .el-form {
    .el-collapse {
      .el-col-xs-24 {
        .el-form-item {
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
