<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0.5H5.5L2 6.5H6L3 13.5L11 5.5H7.5L11 0.5Z"
      fill="#FFA51F"
      stroke="#FF7E33"
      stroke-linejoin="round"
    />
  </svg>
</template>
