<script setup lang="ts">
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'

const { isMobile } = useClientInfo()
</script>

<template>
  <div class="task-item-skeleton" :class="{ mobile: isMobile }">
    <el-skeleton animated>
      <template #template>
        <div class="task-item-skeleton-container">
          <div class="thumbnail">
            <el-skeleton-item />
          </div>
          <div class="intro row-1">
            <el-skeleton-item />
            <el-skeleton-item />
          </div>
          <div class="intro row-2">
            <el-skeleton-item />
            <el-skeleton-item />
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<style scoped lang="less">
.task-item-skeleton {
  display: inline-block;
  overflow: hidden;
  width: 256px;

  .el-skeleton__item {
    height: 100%;
  }

  .task-item-skeleton-container {
    width: 256px;
    height: 269px;
    overflow: hidden;

    .thumbnail {
      height: 206px;
      overflow: hidden;
      border-radius: 12px;
    }

    .intro {
      display: flex;
      justify-content: space-between;
    }

    .row-1 {
      margin: 14px 0 9px;
      height: 14px;

      .el-skeleton__item {
        &:first-child {
          width: 125px;
        }
        &:last-child {
          width: 94px;
        }
      }
    }

    .row-2 {
      height: 18px;

      .el-skeleton__item {
        &:first-child {
          width: 185px;
        }
        &:last-child {
          width: 19px;
        }
      }
    }
  }
}

.mobile.task-item-skeleton {
  width: 47.5%;
  height: auto;
  .task-item-skeleton-container {
    width: 100%;
    height: auto;
    overflow: hidden;

    .thumbnail {
      width: 100%;
      height: auto;
      aspect-ratio: calc(256 / 206);
      overflow: hidden;
      border-radius: 12px;
    }

    .intro {
      display: flex;
      justify-content: space-between;
    }

    .row-1 {
      margin: 14px 0 9px;
      height: 14px;

      .el-skeleton__item {
        &:first-child {
          width: 85px;
        }
        &:last-child {
          width: 44px;
        }
      }
    }

    .row-2 {
      margin: 14px 0 9px;
      height: 14px;

      .el-skeleton__item {
        &:first-child {
          width: 115px;
        }
        &:last-child {
          width: 15px;
        }
      }
    }
  }
}
</style>
