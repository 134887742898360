import { config } from '@/config'
import { useUserStore } from '@/stores/user'
import router from '.'

router.afterEach((to) => {
  // 一定要在afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
  // 或者判断to.forward, window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
  if (window.history.state && window.history.state.forward) {
    to.meta.isBack = true
  } else {
    to.meta.isBack = false
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'signout') {
    return next(false)
  }
  // checkAuth(to)
  const userStore = useUserStore()

  // TODO：全局校验逻辑优化，防止页面来回跳

  // if (to.meta.requiresAuth && !userStore.id) {
  //   return next({ name: 'signin', params: { from: to.fullPath } })
  // }
  if (
    !['user', 'home'].includes(String(to.name)) &&
    userStore.id &&
    !userStore.email &&
    !userStore.phone
  ) {
    return next({ name: 'user', params: { from: 'email' } })
  }

  const routeTitle = to.meta.title as (() => string) | undefined
  const routeIntro = to.meta.intro as (() => string) | undefined
  document.title = `${config.siteTitle}${routeIntro ? `-${routeIntro()}` : routeTitle ? `-${routeTitle()}` : ''}`

  const fromClass = from.meta.bodyClasses as string[] | undefined
  if (fromClass?.length) {
    fromClass.forEach((cls) => {
      document.body.classList.remove(cls)
    })
  } else {
    document.body.className = ''
    document.body.removeAttribute('class')
  }
  const toClass = to.meta.bodyClasses as string[] | undefined
  if (toClass?.length) {
    toClass.forEach((cls) => {
      document.body.classList.add(cls)
    })
  }

  next()
})

// 这段逻辑是从之前项目里copy过来的，在vmeg里没有任何意义
// const checkAuth = (to: RouteLocationNormalized) => {
//   const { code, state } = to.query
//   if (code) {
//     router.replace({
//       name: to.name || '',
//       query: {
//         ...to.query,
//         code: undefined
//       },
//       params: to.params
//     })
//     oauth2SignIn({
//       code: String(code),
//       state: String(state || '') || undefined
//     })
//       .then((res) => {
//         if (!res.data.data?.user) {
//           console.error(res.data)
//           return
//         }
//
//         const { email, phone } = res.data.data.user
//         updateUserStore(useUserStore(), res.data.data)
//
//         if (res.data?.code === -404) {
//           router.push({ name: 'signup', params: { from: 'register' } })
//           return
//         }
//
//         fetchProjData(useProjStore())
//
//         if (!email && !phone) {
//           router.push({ name: 'user', params: { from: 'email' } })
//           return
//         }
//
//         router.push({ path: to.fullPath })
//       })
//       .catch((err) => {
//         if (err.code === 'ERR_CANCELED' || err.status === 401) {
//           return
//         }
//       })
//   }
// }

export default router
