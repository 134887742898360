<script setup lang="ts">
import type { GoodsInfo } from '@/api'
import i18nHome from '@/locales/i18n-home'

defineProps<{ goodsInfo: GoodsInfo }>()
const { t } = i18nHome.global
</script>

<template>
  <div class="credit-count-info">
    <div class="credit-desc">
      <div class="credits">{{ goodsInfo.packageConfig.credits }} {{ t('credits') }}</div>
      <div class="period">/{{ t(goodsInfo.packageConfig.periodDesc) }}</div>
      <div class="tips" v-if="goodsInfo.packageConfig.tips">
        {{ t(goodsInfo.packageConfig.tips) }}
      </div>
    </div>
    <div class="price-each-desc" v-if="goodsInfo.packageConfig.originPrice">
      <div class="price">
        {{ goodsInfo.packageConfig.currencySymbol }}{{ goodsInfo.packageConfig.creditEach }}
      </div>
      <div class="period">/{{ t('credit') }}</div>
    </div>
  </div>
</template>

<style scoped lang="less">
.credit-count-info {
  display: flex;
  justify-content: space-between;

  .credit-desc {
    display: flex;
    align-items: center;
    font-family:
      SF Pro Text,
      var(--el-font-family);

    .credits {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .period {
      font-size: 16px;
      line-height: 24px;
    }

    .tips {
      margin-left: 6px;
      font-size: 12px;
      font-weight: 700;
      //line-height: 20px;
      color: #ff6b86;
    }
  }

  .price-each-desc {
    display: flex;

    .price {
      font-family:
        SF Pro Text,
        var(--el-font-family);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }

    .period {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: var(--my-color-black-89-90);
      white-space: nowrap;
    }
  }
}
</style>
