<script setup lang="ts">
import IconLoading from '@/components/icons/IconLoading.vue'

defineProps<{ height?: string; transform?: string }>()
</script>

<template>
  <div
    class="loading-animation"
    :style="{
      height: height,
      transform: transform
    }"
  >
    <IconLoading />
  </div>
</template>

<style scoped lang="less">
.loading-animation {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    animation: loading-rotate 1s linear infinite;
    transform-origin: center;
  }
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
