<script lang="ts" setup>
import DashboardSwiper from '@/views/Dashboard/pages/DashboardView/modules/DashboardSwiper.vue'
import ProductsList from '@/views/Dashboard/pages/DashboardView/modules/ProductsList.vue'
import RecentTaskList from '@/views/Dashboard/pages/DashboardView/modules/RecentTaskList.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'

const { isMobile } = useClientInfo()
</script>

<template>
  <div class="command-home" :class="{ mobile: isMobile }">
    <DashboardSwiper />
    <ProductsList />
    <RecentTaskList />
  </div>
</template>

<style scoped lang="less">
.command-home {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.command-home.mobile {
  padding-top: 0;
}
</style>
