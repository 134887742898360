const defaultStorage = {
  removeItem: (key: string) => {
    // empty
  },
  setItem: (key: string) => {
    // empty
  },
  getItem: (key: string) => {
    // empty
  }
}

export const getLocalStorage = () => {
  let storage
  try {
    storage = window.localStorage
  } catch (err) {
    console.error('read localstorage error:', err)
  }

  if (storage) return storage
  return defaultStorage
}

export const getSessionStorage = () => {
  let storage
  try {
    storage = window.sessionStorage
  } catch (err) {
    console.error('read sessionStorage error:', err)
  }

  if (storage) return storage
  return defaultStorage
}
