<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch } from 'vue'
import QRCode from 'qrcode'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import { CircleCheck, CircleCloseFilled } from '@element-plus/icons-vue'

import PageFooter from '@/components/PageFooter.vue'

import i18nHome from '@/locales/i18n-home'
import {
  type GoodsInfo,
  getGoodsList,
  getOrderList,
  genWeChatPaymentLink,
  recordReferral
} from '@/api'
import router, { getDefaultStartRoute, routeNameMap } from '@/router'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { useRoute } from 'vue-router'
import IconFlash from '@/components/icons/IconFlash.vue'
import PriceCutoffInfo from '@/views/Home/components/Pricing/modules/PriceCutoffInfo.vue'
import {
  annuallyAmountSaved,
  currencySymbol,
  enterprise,
  freePlanCredits,
  plansTable
} from './Home/utils/price'
import PriceInfo from '@/views/Home/components/Pricing/modules/PriceInfo.vue'
import CreditCountInfo from '@/views/Home/components/Pricing/modules/CreditCountInfo.vue'
import PricingSlogan from '@/views/Home/components/Pricing/modules/PricingSlogan.vue'
import PriceDetails from '@/views/Home/components/Pricing/modules/PriceDetails.vue'
import FAQ from '@/views/Home/components/Pricing/modules/FAQ.vue'

const { t } = i18nHome.global

const userStore = useUserStore()
const projStore = useProjStore()
const route = useRoute()

const baseDataReady = ref(false)
watch(
  () => projStore.projId,
  (val) => {
    if (val) {
      baseDataReady.value = true
      changeGoodsInfo()
    }
  }
)
onMounted(() => {
  if (projStore.projId) {
    baseDataReady.value = true
    changeGoodsInfo()
  }
})

const paymentCategory = ref('Strip')
const paymentCategories = ref<string[]>([])
const paymentGroup = ref<{
  [key: string]: GoodsInfo[]
}>({})
const paymentGroups = ref<{
  [key: string]: typeof paymentGroup.value
}>({})
const paymentList = ref<GoodsInfo[]>([])
const goodsInfo = ref<GoodsInfo | null>()
const paymentPeriod = ref('1') // 1 | 3 | 6 | 12 -> 月 | 季 | 半年 | 年
const paymentPeriods = ref<string[]>([])
const paymentCreditMarks = ref({})
const paymentGoodsIndex = ref(0)
const paymentLinkUrl = ref('')

let errCount = 0
let requestTask: AbortController | null = null
let requestTimer: ReturnType<typeof setTimeout> | null = null
const loadingWxQrCode = ref(false)
const paymentQrCodeBase64 = ref('')
const paymentWxDialogVisible = ref(false)
const paymentErrorMessage = ref('')
const loadingCheckPay = ref(false)

const fetchGoods = () => {
  getGoodsList({
    pageSize: 1000
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('Loading error'))
        return
      }
      paymentGroups.value = {}
      res.data.data.records.forEach((record) => {
        if (!paymentGroups.value[record.category]) {
          paymentGroups.value[record.category] = {}
        }
        if (!paymentGroups.value[record.category][record.packagePeriod]) {
          paymentGroups.value[record.category][record.packagePeriod] = []
        }
        paymentGroups.value[record.category][record.packagePeriod].push(record)
      })
      paymentCategories.value = Object.keys(paymentGroups.value).sort()
      if (!paymentCategories.value.includes(paymentCategory.value)) {
        paymentCategory.value = paymentCategories.value[0]
      }
      changePaymentCategory(paymentCategory.value)
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('Loading failed'))
    })
}
fetchGoods()

const changePaymentCategory = (category: string) => {
  paymentCategory.value = category
  paymentGroup.value = paymentGroups.value[paymentCategory.value]
  paymentPeriods.value = Object.keys(paymentGroup.value).sort((a, b) => {
    return Number(a) - Number(b)
  })
  changePaymentPeriod(paymentPeriods.value[0])
}

const changePaymentPeriod = (period: string) => {
  paymentPeriod.value = period
  paymentList.value = paymentGroup.value[paymentPeriod.value].sort((a, b) => {
    return a.packageConfig.credits - b.packageConfig.credits
  })
  paymentCreditMarks.value = Object.assign(
    {},
    paymentList.value.map((goodsInfo) => String(goodsInfo.packageConfig.credits))
  )
  paymentGoodsIndex.value = 0
  changeGoodsInfo()
}

const compositePaymentLink = (clientId: string) => {
  const url = goodsInfo?.value?.packageConfig?.paymentLink
  const promoCode = goodsInfo?.value?.packageConfig?.promoCode || ''

  if (!url) return ''

  return `${url}?client_reference_id=${clientId}&prefilled_email=${userStore.email}&prefilled_promo_code=${promoCode}`
}

const changeGoodsInfo = () => {
  goodsInfo.value = paymentList.value[paymentGoodsIndex.value] || null
  if (!goodsInfo.value) {
    return
  }
  const url = goodsInfo.value.packageConfig.paymentLink
  if (!url) {
    paymentLinkUrl.value = ''
    return
  }
  paymentLinkUrl.value = compositePaymentLink(`${projStore.projId}-${userStore.id}`)
}

const showWxQrCode = (goodsInfo: GoodsInfo) => {
  if (loadingWxQrCode.value) {
    return
  }
  loadingWxQrCode.value = true
  genWeChatPaymentLink({
    projId: projStore.projId,
    goodsId: goodsInfo.id,
    price: goodsInfo.price,
    quantity: 1,
    total: goodsInfo.price
  })
    .then((res) => {
      if (res.data.code) {
        loadingWxQrCode.value = false
        ElMessage.error(res.data.message || t('Loading error'))
        return
      }
      QRCode.toDataURL(res.data.data.wxPaymentLink, {
        width: 170,
        margin: 0
      })
        .then((url) => {
          paymentQrCodeBase64.value = url
          paymentWxDialogVisible.value = true
          startCheckOrderState(res.data.data.outTradeNo)
        })
        .catch((err) => {
          console.error(err.message)
        })
        .finally(() => {
          loadingWxQrCode.value = false
        })
    })
    .catch((err) => {
      loadingWxQrCode.value = false
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('Loading failed'))
    })
}

onUnmounted(() => {
  abortRequest()
  clearRequestTimeout()
})

const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}

const clearRequestTimeout = () => {
  if (requestTimer) {
    clearTimeout(requestTimer)
    requestTimer = null
  }
}

const startCheckOrderState = (outTradeNo: string) => {
  clearRequestTimeout()
  requestTimer = setTimeout(() => {
    doCheckOrderState(outTradeNo)
  }, 3000)
}
const doCheckOrderState = (outTradeNo: string) => {
  const controller = new AbortController()
  requestTask = controller
  getOrderList(
    {
      outTradeNo,
      projId: projStore.projId,
      currentPage: 1,
      pageSize: 1
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (!res.data.data?.records[0]?.deliveryNo) {
        startCheckOrderState(outTradeNo)
        return
      }
      fetchSubscriptionPlanAndCreditDetails(projStore)
      gotoWorkspace()
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      errCount++
      if (errCount < 3) {
        startCheckOrderState(outTradeNo)
        return
      }
      paymentErrorMessage.value = t('Payment status query failed')
    })
}

const letsTalkUrl = import.meta.env.DEV
  ? 'https://of2tfhisn1r.sg.larksuite.com/share/base/form/shrlgMrnfA2OELlwG7ncQzGjS6c'
  : 'https://of2tfhisn1r.sg.larksuite.com/share/base/form/shrlgctpbfld6lsdr488hkhiz4c'

const getPlanFeatures = (plan: (typeof plansTable)[0], planIndex: number) => {
  if (plan.title === enterprise) {
    const values = new Array(planIndex + 1).fill({})
    return [
      {
        title: 'All Features',
        values
      },
      {
        title: 'Customized Features',
        values
      },
      {
        title: 'Customized Credits',
        values
      },
      {
        title: 'Priority Customer Support',
        values
      }
    ]
  }
  return [
    {
      title: 'Cloud Storage',
      values: [{ value: '1GB' }, { value: '100GB' }]
    },
    {
      title: 'Speed',
      values: [{ text: 'Standard' }, { text: 'High Speed' }]
    },
    {
      title: 'Video Resolution',
      values: [{ value: '720P' }, { value: '720P, 1080P' }]
    },
    {
      title: 'No Watermark',
      values: [{ support: false }, { support: true }]
    }
  ]
}

const gotoWorkspace = () => {
  if (userStore.roles?.includes('admin')) {
    router.push({ name: 'admin.user-projects' })
    return
  }

  // router.push({ name: 'vmeg.create-video', params: { projId: projStore.projId || '-' } })
  // router.push({ name: 'vmeg.video-translation', params: { projId: projStore.projId || '-' } })
  router.push({ name: getDefaultStartRoute().name })
}
const gotoSignin = () => {
  router.push({ name: 'signin', params: { from: '/pricing' } })
}

const faqs = (() => {
  // @ts-expect-error
  let message = i18nHome.global.messages.value[i18nHome.global.locale.value]
  if (!message) {
    // @ts-expect-error
    message = i18nHome.global.messages.value[i18nHome.global.fallbackLocale.value]
  }
  return message.pricing.faqs || []
})()

const recordReferralErrorCnt = ref(0)
const handleError = () => {
  // 重试一次，不行就直接走原逻辑
  if (recordReferralErrorCnt.value < 1) {
    ElMessage.error('Sorry for the hiccup. Click "Get Started" to retry!')
    recordReferralErrorCnt.value = recordReferralErrorCnt.value + 1
  } else {
    window.open(paymentLinkUrl.value)
  }
}

const handlePay = async () => {
  // 有stripe paymentlink就走海外支付
  if (paymentLinkUrl.value) {
    const referralId = window?.Rewardful?.referral
    // 判断一下是从Rewardful Affiliate Link过来的
    if (referralId) {
      try {
        loadingCheckPay.value = true

        const res = await recordReferral({
          projId: projStore.projId,
          referralId
        })

        if (res.data?.code) {
          handleError()
          return
        }

        window.open(compositePaymentLink(referralId))
      } catch (e) {
        handleError()
      } finally {
        loadingCheckPay.value = false
      }
    } else {
      window.open(paymentLinkUrl.value)
    }

    return
  }

  // 否则走国内微信支付：TODO看看这个逻辑到底怎么区分的，之前的逻辑
  if (goodsInfo.value) {
    showWxQrCode(goodsInfo.value)
  }
}
</script>

<template>
  <PricingSlogan />
  <div class="block block-price-table">
    <div class="content">
      <div class="sw-wrapper" v-if="paymentCategories.length > 1">
        <div class="sw-content">
          <div
            class="sw-item"
            :class="{ active: paymentCategory == category }"
            @click="changePaymentCategory(category)"
            v-for="category in paymentCategories"
            :key="category"
          >
            {{ t(category) }}
          </div>
        </div>
      </div>
      <div class="sw-wrapper" v-if="paymentPeriods.length > 1">
        <div class="sw-content">
          <div
            class="sw-item"
            :class="{ active: period == paymentPeriod }"
            @click="changePaymentPeriod(period)"
            v-for="period in paymentPeriods"
            :key="period"
          >
            {{
              period == '1'
                ? t('Monthly')
                : period == '12'
                  ? t('Annually')
                  : t('{count} Months', { count: period })
            }}
          </div>
        </div>
        <div class="sw-saved-wrapper">
          <div class="sw-saved-arrow"></div>
          <div class="sw-saved-content">
            {{ t('Save {amountSaved}%', { amountSaved: annuallyAmountSaved }) }}
          </div>
        </div>
      </div>
      <div class="price-table">
        <template v-for="(plan, planIndex) in plansTable" :key="planIndex">
          <div class="plan" v-if="planIndex == 0">
            <div class="plan-title">{{ t('Free') }}</div>
            <div class="price-wrapper">
              <div class="price-pay">
                <div class="price">
                  <small>{{ currencySymbol }}</small
                  >0
                </div>
                <div class="period">/{{ t('mo') }}</div>
              </div>
            </div>
            <div class="intro-wrapper">
              <div class="credit-desc">
                <div class="credits">{{ freePlanCredits }} {{ t('credits') }}</div>
                <div class="period">/{{ t('month') }}</div>
              </div>
              <div class="generate-desc">{{ t('Expiring monthly') }}</div>
            </div>
            <el-button size="large" type="primary" round plain @click="gotoWorkspace">
              {{ t('Get started') }}
            </el-button>
            <div class="features">
              <template v-for="(feature, index) of getPlanFeatures(plan, planIndex)" :key="index">
                <div class="feature-item">
                  <el-icon class="supported" v-if="feature.values[planIndex].support !== false">
                    <CircleCheck />
                  </el-icon>
                  <el-icon v-else><CircleCloseFilled /></el-icon>
                  <div class="feature">
                    <div>{{ t(feature.title) }}</div>
                    <div v-if="feature.values[planIndex].text">
                      : {{ t(feature.values[planIndex].text || '') }}
                    </div>
                    <div v-if="feature.values[planIndex].value">
                      : {{ feature.values[planIndex].value }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="sw-wrapper mobile" v-if="planIndex == 1 && paymentCategories.length > 1">
            <div class="sw-content">
              <div
                class="sw-item"
                :class="{ active: paymentCategory == category }"
                @click="changePaymentCategory(category)"
                v-for="category in paymentCategories"
                :key="category"
              >
                {{ t(category) }}
              </div>
            </div>
          </div>
          <div class="sw-wrapper mobile" v-if="planIndex == 1 && paymentPeriods.length > 1">
            <div class="sw-content">
              <div
                class="sw-item"
                :class="{ active: period == paymentPeriod }"
                @click="changePaymentPeriod(period)"
                v-for="period in paymentPeriods"
                :key="period"
              >
                {{
                  period == '1'
                    ? t('Monthly')
                    : period == '12'
                      ? t('Annually')
                      : t('{count} Months', { count: period })
                }}
              </div>
            </div>
            <div class="sw-saved-wrapper">
              <div class="sw-saved-arrow"></div>
              <div class="sw-saved-content">
                {{ t('Save {amountSaved}%', { amountSaved: annuallyAmountSaved }) }}
              </div>
            </div>
          </div>
          <div class="plan" v-if="planIndex == 1" v-loading="!goodsInfo">
            <template v-if="goodsInfo">
              <div class="plan-title">
                {{ t(goodsInfo.name) }}
                <div class="amount-saved" v-if="paymentPeriod == '12'">
                  {{ t('Save {amountSaved}%', { amountSaved: annuallyAmountSaved }) }}
                </div>
                <div class="cut-off-tag" v-if="goodsInfo.packageConfig.cutoffPercent">
                  <IconFlash />
                  {{
                    t('{percent}% off for the first month', {
                      percent: goodsInfo.packageConfig.cutoffPercent
                    })
                  }}
                </div>
              </div>
              <PriceInfo :goods-info="goodsInfo" v-if="!goodsInfo.packageConfig.originPrice" />
              <PriceCutoffInfo :goods-info="goodsInfo" v-else />
              <div class="intro-wrapper">
                <CreditCountInfo :goods-info="goodsInfo" />
                <div class="generate-desc">
                  {{
                    t('Clips to Videos about {count} videos', {
                      count: goodsInfo.packageConfig.videoCount
                    })
                  }}
                </div>
                <div class="generate-desc">
                  {{
                    t('Video Translation about {minute} minutes', {
                      minute: goodsInfo.packageConfig.videoTranslationMinutes
                    })
                  }}
                </div>
                <el-slider
                  v-model="paymentGoodsIndex"
                  :max="paymentList.length - 1"
                  :step="1"
                  :show-tooltip="false"
                  :marks="paymentCreditMarks"
                  @input="changeGoodsInfo"
                  v-if="paymentList.length > 1"
                />
              </div>
              <template v-if="paymentList.length > 1 && paymentList[0].packageName != 'gift'">
                <el-button
                  size="large"
                  type="primary"
                  round
                  v-if="userStore.id"
                  :loading="loadingCheckPay || !baseDataReady || loadingWxQrCode"
                  @click="handlePay"
                >
                  {{ t('Get started') }}
                </el-button>
                <el-button size="large" type="primary" round @click="gotoSignin" v-else>
                  {{ t('Get started') }}
                </el-button>
              </template>
              <div class="features" v-if="goodsInfo.packageConfig.features">
                <template v-for="(feature, index) of goodsInfo.packageConfig.features" :key="index">
                  <div class="feature-item">
                    <el-icon class="supported" v-if="feature.support !== false">
                      <CircleCheck />
                    </el-icon>
                    <el-icon v-else><CircleCloseFilled /></el-icon>
                    <div class="feature">
                      <div>{{ t(feature.title) }}</div>
                      <div v-if="feature.text">: {{ t(feature.text) }}</div>
                      <div v-if="feature.value">: {{ feature.value }}</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="features" v-else>
                <template v-for="(feature, index) of getPlanFeatures(plan, planIndex)" :key="index">
                  <div class="feature-item">
                    <el-icon class="supported" v-if="feature.values[planIndex].support !== false">
                      <CircleCheck />
                    </el-icon>
                    <el-icon v-else><CircleCloseFilled /></el-icon>
                    <div class="feature">
                      <div>{{ t(feature.title) }}</div>
                      <div v-if="feature.values[planIndex].text">
                        : {{ t(feature.values[planIndex].text || '') }}
                      </div>
                      <div v-if="feature.values[planIndex].value">
                        : {{ feature.values[planIndex].value }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="plan" v-if="planIndex == 2">
            <div class="plan-title">{{ t("Let's talk") }}</div>
            <div class="price-wrapper">
              <div class="price-pay">
                <div class="price">{{ t(enterprise) }}</div>
              </div>
            </div>
            <div class="intro-wrapper">
              <div class="credit-desc">
                <div class="credits">{{ t('Requiring higher limit or custom features?') }}</div>
              </div>
              <div class="credit-desc">
                <div class="credits">{{ t("Let's talk!") }}</div>
              </div>
            </div>
            <el-button size="large" type="primary" round plain>
              <a :href="letsTalkUrl" target="_blank">{{ t('Get started') }}</a>
            </el-button>
            <div class="features">
              <template v-for="(feature, index) of getPlanFeatures(plan, planIndex)" :key="index">
                <div class="feature-item">
                  <el-icon class="supported" v-if="feature.values[planIndex].support !== false">
                    <CircleCheck />
                  </el-icon>
                  <el-icon v-else><CircleCloseFilled /></el-icon>
                  <div class="feature">
                    <div>{{ t(feature.title) }}</div>
                    <div v-if="feature.values[planIndex].text">
                      : {{ t(feature.values[planIndex].text || '') }}
                    </div>
                    <div v-if="feature.values[planIndex].value">
                      : {{ feature.values[planIndex].value }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <PriceDetails />
  <FAQ />
  <div class="payment-dialog">
    <el-dialog
      class="qrcode"
      v-model="paymentWxDialogVisible"
      :title="t('Scan code to pay')"
      width="242px"
      draggable
      align-center
      destroy-on-close
    >
      <div class="qrcode-image-box" v-if="!paymentErrorMessage">
        <el-image class="qrcode-image" :src="paymentQrCodeBase64" />
        <div class="qrcode-message">{{ t('Please use WeChat to scan the QR code to pay') }}</div>
      </div>
      <div class="qrcode-image-box" v-if="paymentErrorMessage">
        <el-result
          icon="error"
          :title="t('Scan code payment error')"
          :sub-title="paymentErrorMessage"
        />
      </div>
    </el-dialog>
  </div>
  <PageFooter />
</template>

<style scoped lang="less">
*,
.title,
.main-title {
  font-family:
    SF Pro Display,
    var(--el-font-family);
}

.subtitle {
  font-family:
    SF Pro,
    var(--el-font-family);
}

.main-title {
  padding: 80px 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 66px;
  color: var(--my-color-black-89-90);
}

.block-price-table {
  padding-bottom: 50px;

  .content {
    width: 1120px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sw-wrapper {
      border-radius: 52px;
      background: var(--my-color-white-100);
      position: relative;

      &.mobile {
        display: none;
      }

      + .sw-wrapper {
        margin-top: 10px;
      }

      .sw-content {
        display: flex;
        margin: 2px;

        .sw-item {
          margin: 2px;
          padding: 8px 24px;
          border-radius: 52px;
          user-select: none;

          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          text-align: center;
          color: var(--my-color-black-100);
          text-transform: capitalize;
          cursor: pointer;

          &.active {
            background: var(--el-color-primary);
            color: var(--my-color-white-100);
          }
        }
      }

      .sw-saved-wrapper {
        position: absolute;
        top: 9px;
        right: -100px;
        height: 28px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: var(--my-color-white-100);

        &,
        .sw-saved-arrow {
          background: #ff6b86;
        }

        .sw-saved-arrow {
          position: absolute;
          width: 8px;
          height: 8px;
          left: -4px;
          top: calc((100% - 8px) / 2);
          transform: rotate(45deg);
        }

        .sw-saved-content {
          padding: 2px 12px;
        }
      }
    }

    .price-table {
      margin-top: 70px;
      display: flex;
      flex-wrap: wrap;
      gap: 35px;

      .plan {
        padding: 20px 24px;
        width: 270px;
        height: 460px;
        border-radius: 16px;
        box-shadow: 0 2px 12px 0 var(--my-color-primary-20);
        background: var(--my-color-white-100);
        overflow: hidden;

        :deep(.el-loading-mask) {
          z-index: unset;
        }

        .plan-title {
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
          color: var(--el-color-primary);
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: 'SF Pro Display' !important;

          .amount-saved {
            display: inline-block;
            padding: 0 8px 0 10px;
            font-size: 14px;
            line-height: 24px;
            height: 24px;
            color: #ff6b86;
            background: #ff6b8626;
            border-radius: 50px;
            text-align: center;
          }

          .cut-off-tag {
            font-size: 12px;
            height: 24px;
            margin-left: 8px;
            display: flex;
            align-items: center;
            padding: 0 6px;
            white-space: nowrap;
            background-color: rgba(#ffb321, 0.4);
            border-radius: 50px;
            color: #000;
          }
        }

        .price-wrapper {
          height: 48px;
          margin: 20px 0 16px;
          display: flex;
          align-items: baseline;

          .price-pay {
            display: flex;
            align-items: baseline;
            gap: 4px;

            .price {
              font-size: 44px;
              font-weight: 700;
              line-height: 48px;

              small {
                font-size: 30px;
              }
            }

            .period {
              font-family:
                SF Pro Text,
                var(--el-font-family);
              font-size: 22px;
              font-weight: 500;
              line-height: 22px;
              color: #999999;
              white-space: nowrap;
            }
          }

          .price-origin {
            margin-left: 16px;
            color: rgba(#000, 0.5);
            font-size: 20px;
            text-decoration: line-through;
          }

          .price-each-desc {
            display: flex;

            .price {
              font-family:
                SF Pro Text,
                var(--el-font-family);
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
            }

            .period {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              color: var(--my-color-black-89-90);
              white-space: nowrap;
            }
          }
        }

        .intro-wrapper {
          height: 120px;

          .credit-num {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .price-each-desc {
            display: flex;

            .price {
              font-family:
                SF Pro Text,
                var(--el-font-family);
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
            }

            .period {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              color: var(--my-color-black-89-90);
              white-space: nowrap;
            }
          }

          .credit-desc {
            display: flex;
            align-items: center;
            font-family:
              SF Pro Text,
              var(--el-font-family);

            .credits {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }

            .period {
              font-size: 16px;
              line-height: 24px;
            }

            .tips {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 700;
              //line-height: 20px;
              color: #ff6b86;
            }
          }

          .generate-desc {
            font-size: 14px;
            line-height: 22px;
            color: var(--my-color-black-50);
            margin: 2px 0 0;
          }

          .el-slider {
            width: calc(100% - 20px);
            margin: 0 auto;

            :deep(.el-slider__runway) {
              --el-slider-runway-bg-color: var(--my-color-primary-20);

              .el-slider__marks-stop {
                --el-slider-stop-bg-color: var(--el-color-primary);
              }

              .el-slider__marks {
                .el-slider__marks-text {
                  font-size: 12px;
                  line-height: 14px;
                  color: var(--my-color-black-50);
                }
              }
            }
          }
        }

        .el-button {
          margin-top: 8px;

          width: 100%;
          height: 44px;
          border-radius: 44px;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;

          :deep(> span) {
            display: block;
            width: 100%;
            height: 44px;
            line-height: 44px;
          }

          a {
            display: block;
            color: var(--my-color-white-100);
            text-decoration: none;
          }

          &.is-plain {
            --el-button-hover-text-color: var(--el-button-text-color);
            --el-button-bg-color: transparent;
            --el-button-hover-bg-color: transparent;

            a {
              color: var(--el-button-text-color);
            }
          }
        }

        .features {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 14px;

          .feature-item {
            line-height: 20px;

            display: flex;
            gap: 12px;

            .feature {
              font-size: 14px;
              display: flex;
            }

            .el-icon {
              font-size: 20px; // 18px

              color: var(--my-color-black-20);

              &.supported {
                color: var(--el-color-primary);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-title {
    font-size: 28px;
    line-height: 36px;
    padding: 50px 0 20px;
  }

  .block {
    .content {
      width: calc(100% - var(--el-main-padding) * 2);
      padding-left: var(--el-main-padding);
      padding-right: var(--el-main-padding);
    }
  }

  .block-price-table {
    padding-bottom: 20px;

    .content {
      padding-top: 0;

      .sw-wrapper {
        display: none;

        &.mobile {
          display: block;
        }

        /*&:not(.mobile) {
          margin-top: 30px;

          .sw-saved-wrapper {
            top: -35px;
            right: 16px;
            font-size: 12px;

            .sw-saved-arrow {
              left: calc((100% - 8px) / 2);
              top: unset;
              bottom: -4px;
            }
          }
        }*/
      }

      .price-table {
        margin-top: 30px;

        .plan {
          padding: 20px 30px;
          width: 100%;
          height: unset;

          .plan-title {
            line-height: 24px;
          }

          .intro-wrapper {
            height: unset;
          }

          .el-button {
            margin-top: 20px;
            height: 53px;

            > span {
              height: 53px;
              line-height: 53px;
            }
          }
        }
      }
    }
  }
}

.payment-dialog {
  :deep(.el-dialog) {
    &.qrcode {
      .el-dialog__header {
        .el-dialog__title {
          color: #6cbe5e;
        }
      }

      .qrcode-image-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      .qrcode-image {
        width: 170px;
        height: 170px;
        border: 1px solid var(--my-color-y2);
      }

      .qrcode-message {
        text-align: center;
        color: #409eff;
      }
    }
  }
}
</style>
