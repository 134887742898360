<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ProductItem from '@/views/Dashboard/components/ProductItem.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { DisplayContentType, queryDisplayConfig } from '@/api'
import TitleSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TitleSkeleton.vue'
import ProductListSkeleton from '@/views/Dashboard/pages/DashboardView/modules/ProductListSkeleton.vue'
import { useLoadingThrottle } from '@/views/Dashboard/hooks/useLoadingThrottle'
import LoadErrorRetry from '@/views/Dashboard/components/LoadErrorRetry.vue'
import { useI18n } from 'vue-i18n'

type ProductConfig = {
  imageUrl: string
  link: string
  config: any
}

const products = ref<ProductConfig[]>([])
const loading = ref(false)
const loadError = ref(false)
const { t } = useI18n()

const { isMobile } = useClientInfo()
const { throttleWrapper } = useLoadingThrottle()

const getProducts = async () => {
  loading.value = true
  loadError.value = false

  try {
    await throttleWrapper(async () => {
      const res = await queryDisplayConfig({
        type: DisplayContentType.Product,
        pagination: {
          page: 1,
          size: 10,
          orderBy: 'display_order',
          order: 'desc'
        }
      })

      products.value =
        res.data.data.records?.map((item: ProductConfig) => {
          let c
          try {
            c = JSON.parse(item.config)
          } catch (err) {
            console.error(err, item)
          }

          return {
            ...item,
            config: c
          }
        }) || []
    })
  } catch (err) {
    loadError.value = true
  }

  loading.value = false
}

onMounted(() => {
  getProducts()
})
</script>

<template>
  <div class="products-list-container" :class="{ mobile: isMobile }">
    <template v-if="loading">
      <TitleSkeleton />
      <ProductListSkeleton :length="3" />
    </template>

    <template v-else>
      <div class="header">{{ t('精选') }}</div>
      <div class="product-list">
        <template v-if="!loadError">
          <ProductItem
            v-for="(item, idx) in products.slice(0, 4)"
            :key="idx"
            :link="item.link"
            :title="item.config?.title"
            :image-url="item.imageUrl"
            :description="item.config?.description"
            :introVideoUrl="item.config?.introVideoUrl"
            :type="item.config?.type"
            :tag="item.config?.tag"
          />
        </template>
        <LoadErrorRetry v-else :on-retry="getProducts" />
      </div>
    </template>
  </div>
</template>

<style scoped lang="less">
.products-list-container {
  margin-top: 32px;
  padding: 0 40px;

  .header {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
  }

  .product-list {
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;
  }
}

// 手机样式适配，适用于小调整，大调整建议替换组件，否则后面不太好维护
.mobile.products-list-container {
  margin-top: 12px;
  padding: 0 16px;
}
</style>
