export const useLoadingThrottle = () => {
  // 执行时间小于 throttle 的方法会在1s以后执行完成
  // 主要适用于页面请求返回比较快的情况下页面加载状态和数据切换过快的问题，体验不好
  const throttleWrapper = async (fn: () => Promise<any>, throttle: number = 1000) => {
    let resp
    const startTime = Date.now()

    try {
      resp = await fn()
    } finally {
      const endTime = Date.now()
      const timeout = throttle + startTime - endTime

      await new Promise((resolve) =>
        setTimeout(() => {
          resolve(true)
        }, timeout)
      )
    }
    return resp
  }

  return {
    throttleWrapper
  }
}
