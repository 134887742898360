<script setup lang="ts">
import type { GenerateVideoTask } from '@/api'
import IconStateRunning from '@/components/icons/IconStateRunning.vue'
import IconCircleClose from '@/components/icons/IconCircleClose.vue'
import IconClock from '@/components/icons/IconClock.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useTaskInfo } from '@/views/Dashboard/hooks/useTaskInfo'

// 定义 props 类型
const props = defineProps<{
  item: GenerateVideoTask
}>()
const { t } = useI18n()

const formatDuration = (durationInSeconds: number) => {
  if (!durationInSeconds) {
    return ''
  }

  durationInSeconds = ~~(durationInSeconds / 1000)

  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60

  // 格式化为两位数
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  // 如果小时数为 0，则只返回分钟和秒
  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  } else {
    return `${formattedMinutes}:${formattedSeconds}`
  }
}

// @ts-ignore 原始声明导致的问题，后面优化
const duration = computed(() => props.item?.config?.memo?.video_duration || 0)

const { isErrorState } = useTaskInfo(props.item)
</script>

<template>
  <div class="duration" v-if="duration && !isErrorState">{{ formatDuration(duration) }}</div>
  <div class="state is-pending" v-if="item.state == 'pending'">
    <el-icon>
      <IconClock />
    </el-icon>
    {{ t('等待中') }}
  </div>
  <div class="state is-running" v-if="item.state == 'running'">
    <el-icon>
      <IconStateRunning />
    </el-icon>
    {{ t('运行中') }}
  </div>
  <div class="state is-failed" v-if="['canceled', 'failed'].includes(item.state)">
    <el-icon>
      <IconCircleClose />
    </el-icon>
    {{ item.state == 'canceled' ? t('已取消') : t('已失败') }}
  </div>
</template>

<style scoped lang="less">
.duration {
  background-color: rgba(#000, 0.5);
  color: white;
  position: absolute;
  left: 12px;
  top: 12px;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 4px;
}
.state {
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 2px 8px 2px 4px;
  border-radius: 30px;
  background: var(--my-color-white-89-90);

  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  line-height: 16px;
  color: var(--my-color-black-60);

  &.is-running,
  &.is-pending {
    color: var(--el-color-primary);
  }

  .el-icon {
    font-size: 16px;
  }
}
</style>
