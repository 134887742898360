<script setup lang="ts">
import IconLoadErrorRetry from '@/components/icons/IconLoadErrorRetry.vue'
import { useI18n } from 'vue-i18n'
import IconVideo from '@/components/icons/IconVideo.vue'
import IconEmpty from '@/components/icons/IconEmpty.vue'

defineProps<{
  type?: 'error' | 'empty'
  onRetry?: () => any
  text?: string
  reloadText?: string
  hideRetryButton?: boolean
}>()
const { t } = useI18n()
</script>

<template>
  <div class="load-error-retry-container">
    <IconEmpty v-if="type === 'empty'" />
    <IconLoadErrorRetry v-else />
    <span class="text">{{ text || t('加载失败请重试') }}</span>
    <el-button type="primary" plain @click="onRetry" v-if="!hideRetryButton"
      >{{ reloadText || t('重新加载') }}
    </el-button>
  </div>
</template>

<style scoped lang="less">
.load-error-retry-container {
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0;
  border-radius: 12px;

  .text {
    font-size: 14px;
    color: rgba(#000, 0.5);
    margin: 8px 0 16px;
  }
}
</style>
