import { h } from 'vue'

import { ElMessageBox } from 'element-plus'
import 'element-plus/es/components/message-box/style/css'

import { type Material } from '@/api'
import { config } from '@/config'

import i18n from '@/locales/i18n'
const { t } = i18n.global

export const videoPlay = (material: Material, usePreview: boolean = false) => {
  let { address } = material
  const { previewVideo } = material
  if (usePreview && previewVideo) {
    address = previewVideo
  } else {
    usePreview = false
  }
  const filename = address.split('/').slice(-1).join('')
  const src = `${material.url.split('/').slice(0, -1).join('/')}/${filename}`
  const extname = filename.split('.').slice(-1).join('').toLowerCase()

  ElMessageBox({
    title: `${usePreview ? `[${(previewVideo ? `30${t('time.symbol.second', 's')} ` : '') + t('预览')}] ` : ''}${material.name}`,
    message: h(['mp3', 'wav'].includes(extname) ? 'audio' : 'video', {
      autoplay: '',
      controls: '',
      playsinline: '',
      poster: material.url,
      src,
      style: 'width:100%;min-width:300px'
    }),
    customClass: 'g-message-box-center',
    showConfirmButton: false
  }).catch(() => {})
}

export const videoDownload = (url: string = '', filename: string, isSignedUrl: boolean = false) => {
  if (!url) {
    return
  }
  const a = document.createElement('a')
  const isCloudfront =
    url.includes('.cloudfront.') || url.includes('res.vmeg.pro') || url.includes('cdn.gthree.cn')
  if (isCloudfront || url.includes(config.baseUrl)) {
    const extQuery = encodeURIComponent(
      `; filename*=UTF-8''${isCloudfront ? encodeURIComponent(filename) : filename}`
    )
    a.href = `${url}${url.includes('?') ? '&' : '?'}response-content-disposition=attachment${extQuery}`
  } else {
    a.href = url
  }
  a.download = filename
  if (!isSignedUrl && !url.includes(config.baseUrl)) {
    // download attribute only work for same-origin URLs and the blob: and data: schemes
    a.target = '_blank'
  }
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export interface LanguageOption {
  label: () => string
  value: string
  nativeName: string
}
export const getLanguages = (prodcut: string, withAutoDetect?: boolean): LanguageOption[] => {
  const commonLanguages = [
    {
      label: () => t('英语'),
      value: 'en',
      nativeName: 'English'
    },
    {
      label: () => t('中文'),
      value: 'zh-CN',
      nativeName: '简体中文'
    },
    {
      label: () => t('日语'),
      value: 'ja-JP',
      nativeName: '日本語'
    },
    {
      label: () => t('法语'),
      value: 'fr-FR',
      nativeName: 'Français'
    },
    {
      label: () => t('德语'),
      value: 'de-DE',
      nativeName: 'Deutsch'
    },
    {
      label: () => t('西班牙语'),
      value: 'es-ES',
      nativeName: 'Español'
    },
    {
      label: () => t('印度尼西亚语'),
      value: 'id-ID',
      nativeName: 'Bahasa Indonesia'
    },
    {
      label: () => t('葡萄牙语'),
      value: 'pt-PT',
      nativeName: 'Português'
    },
    {
      label: () => t('意大利语'),
      value: 'it-IT',
      nativeName: 'Italiano'
    },
    {
      label: () => t('土耳其语'),
      value: 'tr-TR',
      nativeName: 'Türkçe'
    },
    {
      label: () => t('韩语'),
      value: 'ko-KR',
      nativeName: '한국인'
    }
  ]
  if (prodcut === 'video-translation') {
    if (withAutoDetect) {
      return [
        {
          label: () => t('自动检测'),
          value: 'auto',
          nativeName: 'Auto Detect'
        }
      ].concat(commonLanguages)
    }
    return commonLanguages.concat([])
  }
  if (prodcut === 'clips-to-videos') {
    return commonLanguages.concat([
      {
        label: () => t('越南语'),
        value: 'vi-VN',
        nativeName: 'Tiếng Việt'
      }
      /*{
        label: () => t('俄语'),
        value: 'ru-RU',
        nativeName: 'Русский'
      },
      {
        label: () => t('阿拉伯语'),
        value: 'ar',
        nativeName: 'عربي'
      },*/
      /*{
      label: () => t('波兰语'),
      value: 'pl-PL',
      nativeName: 'Polski'
    },*/
    ])
  }
  return commonLanguages
}

export const getLanguage = (prodcut: string, value: string): LanguageOption => {
  const languages = getLanguages(prodcut)
  let lang: LanguageOption | null = null
  let language = value
  for (let i = 0; i < languages.length; i++) {
    if (languages[i].value === value) {
      lang = languages[i]
      break
    }
  }
  if (!lang) {
    language = value.split('-')[0]
    for (let i = 0; i < languages.length; i++) {
      if (languages[i].value === value) {
        lang = languages[i]
        break
      }
    }
  }
  return {
    label: lang ? lang.label : () => '',
    value: language,
    nativeName: lang ? lang.nativeName : ''
  }
}
