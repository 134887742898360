<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.78955 5.3196C1.78955 3.82279 3.00296 2.60938 4.49978 2.60938H13.4998C14.9966 2.60938 16.21 3.82279 16.21 5.3196V12.6832C16.21 14.1801 14.9966 15.3935 13.4998 15.3935H4.49978C3.00296 15.3935 1.78955 14.1801 1.78955 12.6832V5.3196Z"
      fill="#7B4DFF"
      stroke="#7B4DFF"
      stroke-width="1.125"
    />
    <path
      d="M11.415 8.15531C12.0581 8.53533 12.0581 9.46583 11.415 9.84585L7.7813 11.993C7.12681 12.3798 6.3 11.908 6.3 11.1478L6.3 6.8534C6.3 6.09318 7.12681 5.62138 7.7813 6.00812L11.415 8.15531Z"
      stroke="white"
      stroke-width="1.14545"
    />
  </svg>
</template>
