import { getSignedInfo } from '@/api'
import { updateUserStore, useUserStore } from '@/stores/user'
import { fetchSubscriptionPlanAndCreditDetails, updateProjData, useProjStore } from '@/stores/proj'
import router from '@/router'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useSignCheck = () => {
  const loading = ref(false)
  const route = useRoute()
  const userStore = useUserStore()
  const projStore = useProjStore()

  const checkSign = async () => {
    try {
      loading.value = true
      const res = await getSignedInfo()
      if (!res.data.data?.user) {
        return
      }

      const { email, phone } = res.data.data.user
      updateUserStore(userStore, res.data.data)
      updateProjData(projStore, res.data.data.projectInfo)

      if (!userStore.roles?.includes('admin')) {
        fetchSubscriptionPlanAndCreditDetails(projStore)
        setTimeout(() => {
          fetchSubscriptionPlanAndCreditDetails(projStore)
        }, 5000) // 付费后后台可能延后生效。此处延时再次查询一下
      }

      if (!email && !phone) {
        router.push({ name: 'user', params: { from: 'email' } })
        return
      }
      if (route.params.from && route.params.from !== '-') {
        router.push({ path: String(route.params.from) })
        return
      }
    } finally {
      loading.value = false
    }
  }

  return { loading, checkSign }
}
