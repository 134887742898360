<script setup lang="ts">
import { User } from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { userSignOut } from '@/api'
import { useProjStore } from '@/stores/proj'
import router from '@/router'

const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()
const { t } = useI18n()

const showMenu = ref(false)

const handleProfile = () => {
  router.push({ name: 'user', params: { from: '-' } })
}

const handleLogout = () => {
  userSignOut().finally(() => {
    userStore.$reset()
    projStore.$reset()
  })
}
</script>

<template>
  <el-dropdown
    class="avatar-container"
    @mouseenter="showMenu = true"
    @mouseleave="showMenu = false"
    popper-class="avatar-sub-menu"
    placement="bottom-end"
  >
    <el-avatar :src="userStore.avatar" :size="32" v-if="userStore.id" />
    <el-avatar :src="User" :size="32" v-else />
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="handleProfile">
          <div>
            <el-text tag="div">{{ userStore.name }}</el-text>
            <el-text size="small" tag="div" type="info">{{ userStore.email }}</el-text>
          </div>
        </el-dropdown-item>
        <el-dropdown-item divided @click="handleLogout">{{ t('退出') }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style lang="less">
.avatar-container {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .dropdown-menu {
    position: absolute;
    top: 100%; /* 在头像下方显示 */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* 确保菜单在其他元素上方 */
  }

  .el-avatar:focus {
    outline: none !important;
  }
}

.avatar-sub-menu {
  --el-dropdown-menuItem-hover-fill: #f3f3f3;
  .base-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    .email {
      color: #999;
    }
  }

  /* 隐藏箭头 */

  .el-popper__arrow {
    display: none;
  }
}
</style>
