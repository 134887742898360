<script setup lang="ts">
import { type Component, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import router, { routeNameMap } from '@/router'
import HomeIcon from '@/components/icons/IconHome.vue'
import ProductsIcon from '@/components/icons/IconAllProducts.vue'
import TasksIcon from '@/components/icons/IconMyTasks.vue'
import IconArrowSingle from '@/components/icons/IconArrowSingle.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import IconSubscription from '@/components/icons/IconSubscription.vue'
import IconCredit from '@/components/icons/IconCredit.vue'
import { useI18n } from 'vue-i18n'

const {
  dashboardHome,
  createVideo,
  videoTranslation,
  dashboardTasks,
  subscriptionPlan,
  creditList
} = routeNameMap

const { t } = useI18n()

type MenuItem = {
  title: string
  path?: string
  icon?: Component
  name: string
  submenu?: MenuItem[]
}

const menuData = ref<MenuItem[]>([
  { title: t('控制台'), path: '/home', icon: HomeIcon, name: dashboardHome.name },
  {
    title: t('全部产品'),
    name: 'products',
    icon: ProductsIcon,
    submenu: [
      { title: t('切片到视频'), path: '/create-video/-', name: createVideo.name },
      { title: t('视频翻译'), path: '/video-translation/-', name: videoTranslation.name }
    ]
  },
  { title: t('我的任务'), path: '/home/my-tasks', icon: TasksIcon, name: dashboardTasks.name },
  {
    title: t('订阅计划'),
    icon: IconSubscription,
    path: '/subscription-plan/-',
    name: subscriptionPlan.name
  },
  {
    title: t('积分详情'),
    path: '/credit-details/-',
    name: creditList.name,
    icon: IconCredit
  }
])

const route = useRoute()
const { isMobile } = useClientInfo()

const expandedMenu = ref<string>('')

const handleMenuItemClick = (item: MenuItem) => {
  // item.submenu ? toggleSubMenu(index) : null
  if (item.path) {
    router.push({ path: item.path })
    return
  }

  if (expandedMenu.value === item.name) {
    expandedMenu.value = ''
  } else {
    expandedMenu.value = item.name
  }
}

const subMenuIsOpen = (item: MenuItem) => {
  return expandedMenu.value === item.name
}

const subMenuIsActive = (submenu?: MenuItem[], name?: string) => {
  return name && submenu?.map((sm) => sm.name).includes(name)
}

const initSubMenuExpand = () => {
  const m = menuData.value?.find((m: MenuItem) => {
    if (subMenuIsActive(m.submenu, route.name as string)) {
      return m
    }
  })
  if (m) {
    expandedMenu.value = m.name
  }
}

const menuIsActive = (item: MenuItem) => {
  return item.name === route.name || subMenuIsActive(item.submenu, route.name as string)
}

onMounted(() => {
  initSubMenuExpand()

  watch(
    () => route.name,
    () => {
      initSubMenuExpand()
    }
  )
})
</script>

<template>
  <div class="sidebar" :class="{ mobile: isMobile }">
    <ul class="menu">
      <li
        v-for="(item, index) in menuData"
        :key="index"
        class="menu-item"
        :class="{ 'has-submenu': item.submenu }"
      >
        <div
          class="menu-link"
          @click="handleMenuItemClick(item)"
          :class="{ active: menuIsActive(item) }"
        >
          <component :is="item.icon" />
          <span class="title">{{ item.title }}</span>
          <IconArrowSingle
            v-if="item.submenu"
            class="icon-arrow"
            :class="{ 'is-open': subMenuIsOpen(item) }"
          />
        </div>
        <ul
          v-if="item.submenu"
          class="submenu"
          :class="{ 'is-open': subMenuIsOpen(item) }"
          :style="{ height: subMenuIsOpen(item) ? item.submenu.length * 48 + 'px' : '' }"
        >
          <li
            v-for="(subitem, subindex) in item.submenu"
            :key="subindex"
            class="submenu-item"
            :class="{ active: subitem.name === route.name }"
            @click="router.push({ path: subitem.path })"
          >
            {{ subitem.title }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="less">
.sidebar {
  width: 100%;
  height: 100vh;

  .menu {
    list-style: none;
    padding: 0;
  }

  .menu-item {
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;

    .menu-link {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #888;
      gap: 8px;
      height: 50px;
      padding: 0 16px;
      transition: all 0.3s;

      .title {
        flex: 1;
        white-space: nowrap;
      }

      .icon-arrow {
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: transform 0.2s;
        transform: rotate(180deg);

        &.is-open {
          transform: rotate(0);
        }
      }

      &.active,
      &:hover {
        background: rgba(#7b4dff, 0.1);
        border-radius: 50px;
        color: #7b4dff;
      }
    }
  }

  .submenu {
    list-style: none;
    padding: 0;
    transition: height 0.2s;
    overflow: hidden;
    height: 0;
  }

  .has-submenu .submenu {
    display: block;
  }

  .submenu-item {
    box-sizing: border-box;
    height: 48px;
    color: #888;
    font-size: 16px;
    padding-left: 48px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.active {
      color: #7b4dff;
    }
  }
}

.mobile.sidebar {
  height: auto;
  .menu {
    margin: 0;
    padding-top: env(safe-area-inset-top);

    .menu-item {
      border-bottom: 1px solid #eee;
      margin: 4px 0;

      .menu-link {
        background: #fff;

        &.active,
        &:hover {
          background: #fff;
        }
      }

      .submenu-item {
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
