<script setup lang="ts">
import { Picture } from '@element-plus/icons-vue'
import IconVideo from '@/components/icons/IconVideo.vue'
import type { GenerateVideoTask } from '@/api'
import { computed, ref } from 'vue'
import { useTaskInfo } from '@/views/Dashboard/hooks/useTaskInfo'
import GloablLoadingAnimation from '@/views/Dashboard/components/GloablLoadingAnimation.vue'
import LoadingV2 from '@/views/Dashboard/components/LoadingV2.vue'
import TaskItemSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TaskItemSkeleton.vue'

// 定义 props 类型
const props = defineProps<{
  item: GenerateVideoTask
}>()

const url = computed(() => props.item.thumbnailUrls[0] || '')

const { isErrorState } = useTaskInfo(props.item)
</script>

<template>
  <div
    class="task-thumbnail-img"
    :class="{
      'is-canceled': item.state == 'canceled',
      'is-failed': item.state == 'failed',
      'is-finished': item.state == 'finished'
    }"
  >
    <el-image class="thumbnail" fit="cover" :src="url" v-if="url && !isErrorState">
      <template #placeholder>
        <el-skeleton animated style="width: 100%; height: 100%">
          <template #template>
            <el-skeleton-item variant="image" style="width: 100%; height: 100%" />
          </template>
        </el-skeleton>
      </template>
      <template #error>
        <el-icon><Picture /></el-icon>
      </template>
    </el-image>
    <el-image class="thumbnail" v-else>
      <template #error>
        <el-icon><IconVideo /></el-icon>
      </template>
    </el-image>
  </div>
</template>

<style scoped lang="less">
.task-thumbnail-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .thumbnail {
    width: 100%;
    height: 100%;
    background: var(--my-color-x9);

    font-size: 32px;
    color: var(--my-color-primary-12);

    display: flex;
    align-items: center;
    justify-content: center;

    &.is-canceled,
    &.is-failed,
    &.is-finished {
      //--my-thumbnail-size: 128px;
      //--my-thumbnail-gap: 0px;
    }

    .loading-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-canceled,
  &.is-failed {
    .thumbnail {
      color: var(--my-color-black-10);
      background: var(--my-color-x8);
    }
  }
}
</style>
