export default {
  首页: 'Home',
  异常信息: 'Error messages',
  个人资料: 'Profile',

  产品: 'Products',
  博客: 'Blog',
  切片到视频: 'Clips to Videos',
  长视频转短视频: 'Long Video to Shorts',
  产品URL到视频: 'Product URL to Videos',
  图片转视频: 'Images to Videos',
  脚本到视频: 'Script to Videos',
  头像: 'Avatar',
  频道: 'Channels',
  //功能: 'Features',
  //解决方案: 'Solutions',
  定价: 'Pricing',
  支持: 'Support',
  开始吧: 'Get started',
  敬请期待: 'Comming Soon',

  创建视频: 'Create Video',
  我的任务: 'My Tasks',
  我的订阅: 'My Subscription',
  订阅计划: 'Subscription Plan',
  当前订阅: 'Current subscription',
  积分值: 'credits',
  积分详情: 'Credit Details',
  计划: 'Plan',
  类型: 'Type',
  状态: 'Status',
  购买日期: 'Purchased Date',
  续订日期: 'Renewal Date',
  续订: 'Renewal',
  到期: 'Expire',
  过期日期: 'Expiration Date',
  操作: 'Operation',
  名称: 'Name',
  时间: 'Time',
  相关描述: 'Related Description',
  变更积分: 'Changed Credit',
  可用积分: 'Available Credit',
  冻结积分: 'Freeze Credit',
  '您确定要取消订阅？': 'Are you sure you want to cancel your subscription?',
  取消订阅成功: 'Unsubscription successful',
  取消订阅失败: 'Unsubscription failed',
  取消订阅异常: 'Unsubscribe error',
  已取消订阅: 'Ongoing',
  已过期: 'Expired',
  升级: 'Upgrade',
  升级计划: 'Upgrade Plan',
  解锁更多功能和积分: 'UNLOCK MORE FEATURES ＆ CREDITS',
  '您是免费用户，不用担心！升级您的计划以解锁更多功能和优势。':
    "You're a free user, No worries! Upgrade your plan to unlock more features and benefits.",
  确认提交: 'Confirm Submission',
  '此操作将消耗您的积分。请在继续之前确认以下详细信息。':
    'This action will consume your credits. Please confirm the details below before proceeding.',
  将剪辑提交到视频任务: 'Submit Clips to Video Task',
  下载生成的视频: 'Download the generated video',
  消费积分: 'Consume Credits',
  剩余积分: 'Remaining Credits',
  确认: 'Confirm',
  刷新: 'Refresh',

  填写信息: 'Input Info',
  '没有素材？试试这些：': 'No media? Try the following:',
  使用这些素材: 'Use these materials',
  上传素材: 'Upload Media',
  处理中: 'Processing',
  上传失败: 'Upload failed',
  上传异常: 'Upload error',
  '必须是{format}格式': 'Need {format} format',
  '文件体积不能大于{size}': 'File size must be not large than {size}',
  我的素材库: ' My Library',
  来自本地磁盘: 'From the Device',
  来自我的素材库: 'From My Library',
  '最多上传{max}个文件': 'The maximum number of upload files is {max}',
  '拖放或选择最多{max}个文件': 'Drag and drop up to {max} files here',
  拖放或选择一个文件: 'Drag and drop one file here',
  'MP4、MOV、WEBM、WEBP、JPG、JPEG、PNG': 'MP4, MOV, WEBM, WEBP, JPG, JPEG, PNG',
  '上传最多{max}个图片': 'Upload up to {max} images',
  'WEBP、JPG、JPEG、PNG': 'WEBP, JPG, JPEG, PNG',
  已选用了重复的素材: 'A duplicate file has been selected',
  视频: 'Videos',
  图像: 'Images',
  音频: 'Audios',
  声音: 'Voices',
  预览: 'Preview',
  选择: 'Select',

  添加素材: 'Add Media',
  产品名称: 'Product Name',
  手机壳: 'Phone Case',
  产品特性: 'Product Features',
  '时尚、耐用、防水': 'Stylish, Durability, WaterProofing',
  自动生成: 'Auto Generate',
  更多详情: 'More Details',
  隐藏详情: 'Hide Details',
  销售价格: 'Price',
  推广信息: 'Promotion',
  品牌: 'Brand',
  品牌名称: 'Brand Name',
  品牌图标: 'Brand Logo',
  视频时长: 'Duration',
  视频分辨率: 'Resolution',
  视频比例: 'Ratio',
  视频语言: 'Language',
  任意: 'Any',
  背景音乐: 'Background Music',
  自动添加: 'Auto Add',
  '如果没有选择声音，输出视频将不会被配音':
    'The output video will not be dubbed if no voice is chosen',
  '如果未选择模板，输出视频将不会显示字幕':
    'The output video will not show subtitles if no template is chosen',
  生成: 'Generate',
  此选项必填: 'This option is required',
  '{object}必填': '{object} is required',
  '{object}不能超过{max}个字符': '{object} cannot exceed {max} characters',
  '{object}小数后不能超过{precision}位':
    'The {object} after the decimal point cannot exceed {precision} digits',
  '上传的视频片段长度不足以生成符合要求的完整视频，请添加更多视频片段':
    'The total duration of the clips is too short to generate a proper marketing video. Please add more clips',
  '视频或图片高、宽不能小于{size}。当前分辨率：{resolution}':
    'The height or width of the video&image cannot be less than {size}. Current resolution {resolution}',
  '视频或图片高、宽不能大于{size}。当前分辨率：{resolution}':
    'The height or width of the video&image cannot be larger than {size}. Current resolution {resolution}',
  '视频高、宽不能小于{size}。当前分辨率：{resolution}':
    'The height or width of the video cannot be less than {size}. Current resolution {resolution}',
  '视频高、宽不能大于{size}。当前分辨率：{resolution}':
    'The height or width of the video cannot be larger than {size}. Current resolution {resolution}',
  任务提交成功: 'Task submission succeeded',
  任务提交失败: 'Task submission failed',
  任务提交异常: 'Task submission error',
  任务详情: 'Task Details',
  '{count}个视频': '{count} Videos',
  重新生成: 'Regenerate',
  全部: 'All',
  全部状态: 'All status',
  等待中: 'Pending',
  运行中: 'Running',
  已完成: 'Finished',
  已取消: 'Cancelled',
  已失败: 'Failed',
  产品素材: 'Product Media',
  产品信息: 'Product Info',
  更多信息: 'More Info',
  视频属性: 'Video Attribution',
  生成视频: 'Generate Videos',
  视频生成中: 'Generating',
  '您的{count}个产品营销视频正在生成中。':
    '{count} marketing videos for your product are being generated.',
  '您的产品营销视频已成功生成{count}条。':
    'The number of successfully generated videos is {count}.',
  '您的视频生成任务已取消，您可以点击下方按钮重新生成。':
    'The task has been cancelled. You can restart it anytime.',
  '抱歉，视频生成任务失败，您可以点击下方按钮重新生成。':
    'Sorry, your video generation task failed. You may try to regenerate it.',
  '抱歉，您的视频任务没有成功。请别担心，我们会尽快解决这个问题。如果您有任何疑问，可以扫描右下角的微信群二维码，加入我们的社区与我们联系。您消耗的积分会及时退还给您。':
    'Sorry, your video task failed. We\'re on it! If you have questions, click "Need Help" for support. Points used will be refunded. Thank you!',
  取消失败: 'Cancel failed',
  取消异常: 'Cancel error',
  取消成功: 'Cancel succeeded',
  重新生成失败: 'Regeneration failed',
  重新生成异常: 'Regeneration error',
  重新生成成功: 'Regeneration succeeded',

  样式: 'Style',
  动画: 'Animation',

  英语: 'English',
  中文: 'Chinese',
  日语: 'Japanese',
  法语: 'French',
  德语: 'German',
  西班牙语: 'Spanish',
  印度尼西亚语: 'Indonesian',
  葡萄牙语: 'Portuguese',
  越南语: 'Vietnamese',
  意大利语: 'Italian',
  俄语: 'Russian',
  阿拉伯语: 'Arabic',
  土耳其语: 'Turkish',
  波兰语: 'Polish',
  韩语: 'Korean',

  印地语: 'Hindi',
  乌克兰语: 'Ukrainian',
  瑞典语: 'Swedish',
  荷兰语: 'Dutch',

  自动检测: 'Auto Detect',

  上传一个视频: 'Upload a video',
  从视频中抽取音频: 'Extract sound from video',
  上传视频: 'Upload Video',
  翻译信息: 'Translation Info',
  脚本预览: 'Script Preview',
  渲染视频: 'Render Video',
  'MP4、MOV、WEBM': 'MP4, MOV, WEBM',
  '视频时长需要在{min}-{max}秒之间': 'The video duration needs to be between {min}-{max} seconds',
  媒体: 'Media',
  目标语言: 'Translate Into',
  原始语言: 'Original Language',
  请检查原始语言选择是否正确: 'Please check if the original language is selected correctly',
  好的: 'OK',
  '视频的原始语言与目标翻译语言相同。请编辑并重新提交。':
    'The Original Language of video is the same as the target translation language. Please edit and resubmit.',
  '抱歉，原始视频语言为{originLanguage}，目前不支持该语言。请更换视频并重新上传。':
    'Sorry, the original video language is {originLanguage}, and it is not currently supported. Please change the video and upload it again.',
  点击此处: 'Click here',
  '查看支持的语言列表。': 'to view the list of supported languages.',
  '很抱歉出现问题！您可以再试一次': "We're sorry for the hiccups! You can just try it again",
  请选择目标语言: 'Please select the target language',
  '抱歉，我们无法确认原始视频的语言。请手动选择原始视频的语言。':
    "Sorry, we couldn't confirm the language of the original video. Please manually select the language of the original video.",
  //自动侦测: 'Auto Detect',
  性别: 'Gender',
  年龄: 'Age',
  语言: 'Language',
  发言人数: 'Number of Speakers',
  一个发言人: '1 Speaker',
  '{number}个发言人': '{number} Speakers',
  请确认视频中是否存在音频: 'Please confirm if there is audio in the video',
  '为了保证克隆效果，上传的音频必须至少10秒。':
    'To ensure the cloning effect, the uploaded audio must be at least 10 seconds long.',
  下一步: 'Next',
  声音转写和翻译: 'Transcribing & Translating',
  获取视频脚本失败: 'Get video script failed',
  获取视频脚本异常: 'Get video script error',
  翻译视频脚本失败: 'Translate video script failed',
  翻译视频脚本异常: 'Translate video script error',
  原始脚本: 'Original Script',
  译后脚本: 'Translated Script',
  '原始脚本和译后脚本异常，请稍后再试':
    'The original script and the translated script are abnormal, please try again later.',
  重新翻译全部脚本: 'Retranslate the entire script',
  重新翻译单条脚本: 'Retranslate single script',
  /*'确定要重新翻译全部脚本吗？': 'Are you sure you want to re-translate the entire script?',
  '如果之前已经修改过原始脚本和译后脚本，将被自动识别和翻译结果覆盖':
    'If the original script and translated script have been modified before, they will be overwritten by automatic recognition and translation results.',*/
  '原始脚本和译后脚本有缺失部分，请补充':
    'There are missing parts in the Original Script and Translated Script, please complete them',
  '原始脚本有缺失部分，请补充':
    'There are missing parts in the Original Script, please complete them',
  '译后脚本有缺失部分，请补充':
    'There are missing parts in the Translated Script, please complete them',
  语速不合适: 'The speaking speed is inappropriate',
  当前: 'Current',
  最佳: 'Optimal',
  字: 'Words',
  选择AI声音: 'Choose AI Voice',
  翻译音频: 'Translate Voice',
  AI声音: 'AI Voice',
  '{object}必选': '{object} is required',
  筛选: 'Filter',
  声音克隆: 'Clone Voice',
  从原视频文件: 'From the Original Video',
  使用原始视频克隆声音: 'Use original video to clone the voice',
  从上传的音频文件: 'From the Uploaded Audio File',
  '上传音频文件（MP3、WAV），{size}以内并且10秒以上':
    'Upload audio file (MP3, WAV) under {size} and at least 10 seconds',
  从原视频文件中克隆: 'Clone from the Original Video',
  从上传音频文件中克隆: 'Clone from the Uploaded Audio File',
  'MP3、WAV': 'MP3, WAV',
  上传音频: 'Upload Audio',
  添加字幕: 'Add Subtitles',
  翻译字幕: 'Translate Subtitles',
  选择字幕模板: 'Choose Subtitle Template',
  //横屏: 'Horizontal screen',
  //竖屏: 'Vertical screen',
  让视频生动: 'Bring Video Life',
  唇型同步: 'With Lip Sync',
  增强人脸视频: 'Enhance Face Video',
  高清: 'HD',
  渲染: 'Render',
  视频预览: 'Video Preview',
  视频预览不会执行声音克隆和唇型同步:
    'Voice clone and lip-sync will NOT be performed for video preview',
  加载中: 'Loading',
  没有更多: 'No More Data',
  加载更多: 'Load More',
  视频加载中: 'Video loading',
  字体加载中: 'Font loading',
  AI声音生成中: 'AI Voice Generating',
  AI声音生成失败: 'AI Voice generation failed',
  AI声音生成异常: 'AI Voice generation error',
  字幕生成中: 'Subtitle Generating',
  字幕生成失败: 'Subtitle generation failed',
  字幕生成异常: 'Subtitle generation error',
  提交视频翻译任务: 'Submit Video Translation Task',
  原始视频: 'Original Video',
  过程: 'Process',
  基础视频翻译: 'Basic Video Translation',
  消耗: 'Consume',
  '1积分': '1 credit',
  '1分钟': '1 minute',
  分钟: 'minutes',
  编辑: 'Edit',
  重新翻译: 'Retranslate',
  下载失败: 'Download failed',
  下载异常: 'Download error',
  下载: 'Download',

  视频翻译提示: 'Tips for Video Translation',
  视频要求: 'Video Requirements',
  视频时长范围: 'Video Duration Range',
  视频输入的最大分辨率限制为: 'The maximum resolution for video input is limited to',
  视频仅支持单人配音: 'Videos support only single-person voiceovers',
  '避免在视频中遮挡面部，尽量减小和缓慢头部动作，确保面部大小适当，以保证唇形同步效果':
    'Avoid covering faces in the video, minimize and slow down head movements, and ensure faces are of appropriate size for effective lip-syncing',
  '确保视频中没有嘈杂的背景或背景音乐，以避免影响声音克隆':
    'Ensure there is no noisy background or background music in the video, as it may affect voice cloning',
  '保持头部动作最少且缓慢，以保持口型同步的效果':
    'Keep head movements minimal and slow to maintain lip-syncing effectiveness',
  确保视频中的脸部不太大或太小以保持口型同步的效果:
    'Ensure that faces in the video are not too large or too small to maintain lip-syncing effectiveness',
  语言支持: 'Language Support',
  输入: 'Input',
  输出: 'Output',
  '知道了！': 'Got it!',

  头像上传失败: 'Avatar upload failed',
  头像上传异常: 'Avatar upload error',
  '头像图片必须是{format}格式': 'Avatar image need {format} format',
  '头像图片文件体积不能大于{size}': 'Avatar image file size must not large than {size}',

  昵称: 'Nickname',
  请输入昵称: 'Please enter nickname',

  邮箱: 'Email',
  请输入邮箱: 'Please enter email',
  请先输入正确的邮箱和图形验证码: 'Please enter correct email and captcha code first',

  手机号码: 'Phone',
  请输入手机号码: 'Please enter phone number',
  图形验证码: 'Captcha',
  请输入图形验证码: 'Please enter captcha',
  请先输入正确的手机号码和图形验证码: 'Please enter correct phone number and captcha code first',

  验证码: 'Verification code',
  请输入验证码: 'Please enter verification code',
  获取验证码: 'Get verification code',
  获取验证码失败: 'Getting verification code failed',
  图形验证码不正确: 'captcha code error',
  获取验证码异常: 'Getting verification code error',
  验证码已发送: 'Verification code has been sent',

  语言环境: 'Locale',
  浏览器默认: 'Browser default',

  保存: 'Save',
  已保存: 'Saved',
  保存失败: 'Save failed',
  保存异常: 'Save error',

  注册: 'Sign up',
  登录: 'Sign in',
  退出: 'Sign out',
  取消: 'Cancel',
  注册失败: 'Sign up failed',
  注册异常: 'Sign up error',
  登录失败: 'Sign in failed',
  登录异常: 'Sign in error',

  邮箱登录: 'Sign in with email',
  微信扫码登录: 'Sign in with WeChat',
  请使用微信扫码授权登录: 'Please use WeChat scan QrCode to sign in',
  服务条款: 'Terms of Service',
  隐私政策: 'Privacy Policy',
  绑定邮箱: 'Binding Email',

  '无限AI视频，释放营销想象': 'Market Anything with Infinite AI-crafted Videos',
  '使用{object}登录': 'Sign in with {object}',
  '欢迎使用{object}': 'Welcome to {object}',
  '登录即表示您接受{terms_of_service}和{privacy_policy}。':
    'By logging in, you are indicating your acceptance of the {terms_of_service} and {privacy_policy}.',

  加载异常: 'Loading error',
  加载失败: 'Loading failed',
  提交失败: 'Submit failed',
  提交: 'Submit',

  登录状态查询失败: 'Login status query failed',
  '登录过期，需重新登录': 'Your login has expired, please login in again',

  '请求失败，请稍后再试': 'Request failed, please try again later',
  '未登录或登录已过期，需先登录': 'Not logged in or your login has expired, please login in first',

  'generate-video': 'Generate Video',
  'regenerate-video': 'Regenerate Video',
  'video-translation': 'Video Translation',
  'retry-video-translation': 'Retry Video Translation',
  'translate-script': 'Retranslate the entire script',
  'translate-text': 'Translate one script',
  'download-video': 'Download Video',

  // incomplete: '',
  // incomplete_expired: '',
  // trialing: '',
  active: 'Active Subscription',
  // past_due: '',
  // canceled: '',
  // unpaid: '',
  // paused: '',

  new_subscription: 'Subscription',
  recurring_charge: 'Renewal',
  consume: 'Consume',
  frozen: 'Freeze',
  unfrozen: 'Unfrozen',
  auto_unfrozen: 'Unfrozen',
  commit: 'Consume',
  cancel_subscription: 'Cancel Subscription',
  update_subscription: 'Update Subscription',

  Free: 'Free',
  Standard: 'Standard',
  Enterprise: 'Enterprise',
  month: 'month',
  year: 'year',
  Coupon: 'Coupon',

  // ------------- feedback form start ---------------
  '我们真的想知道您是谁？': 'We Care About Who You Are at VMEG',
  '我们重视您的意见！您的反馈对改善VMEG工具至关重要。感谢您帮助我们提升产品的使用体验！✨':
    'We value your opinion! Your feedback is essential for improving VMEG. Thank you for helping us enhance your experience!✨',
  '您的角色是什么？': 'What is your role?',
  企业主: 'Business owner',
  '市场/广告专业人士': 'Marketing/advertising professional',
  内容创作者: 'Content Creator',
  其他: 'Other',
  '您所在的行业是什么？': 'What industry are you in?',
  零售: 'Retail',
  电子商务: 'eCommerce',
  金融: 'Finance',
  技术: 'Technology',
  教育: 'Education',
  房地产: 'Real Estate',
  '您是如何首次了解到 VMEG 的？': 'How did you first learn about VMEG?',
  谷歌: 'Google',
  YouTube: 'Youtube',
  领英: 'Linkedin',
  推特: 'Twitter',
  '脸书，Instagram': 'Facebook, Instagram',
  '论坛，博客，新闻稿': 'Forums, Blogs, Newsletters',
  '希望通过 VMEG 解决哪些问题？（可多选）':
    'What problems do you hope to solve with VMEG? (multiple choices allowed)',
  简化视频编辑过程: 'Simplifying the video editing process',
  降低视频制作成本: 'Reducing video production costs',
  解决语言翻译问题: 'Solving language translation issues',
  增强创造力和灵感: 'Enhancing creativity and inspiration',
  提高视频营销效果: 'Improving video marketing effectiveness',
  '您对我们的哪个产品最感兴趣？': 'Which of our products are you most interested in?',
  短视频转长视频: 'Clips to Videos',
  视频翻译: 'Video Translation',
  两者都感兴趣: 'Both are of interest',
  此时不感兴趣: 'Not interested at this time',
  填写问卷: 'Short Survey',
  填写我们的: 'Fill out our',
  简短调查问卷: 'short survey',
  '不会花超过几分钟的时间。  ': 'it won’t take more than a few minutes of your time.',
  '完成后立即赚取{num}积分。': 'Earn {num} Credits instantly upon completion.',
  '解锁付费的功能，提升你的使用体验。': 'Unlock paid features and enhance your experience with us.',
  '现在你还有一个替代方案，可以解锁更多功能并赚取额外积分':
    'You now have an alternative option to unlock more features and earn additional credits',
  '你已成功提交问卷，获得了5个积分，并解锁了付费功能作为奖励。尽情享受吧！':
    "You've successfully submitted the form and earned 5 credits and unlocked paid features as a reward. Enjoy!",
  立即使用: "Let's Create!",
  '恭喜您!': 'CONGRATULATIONS',
  // ------------- feedback form end ---------------

  // dashboard
  控制台: 'Home',
  全部产品: 'All Products',
  加载失败请重试: 'Loading Failed Please Reload',
  '登录校验失败，请重试': 'Login verification failed, please try again',
  重新加载: 'Reload',
  精选: 'Featured',
  最近任务: 'Recent Tasks',
  更多: 'More',
  暂无数据: 'No Data',

  time: {
    symbol: {
      second: 's'
    }
  }
}
