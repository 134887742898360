<script setup lang="ts">
import { ArrowRight } from '@element-plus/icons-vue'
import router from '@/router'
import TaskList from '@/views/Dashboard/components/TaskList.vue'
import { onBeforeUnmount, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { getGenerateTaskList, type VideoTranslateTask } from '@/api'
import { ElMessage } from 'element-plus'
import { useProjStore } from '@/stores/proj'
import TitleSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TitleSkeleton.vue'
import TaskListSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TaskListSkeleton.vue'
import { useI18n } from 'vue-i18n'
import LoadErrorRetry from '@/views/Dashboard/components/LoadErrorRetry.vue'
import { useTaskList } from '@/views/Dashboard/hooks/useTaskList'

const projStore = useProjStore()
const { t } = useI18n()
const { isMobile } = useClientInfo()
const queries = reactive({
  projId: projStore.projId,
  currentPage: 1,
  pageSize: 8,
  orderBy: 'create_time',
  order: 'desc'
})

const { tasks, fetchData, loadError, isLoading } = useTaskList()

const handleFetchData = () => {
  fetchData(queries)
}

onMounted(() => {
  handleFetchData()
})
</script>

<template>
  <div class="tasks-list-container" :class="{ mobile: isMobile }">
    <template v-if="isLoading">
      <TitleSkeleton with-right-action />
      <TaskListSkeleton :length="8" />
    </template>
    <template v-else>
      <div class="header">
        {{ t('最近任务') }}
        <span @click="router.push({ name: 'tasks' })" class="more"
          >{{ t('更多') }} <ArrowRight style="width: 12px; height: 12px; margin-left: 8px"
        /></span>
      </div>
      <template v-if="!loadError">
        <TaskList :tasks="tasks" />
      </template>
      <LoadErrorRetry v-else :on-retry="handleFetchData" />
    </template>
  </div>
</template>

<style scoped lang="less">
.tasks-list-container {
  margin-top: 32px;
  padding: 0 40px;

  .header {
    font-weight: 500;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    .more {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      color: #888;
      display: flex;
      align-items: center;
      transform: scale(0.8);
      transform-origin: center;
    }
  }
}

.mobile.tasks-list-container {
  padding: 0 16px;
  margin-top: 92px;
}
</style>
