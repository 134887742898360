<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { Loading } from '@element-plus/icons-vue'

import IconDiamond from '@/components/icons/IconDiamond.vue'

import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useProjStore, isValidPlan, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { computed } from 'vue'

const { t } = useI18n()
const userStore = useUserStore()
const projStore = useProjStore()
const { isMobile } = useClientInfo()

const menuTrigger = computed<string>(() => (isMobile ? 'click' : 'hover'))

defineProps<{
  isInMenu?: boolean
}>()

const fetchPlans = () => {
  if (projStore.isLoading || !projStore.projId || projStore.projId === '-') {
    return
  }
  fetchSubscriptionPlanAndCreditDetails(projStore)
}
</script>

<template>
  <div class="plan-container" :class="{ mobile: isMobile }">
    <div
      class="plan-info-wrapper upgrade-plan"
      :class="{ 'is-in-menu': isInMenu }"
      v-if="userStore.id"
    >
      <div class="plan-info-content" @click="router.push({ name: 'pricing' })">
        {{ t('升级计划') }}
      </div>
    </div>
    <div class="plan-info-wrapper" :class="{ 'is-in-menu': isInMenu }" v-if="userStore.id">
      <div class="plan-info-content">
        <el-tooltip :content="t('刷新')" effect="light" v-if="!projStore.isLoading">
          <el-icon class="btn-refresh" @click="fetchPlans">
            <IconDiamond
              :id="`id_linear_${Math.random()}`"
              :xhighlight="projStore.maxLevel > 0"
              :highlight="true"
            />
          </el-icon>
        </el-tooltip>
        <el-icon class="is-loading" v-else><Loading /></el-icon>
        <el-menu
          mode="horizontal"
          :ellipsis="false"
          :menu-trigger="menuTrigger"
          close-on-click-outside
        >
          <el-sub-menu index="credits">
            <template #title>
              <div class="credits" :class="{ highlight: projStore.total_remaining > 0 }">
                {{ projStore.total_remaining }}
                <span v-if="userStore.roles?.includes('admin')">用户积分</span>
                <span v-else>{{ t('积分值') }}</span>
              </div>
            </template>
            <el-menu-item>
              <div class="plan-title">{{ t('当前订阅') }}</div>
            </el-menu-item>
            <template v-for="(plan, index) in projStore.items" :key="index">
              <el-menu-item v-if="isValidPlan(plan, projStore)">
                <div class="plan-info">
                  {{ t(plan.category) }}: {{ plan.quota }} {{ t('积分值') }}/{{ t(plan.interval) }}
                </div>
              </el-menu-item>
            </template>
            <el-menu-item>
              <div class="plan-info-wrapper my-subscription">
                <div
                  class="plan-info-content"
                  @click="
                    router.push({
                      name: 'vmeg.subscription-plan',
                      params: { projId: projStore.projId || '-' }
                    })
                  "
                >
                  {{ t('我的订阅') }}
                </div>
              </div>
            </el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.plan-info-wrapper {
  --linear-gradient: linear-gradient(251.55deg, #ff763c -18.91%, #ff3c82 16.21%, #7b4dff 61.82%);
}
.plan-container {
  display: flex;
  gap: 12px;
  width: 350px;
  justify-content: end;
  margin-right: 20px;
}

.el-menu {
  --el-menu-horizontal-height: initial;
  border-bottom: 0;

  .el-menu-item {
    min-width: 200px;
    font-family:
      SF Pro Display,
      var(--el-font-family);

    cursor: default;
    padding: 0;
    --el-menu-horizontal-sub-item-height: 40px;
    //border-radius: 0 !important;

    &:not(.is-disabled):hover {
      --el-menu-hover-bg-color: var(--el-menu-bg-color);
      --el-menu-hover-text-color: var(--el-menu-text-color);
    }

    &:not(:first-child) {
      margin: 12px 12px 0 !important;
    }

    &:nth-child(2) {
      margin: 6px 12px 0 !important;
    }

    &:last-child {
      margin: 24px 12px 12px !important;
    }

    &:first-child {
      margin: 0 12px;
    }

    .plan-title {
      font-size: 16px;
      font-weight: 500;
    }

    .plan-info {
      width: 100%;
      background: var(--my-color-x12);
      padding: 10px 12px;
      border-radius: 6px;
      line-height: 20px;
    }

    .el-button {
      width: 100%;
      border: 0;
      color: var(--my-color-white-100);
      background: var(--linear-gradient);
    }
  }
}

.plan-info-wrapper {
  height: 28px;
  border-radius: 30px;
  background: var(--linear-gradient);
  padding: 1px;
  box-sizing: border-box;
  overflow: hidden;

  &.is-in-menu {
    //margin: 11px 0 4px;
    height: 30px;
    padding-top: 0.1px;

    .plan-info-content {
      .el-menu.el-menu--horizontal {
        height: 22px;

        .el-sub-menu {
          :deep(.el-sub-menu__title) {
            height: 100%;
          }
        }
      }
    }
  }

  .plan-info-content {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    gap: 4px;
    padding: 0px 14px;
    border-radius: 30px;
    background: var(--my-color-white-100);
    color: var(--my-color-black-60);

    .el-icon {
      margin-right: 0;

      &.btn-refresh {
        cursor: pointer;
      }
    }

    .el-sub-menu {
      :deep(.el-sub-menu__title) {
        padding: 0;
        border-bottom: 0;
        cursor: default;

        .el-sub-menu__icon-arrow {
          display: none;
        }
      }

      .credits {
        outline: none;

        font-family:
          SF Pro Text,
          var(--el-font-family);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;

        &.highlight {
          background: var(--linear-gradient);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
      }
    }
  }
}

.upgrade-plan {
  font-size: 12px;
  cursor: pointer;

  .plan-info-content {
    color: white;
    background: rgba(0, 0, 0, 0);
  }
}

.my-subscription {
  height: 40px;
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;

  .plan-info-content {
    color: white;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
  }
}

.mobile.plan-container {
  width: 100%;
  padding: 16px 0;
  gap: 24px;

  .plan-info-wrapper {
    height: 36px;
    width: 100%;

    .plan-info-content {
      justify-content: center;
      font-size: 14px;
    }
  }
}
</style>
