<script setup lang="ts">
import FeedbackModal from '@/components/feedback/FeedbackModal/FeedbackModal.vue'
import { ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import router from '@/router'
import i18n from '@/locales/i18n'

const props = defineProps<{ displayFeedbackModal?: boolean; from: string }>()
const emit = defineEmits(['closeFeedbackModal']) // 定义事件
const t = i18n.global.t

const showSurvey = ref(props?.displayFeedbackModal || false)

const handleShowSurvey = () => {
  showSurvey.value = true
}

const handleUpgrade = () => {
  ElMessageBox.close()
  router.push({ name: 'pricing' })
}

const handleClose = (type: 'close' | 'done') => {
  emit('closeFeedbackModal', type)
}
</script>

<template>
  <div class="feedback-guide-info">
    <p>{{ t('现在你还有一个替代方案，可以解锁更多功能并赚取额外积分') }} 🌟</p>
    <ul>
      <li>
        {{ t('填写我们的') }}
        <span @click="handleShowSurvey" class="highlight">{{ t('简短调查问卷') }}</span> –
        {{ t('不会花超过几分钟的时间。  ') }}
      </li>
      <li>{{ t('完成后立即赚取{num}积分。', { num: 5 }) }}</li>
      <li>{{ t('解锁付费的功能，提升你的使用体验。') }}</li>
    </ul>
    <div class="feedback-button-group">
      <el-button type="primary" plain @click="handleShowSurvey">{{ t('填写问卷') }}</el-button>
      <el-button type="primary" @click="handleUpgrade">{{ t('升级') }}</el-button>
    </div>
  </div>
  <FeedbackModal v-model:dialogVisible="showSurvey" @close="handleClose" :from="from" />
</template>

<style scoped lang="less">
.feedback-guide-info {
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-top: 20px;

  .highlight {
    color: #7b4dff;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    font-weight: 600;
    font-size: 14px;

    color: rgba(0, 0, 0, 0.6);
  }

  ul {
    list-style-type: decimal; /* 使用数字 */
    padding-left: 16px;
    color: #777;

    li {
      margin-bottom: 4px;
    }
  }

  .feedback-button-group {
    display: flex;
    justify-content: end;
    margin-top: 28px;
  }
}
</style>
