<script setup lang="ts"></script>

<template>
  <svg width="75" height="44" viewBox="0 0 75 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_11846_13450)">
      <path
        d="M16.0469 0H75V26.7969H26.7656C20.8458 26.7969 16.0469 21.9979 16.0469 16.0781V0Z"
        fill="url(#paint0_linear_11846_13450)"
        shape-rendering="crispEdges"
      />
      <path
        d="M16.0469 0H75V26.7969H26.7656C20.8458 26.7969 16.0469 21.9979 16.0469 16.0781V0Z"
        fill="url(#paint1_linear_11846_13450)"
        shape-rendering="crispEdges"
      />
      <path
        d="M16.0469 -1.00488H15.042V0V16.0781C15.042 22.5529 20.2908 27.8018 26.7656 27.8018H75H76.0049V26.7969V0V-1.00488H75H16.0469Z"
        stroke="white"
        stroke-opacity="0.6"
        stroke-width="2.00977"
        shape-rendering="crispEdges"
      />
      <path
        d="M28.0193 8.19991H29.7879L35.6564 16.7374H35.7207V8.19991H37.4733V19.6797H35.769L29.8361 11.0457H29.7718V19.6797H28.0193V8.19991ZM39.7325 8.19991H47.9806V9.69517H41.485V13.0394H47.5947V14.5347H41.485V18.1844H48.2539V19.6797H39.7325V8.19991ZM48.9875 8.19991H50.9651L53.1999 17.1393H53.2642L55.6116 8.19991H57.4446L59.792 17.1393H59.8563L62.0911 8.19991H64.0687L60.7566 19.6797H58.8916L56.5603 10.8206H56.4959L54.1646 19.6797H52.2835L48.9875 8.19991Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_11846_13450"
        x="0.638672"
        y="-8.70703"
        width="84.4102"
        height="52.25"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.67969" dy="4.01953" />
        <feGaussianBlur stdDeviation="5.35938" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11846_13450" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11846_13450"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_11846_13450"
        x1="16.0469"
        y1="-6.69919"
        x2="74.4853"
        y2="-7.57428"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.108979" stop-color="#FF3C82" />
        <stop offset="1" stop-color="#FF9436" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11846_13450"
        x1="16.0469"
        y1="-6.69919"
        x2="74.4853"
        y2="-7.57428"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.108979" stop-color="#FF3C82" />
        <stop offset="1" stop-color="#7B4DFF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<style scoped lang="less"></style>
