<script setup lang="ts">
import IconLoadingGlobal from '@/assets/gloabal-loading.png'
import IconLogoSingle from '@/components/icons/IconLogoSingle.vue'
</script>

<template>
  <div class="icon-container">
    <img :src="IconLoadingGlobal" alt="" />
    <IconLogoSingle />
  </div>
</template>

<style scoped lang="less">
.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  margin-bottom: 12px;

  img,
  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  img {
    width: 66px;
    height: 66px;
    animation: loading-rotate 1s linear infinite;
    transform-origin: center;
  }
}
</style>
