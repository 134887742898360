<script setup lang="ts">
import { User } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/user'
import IconArrowSingle from '@/components/icons/IconArrowSingle.vue'
import CreaditInfoV2 from '@/components/CreditInfoV2/CreaditInfoV2.vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'

defineProps<{ onSignout: () => void; onCancel: () => void }>()
const userStore = useUserStore()
const { t } = useI18n()

const handleProfileClick = () => {
  router.push({ name: 'user', params: { from: '-' } })
}
</script>

<template>
  <div class="user-drawer-container">
    <div class="user-drawer-info">
      <div class="base-info" @click="handleProfileClick">
        <div class="avatar">
          <el-avatar :src="userStore.avatar" :size="32" v-if="userStore.id" />
          <el-avatar :src="User" :size="32" v-else />
        </div>
        <div class="text">
          <el-text tag="div">{{ userStore.name }}</el-text>
          <el-text size="small" tag="div" type="info">{{ userStore.email }}</el-text>
        </div>
        <IconArrowSingle class="arrow-right" />
      </div>
      <CreaditInfoV2 />
      <div class="split-line"></div>
      <div class="drawer-button" @click="onSignout">{{ t('退出') }}</div>
    </div>
    <div class="split-line-dark"></div>
    <div class="drawer-button center" @click="onCancel">{{ t('取消') }}</div>
  </div>
</template>

<style scoped lang="less">
.user-drawer-container {
  padding-bottom: env(safe-area-inset-bottom);

  .user-drawer-info {
    padding: 16px;
    padding-bottom: 0;

    .base-info {
      display: flex;
      align-items: center;

      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      .text {
        flex: 1;
      }

      .arrow-right {
        transform: rotate(90deg);
      }
    }

    .split-line {
      width: 100%;
      height: 1px;
      background-color: #f7f7f7;
    }
  }

  .drawer-button {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    color: var(--my-color-black-90);
    font-size: 14px;
  }

  .center {
    justify-content: center;
  }

  .split-line-dark {
    width: 100%;
    height: 8px;
    background-color: #f3f3f3;
  }
}
</style>
<style lang="less"></style>
