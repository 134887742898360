<script setup lang="ts">
import ProductItemSkeleton from '@/views/Dashboard/pages/DashboardView/modules/ProductItemSkeleton.vue'

defineProps<{ length: number }>()
</script>

<template>
  <div class="product-list-skeleton-container">
    <ProductItemSkeleton v-for="i in new Array(length || 1)" :key="i" />
  </div>
</template>

<style scoped lang="less">
.product-list-skeleton-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
}
</style>
