<script lang="ts" setup>
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { onMounted, reactive, ref } from 'vue'
import {
  type DisplayConfigItem,
  DisplayConfigLinkType,
  DisplayContentType,
  queryDisplayConfig
} from '@/api'
import LoadingAnimation from '@/views/Dashboard/components/LoadingAnimation.vue'
import SwiperSkeleton from '@/views/Dashboard/pages/DashboardView/modules/SwiperSkeleton.vue'
import LoadErrorRetry from '@/views/Dashboard/components/LoadErrorRetry.vue'
import { useLoadingThrottle } from '@/views/Dashboard/hooks/useLoadingThrottle'
import router from '@/router'

// 因为pc设计和h5的设计banner尺寸比例差距很大，所以搞成两张图
interface BannerConfig {
  pcThumbnail: string
  mobileThumbnail: string
}

type Banner = {
  id: number
  imageUrl: string
  link: string
  linkType: DisplayConfigLinkType
  config: BannerConfig // string ,need parse
}

const { isMobile } = useClientInfo()
const { throttleWrapper } = useLoadingThrottle()
const loading = ref(false)
const loadError = ref(false)
const banners = ref<Banner[]>([])

// 做图片加载记录，主要是为了避免大图加载慢，好做一些加载体验优化
const loadedMap = reactive<Record<string, boolean>>({})

const getBanners = async () => {
  loading.value = true
  loadError.value = false

  try {
    await throttleWrapper(async () => {
      const res = await queryDisplayConfig({
        type: DisplayContentType.Banner,
        pagination: {
          page: 1,
          size: 10,
          orderBy: 'display_order',
          order: 'desc'
        }
      })

      banners.value =
        res.data.data.records?.map((i: DisplayConfigItem) => {
          let config: BannerConfig = {
            mobileThumbnail: '',
            pcThumbnail: ''
          }

          try {
            config = JSON.parse(i.config) as BannerConfig
          } catch (e) {
            console.error('banner config load error')
          }

          return {
            ...i,
            config: config
          }
        }) || ([] as Banner[])
    })
  } catch (e) {
    loadError.value = true
  } finally {
    loading.value = false
  }
}

const onImageLoad = (imageUrl: string) => {
  loadedMap[imageUrl] = true
}

const getBannerImage = (banner: Banner) => {
  return isMobile.value ? banner.config?.mobileThumbnail : banner.config?.pcThumbnail
}

const handleItemClick = (banner: Banner) => {
  if (!banner.link) return

  if (banner.linkType === DisplayConfigLinkType.External) {
    window.open(banner.link, '__blank')
  } else {
    router.push({ path: banner.link })
  }
}

onMounted(() => {
  getBanners()
})
</script>
<template>
  <div class="swiper-container" :class="{ mobile: isMobile }">
    <SwiperSkeleton v-if="loading" />
    <LoadErrorRetry :on-retry="getBanners" v-else-if="loadError" />
    <el-carousel height="220px" trigger="click" arrow="always" v-else>
      <!--   banner直接加在大图可能会耗时较长导致长期白底，所以先用image去load，然后加载完了再展示   -->
      <el-carousel-item v-for="item in banners" :key="item.id" @click="handleItemClick(item)">
        <div
          class="banner-bg"
          :style="{
            backgroundImage: loadedMap[getBannerImage(item)]
              ? 'url(' + getBannerImage(item) + ')'
              : ''
          }"
        >
          <el-image
            v-if="!loadedMap[getBannerImage(item)]"
            :src="getBannerImage(item)"
            fit="cover"
            @load="onImageLoad(getBannerImage(item))"
            class="banner-img"
          >
            <template #placeholder>
              <div class="loading-placeholder">
                <LoadingAnimation height="220px" />
              </div>
            </template>
            <template #error>
              <div class="error-placeholder">Banner Load Error</div>
            </template>
          </el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<style scoped>
.swiper-container {
  margin: 0 40px;
  border-radius: 16px;
  overflow: hidden;
}

.swiper-container.mobile {
  margin: 0;
  border-radius: 0;
}
</style>

<style>
.swiper-container {
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #7b4dff;
    background: #fff;
  }

  .el-carousel__indicator.is-active button {
    background: #7b4dff;
  }

  .banner-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .banner-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
