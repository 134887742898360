<script setup lang="ts">
import { type Component, defineProps, ref } from 'vue'
import { type GenerateVideoTask, getGeneratedVideoList } from '@/api'
import { useI18n } from 'vue-i18n'
import TaskThumbnail from '@/views/Dashboard/components/TaskThumbnail.vue'
import TaskStatusTag from '@/views/Dashboard/components/TaskStatusTag.vue'
import IconVT from '@/components/icons/IconVT.vue'
import IconClips2Video from '@/components/icons/IconClips2Video.vue'
import TaskOpMenu from '@/views/Dashboard/components/TaskOpMenu.vue'
import { useTaskInfo } from '@/views/Dashboard/hooks/useTaskInfo'
import router from '@/router'
import { useProjStore } from '@/stores/proj'
import VideoPreview from '@/views/Dashboard/components/VideoPreview.vue'
import VideoPlayMask from '@/views/Dashboard/components/VideoPlayMask.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { ElMessage } from 'element-plus'
import { formatUserTime } from '@/utils/time'

// 定义 props 类型
const props = defineProps<{
  item: GenerateVideoTask
}>()
const { t } = useI18n()
const projStore = useProjStore()

const { isFinished, isClips2Video, isVT } = useTaskInfo(props.item)
const showPreview = ref(false)
const videoPreviewUrl = ref('')
const videoLoading = ref(false)
const { isMobile } = useClientInfo()

const infoMap: Record<string, { icon: Component; name: string }> = {
  'clips-to-videos': {
    icon: IconClips2Video,
    name: t('切片到视频')
  },
  'video-translation': {
    icon: IconVT,
    name: t('视频翻译')
  }
}

const handleClick = async () => {
  if (isClips2Video.value) {
    router.push({
      name: 'vmeg.my-tasks.details',
      params: { projId: projStore.projId, taskId: props.item.id }
    })
  }

  // 现在vt的任务和结果视频没有区分开，所以在一个页面上实现的，后面会抽离到详情页，这部分逻辑就可以清理了
  if (isVT.value && isFinished.value) {
    if (!videoPreviewUrl.value) {
      videoLoading.value = true
      try {
        const res = await getGeneratedVideoList({
          projId: projStore.projId,
          taskId: props.item.id,
          currentPage: 1,
          pageSize: 1
        })

        if (res?.data?.code) {
          ElMessage.error(res.data.message || t('加载异常'))
          return
        }
        if (!res.data?.data?.records?.[0]) {
          ElMessage.error(`${t('加载异常')}.`)
          return
        }
        const list = res.data?.data?.records

        videoPreviewUrl.value = list[0].addressUrl
        showPreview.value = true
      } finally {
        videoLoading.value = false
      }
    } else {
      showPreview.value = true
    }
  }
}
</script>

<template>
  <div class="task-item" :class="{ mobile: isMobile }">
    <div class="task-thumbnail-container" @click="handleClick">
      <TaskThumbnail :item="item" />
      <TaskStatusTag :item="item" />
      <VideoPlayMask :loading="videoLoading" v-if="isVT && isFinished" />
    </div>
    <div class="task-info">
      <div class="base-info">
        <div class="type-info">
          <component :is="infoMap[item.product]?.icon" />
          <span class="product-name small-text">{{ infoMap[item.product]?.name }}</span>
        </div>
        <span class="small-text">
          {{ formatUserTime(item.createTime) }}
        </span>
      </div>
      <div class="op-info">
        <el-text class="op-title">{{ item.title }}</el-text>
        <span class="op-menu">
          <TaskOpMenu :item="item" />
        </span>
      </div>
    </div>
  </div>
  <VideoPreview
    v-if="showPreview"
    :title="item.title"
    :show="showPreview"
    :video-url="videoPreviewUrl"
    :poster-url="item.thumbnailUrls[0]"
    @close="showPreview = false"
    :autoplay="true"
    enable-download
    :styles="{
      width: '80%',
      aspectRatio: '1'
    }"
  />
</template>

<style lang="less">
.task-item {
  width: 256px;
  //width: 24%;
  border-radius: 12px;
  overflow: hidden;

  .task-thumbnail-container {
    position: relative;
    width: 100%;
    aspect-ratio: calc(256 / 206);
    overflow: hidden;
    border-radius: 12px;
    cursor: pointer;
  }

  .task-info {
    height: 50px;
    color: rgba(#000, 0.6);

    .base-info {
      display: flex;
      align-items: center;
      margin: 13px 0 5px;

      .type-info {
        display: flex;
        align-items: center;
        flex: 1;
      }

      .product-name {
        flex: 1;
        margin-left: 4px;
      }
    }

    .small-text {
      font-size: 12px;
    }

    .op-info {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .op-title {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .op-menu {
        display: flex;
        margin-left: 12px;
      }
    }
  }
}

.mobile.task-item {
  width: 47.5%;
  margin-bottom: 16px;

  .task-info {
    height: 64px;
  }
  .base-info {
    flex-direction: column;
    align-items: start;

    .el-text {
      align-self: start;
    }
  }
}
</style>
