<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.79004 5.3196C1.79004 3.82279 3.00345 2.60938 4.50027 2.60938H13.5003C14.9971 2.60938 16.2105 3.82278 16.2105 5.3196V12.6832C16.2105 14.1801 14.9971 15.3935 13.5003 15.3935H4.50026C3.00345 15.3935 1.79004 14.1801 1.79004 12.6832V5.3196Z"
      fill="#7B4DFF"
      stroke="#7B4DFF"
      stroke-width="1.125"
    />
    <path
      d="M4.84546 5.77073H10.3839M7.61469 4.84766V5.77073M8.09847 10.1553C7.39353 9.4251 6.82118 8.56604 6.42017 7.61689M9.23007 11.7707H12.4608M8.53777 13.1553L10.8455 8.53996L13.1532 13.1553M9.34597 5.77073C8.8992 8.4339 7.18532 10.6674 4.84546 11.8303"
      stroke="white"
      stroke-width="1.12846"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
