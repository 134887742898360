<script setup lang="ts">
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import { ArrowDown } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export interface FilterValue {
  product?: string
  status?: string
}

const props = defineProps<{ value: FilterValue }>()
const { t } = useI18n()

const emit = defineEmits<{
  (event: 'change', value: FilterValue): void // 定义事件名和参```数类型
}>()
const { isMobile } = useClientInfo()

const option = {
  products: [
    {
      value: '',
      label: t('全部')
    },
    {
      value: 'clips-to-videos',
      label: t('切片到视频')
    },
    {
      value: 'video-translation',
      label: t('视频翻译')
    }
  ],
  statuses: [
    {
      value: '',
      label: t('全部状态')
    },
    { value: 'pending', label: t('等待中') },
    {
      value: 'running',
      label: t('运行中')
    },
    {
      value: 'finished',
      label: t('已完成')
    },
    {
      value: 'failed',
      label: t('已失败')
    },
    {
      value: 'cancelled',
      label: t('已取消')
    }
  ]
}

const categorySelectorTitle = computed(() => {
  return (
    option.statuses?.find((status) => status.value === props.value.status)?.label ||
    option.statuses[0].label
  )
})

type OptionValueType = {
  [key in keyof FilterValue]: FilterValue[key]
}
const handleChange = (optionValue: OptionValueType) => {
  emit('change', {
    ...props.value,
    ...optionValue
  })
}
</script>

<template>
  <div class="tasks-view-filter" :class="{ mobile: isMobile }">
    <div class="category-filter">
      <span
        v-for="(i, idx) in option.products"
        @click="handleChange({ product: i.value })"
        :key="idx"
        class="selection-item"
        :class="{
          'is-active': i.value === (value?.product || '')
        }"
        >{{ i.label }}</span
      >
    </div>
    <div class="extra-filter">
      <el-dropdown>
        <span class="el-dropdown-link">
          {{ categorySelectorTitle }} <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="task-state-list-dropdown">
            <el-dropdown-item
              v-for="i in option.statuses"
              :key="i.label"
              @click="handleChange({ status: i.value })"
              :class="i.value === value?.status ? 'is-active' : ''"
              >{{ i.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped lang="less">
.tasks-view-filter {
  margin: 10px 0 20px;
  color: #2c2c2c;

  .category-filter {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 10px;

    .selection-item {
      transition: color 0.3s;
      color: #999;
      margin-right: 32px;
      font-weight: 500;
      user-select: none;
      cursor: pointer;

      &:hover {
        color: rgba(#7b4dff, 0.4);
      }
    }

    .is-active {
      color: #7b4dff;

      &:hover {
        color: #7b4dff;
      }
    }
  }

  .extra-filter {
    margin-top: 12px;

    .el-dropdown-link {
      user-select: none;
      outline: none;
      color: #888;
      display: flex;

      .el-icon {
        transform: rotate(0deg);
        transition: transform 0.3s;
      }
    }
  }
}

.mobile.tasks-view-filter {
  .selection-item {
    font-size: 12px;
    margin-right: 18px;
  }

  .extra-filter {
    .el-dropdown-link {
      font-size: 12px;
    }
  }
}
</style>

<style lang="less">
.task-state-list-dropdown {
  // override elements style
  --el-dropdown-menuItem-hover-fill: #f3f3f3;

  .is-active.el-dropdown-menu__item {
    background-color: var(--el-dropdown-menuItem-hover-fill);
  }
}
</style>
