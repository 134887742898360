import { useLoadingThrottle } from '@/views/Dashboard/hooks/useLoadingThrottle'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import { EventBusType } from '@/constrants/vars'
import {
  type GenerateVideoTask,
  getGenerateTaskList,
  type GetTaskListReq,
  type VideoTranslateTask
} from '@/api'
import { ElMessage } from 'element-plus'
import { useProjStore } from '@/stores/proj'
import { useI18n } from 'vue-i18n'

export const useTaskList = () => {
  const isLoading = ref(false)
  const loadError = ref(false)
  const isLoadingMore = ref(false)
  const tasks = ref<Array<VideoTranslateTask | GenerateVideoTask>>([])
  const statusListenerTimer = ref(0)
  const total = ref(0)
  const { throttleWrapper } = useLoadingThrottle()
  const componentAlive = ref(true)
  const regenerateBus = useEventBus<string>(EventBusType.regenerateTask)
  const projStore = useProjStore()
  const { t } = useI18n()
  const stopStatusListener = async () => {
    componentAlive.value = false // 防止refresh请求hung住的时候销毁组件导致没有正常停止定时器的问题
    clearTimeout(statusListenerTimer.value)
  }

  const hasMore = computed(() => {
    if (total.value === undefined) return true

    return total.value > tasks.value.length
  })

  const startStatusListener = async () => {
    console.log('enter status listener', componentAlive.value)
    if (!componentAlive.value) return

    clearTimeout(statusListenerTimer.value)
    const needPollingIds = tasks.value
      ?.filter((task) => ['pending', 'running'].includes(task.state))
      .map((task) => task.id)
    if (needPollingIds.length) {
      try {
        const res = await getGenerateTaskList({
          projId: projStore.projId,
          ids: needPollingIds?.join(','),
          pageSize: needPollingIds.length,
          currentPage: 1
        })
        tasks.value = tasks.value?.map((task) => {
          const newTask = res.data?.data?.records?.find((r) => r.id === task.id)
          const newState = newTask?.state

          if (newState !== task.state) {
            console.log('dd task matched, new state: ', newState)
            return {
              ...task,
              ...newTask
            }
          }

          return task
        })
      } finally {
        console.log('set next timer 5000')
        statusListenerTimer.value = window.setTimeout(() => {
          console.log('run next timer 5000')
          startStatusListener()
        }, 5000)
      }
      return
    }
    statusListenerTimer.value = window.setTimeout(() => {
      console.log('run next timer 5000')
      startStatusListener()
    }, 5000)
  }

  const fetchData = async (queries: GetTaskListReq) => {
    isLoading.value = true
    loadError.value = false

    try {
      await throttleWrapper(async () => {
        const res = await getGenerateTaskList({
          ...queries,
          projId: projStore.projId
        })

        tasks.value = (res.data.data.records || []) as VideoTranslateTask[]
        total.value = res.data.data.total
      })
    } catch (err: any) {
      loadError.value = true
    }

    isLoading.value = false
  }

  const loadMore = async (queries: GetTaskListReq) => {
    if (isLoadingMore.value || !hasMore.value) {
      return
    }
    isLoadingMore.value = true

    try {
      const res = await throttleWrapper(async () => {
        return await getGenerateTaskList({
          ...queries
        })
      }, 1000)

      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }

      tasks.value = tasks.value.concat(res.data.data.records || []) as VideoTranslateTask[]
      total.value = res.data.data.total
    } catch (err: any) {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    }

    isLoadingMore.value = false
  }

  // let unsubscribe: () => void
  onMounted(() => {
    // fetchData()
    // unsubscribe = regenerateBus.on(fetchData)
    setTimeout(() => {
      startStatusListener()
    }, 10000)
  })
  onUnmounted(() => {
    // unsubscribe?.()
    stopStatusListener()
  })

  return { tasks, fetchData, loadMore, isLoading, loadError, isLoadingMore, total, hasMore }
}
