<script setup lang="ts">
import type { GenerateVideoTask } from '@/api'
import TaskItem from '@/views/Dashboard/components/TaskItem.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import LoadErrorRetry from '@/views/Dashboard/components/LoadErrorRetry.vue'
import { useI18n } from 'vue-i18n'

defineProps<{ tasks: Array<GenerateVideoTask> }>()
const { isMobile } = useClientInfo()
const { t } = useI18n()
</script>

<template>
  <div class="task-list" :class="{ mobile: isMobile }">
    <LoadErrorRetry v-if="!tasks?.length" hide-retry-button :text="t('暂无数据')" type="empty" />
    <TaskItem v-for="item in tasks" :item="item" :key="item.id + item.state" v-else />
  </div>
</template>

<style lang="less" scoped>
.task-list {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-wrap: wrap;

  .list-empty {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.mobile {
    justify-content: space-between;
    gap: 0;
  }
}
</style>
