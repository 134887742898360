<script setup lang="ts">
import TaskItemSkeleton from '@/views/Dashboard/pages/DashboardView/modules/TaskItemSkeleton.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'

defineProps<{ length: number }>()
const { isMobile } = useClientInfo()
</script>

<template>
  <div class="task-list-skeleton-container" :class="{ mobile: isMobile }">
    <TaskItemSkeleton v-for="i in new Array(length || 1)" :key="i" />
  </div>
</template>

<style scoped lang="less">
.task-list-skeleton-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
}

.task-list-skeleton-container.mobile {
  justify-content: space-between;
  gap: 0;
}
</style>
