import { ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ElMessage, type MessageBoxState } from 'element-plus'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import {
  type GenerateVideoTask,
  type GeneratedVideo,
  getGenerateTaskList,
  getGeneratedVideoList,
  genVideoDownloadUrl,
  type VideoTranslateTask,
  type VideoTranslationCostDetail,
  retryVideoTranslateTask
} from '@/api'
import { videoDownload } from '@/common'
import { costQuotasMap } from '@/config'
import { useUserStore } from '@/stores/user'
import { useProjStore, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { showVTSubmitConfirm } from '@/utils/creditsCostConfirm'

export const useVideoTranslationOpts = (item: VideoTranslateTask) => {
  const { t } = useI18n()
  const userStore = useUserStore()
  const projStore = useProjStore()

  const submitId = ref('')
  const redoTask = async () => {
    if (submitId.value) {
      return
    }

    const resolution = item.config.memo.video_resolution

    const seconds = item.config.memo.video_duration / 1000
    const minutes = Math.ceil(seconds / 60)
    const basic = costQuotasMap['video-translation-basic']
    const costDetail: VideoTranslationCostDetail = {
      basic: basic * minutes
    }
    let costQuotas = basic
    if (item.config.voice?.clone?.audio_files.length) {
      const voiceClone = costQuotasMap['video-translation-voice-clone']
      costQuotas += voiceClone
      costDetail.voiceClone = voiceClone * minutes
    }
    if (item.config.lipsync?.enable) {
      const lipSync = costQuotasMap['video-translation-lip-sync']
      costQuotas += lipSync
      costDetail.lipSync = lipSync * minutes
    }
    if (item.config.hd?.enable) {
      const hd = costQuotasMap['video-translation-hd']
      costQuotas += hd
      costDetail.hd = hd * minutes
    }
    costQuotas *= minutes

    if (costQuotas <= 0) {
      doCreateAction(costQuotas, costDetail)
      return
    }

    if (projStore.total_remaining < costQuotas) {
      submitId.value = item.id
      await fetchSubscriptionPlanAndCreditDetails(projStore)
      submitId.value = ''
    }

    showVTSubmitConfirm(
      {
        title: t('提交视频翻译任务'),
        costQuotas,
        totalRemaining: projStore.total_remaining,
        seconds,
        minutes,
        resolution,
        costDetail,
        feedbackTypes: userStore.feedbackTypes,
        from: 'video-translation-redo'
      },
      {
        beforeClose(action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true

            doCreateAction(costQuotas, costDetail, instance, done)
          } else {
            done()
          }
        }
      }
    )
  }
  const doCreateAction = (
    costQuotas: number,
    costDetail: VideoTranslationCostDetail,
    instance?: MessageBoxState,
    done?: () => void
  ) => {
    submitId.value = item.id
    retryVideoTranslateTask({
      id: item.id,
      projId: item.projId
    })
      .then((res) => {
        if (res.data.code) {
          ElMessage.error(res.data.message || t('重新生成异常'))
          return
        }
        ElMessage.success(res.data.message || t('重新生成成功'))
        item.state = res.data.data.state
        item.createTime = res.data.data.createTime
        if (costQuotas > 0) {
          projStore.$patch({
            total_remaining: projStore.total_remaining - costQuotas
          })
          fetchSubscriptionPlanAndCreditDetails(projStore)
        }
        // clearRequestTimeout()
        // requestTimer = setTimeout(() => {
        //   fetchData(true)
        // }, 10000)
        if (done) {
          done()
        }
      })
      .catch((err) => {
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          return
        }
        ElMessage.error(err.message || t('重新生成失败'))
      })
      .finally(() => {
        if (instance) {
          instance.confirmButtonLoading = false
        }

        submitId.value = ''
      })
  }

  const detailMap = ref<{
    [key: string]: GeneratedVideo
  }>({})
  const playItem = ref<VideoTranslateTask | null>()

  const downloadItem = ref<VideoTranslateTask | null>()
  const downloadVideo = async (event: MouseEvent) => {
    event.stopPropagation()

    if (downloadItem.value) {
      return
    }

    downloadItem.value = item
    let list: GeneratedVideo[] = []
    try {
      if (detailMap.value[item.id]) {
        list = [detailMap.value[item.id]]
      } else {
        const res = await getGeneratedVideoList({
          projId: item.projId,
          taskId: item.id,
          currentPage: 1,
          pageSize: 1
        })
        list = res.data.data.records || []
      }
    } catch (e) {
      downloadItem.value = null
      const err = e as Error
      console.error(err?.message)
      ElMessage.error(t('下载异常'))
      return
    }
    if (!list.length) {
      downloadItem.value = null
      ElMessage.error(t('下载失败'))
      return
    }

    detailMap.value[item.id] = list[0]

    downloadVideoAction(list[0], 0)
  }
  const downloadVideoAction = (
    item: GeneratedVideo,
    costQuotas: number,
    instance?: MessageBoxState,
    done?: () => void
  ) => {
    //downloadItem.value = item
    const taskData = downloadItem

    const title = taskData.value?.title || item.resolution
    const downloadFilename = `${title}-${item.addressUrl.split('/').slice(-1).join('').split('?')[0]}`

    genVideoDownloadUrl({
      id: item.id,
      projId: item.projId,
      taskId: item.taskId,
      downloadFilename
    })
      .then((res) => {
        if (res.data.code) {
          if (res.data.errorCode === 'TOO_MANY_REQUEST_FOR_DOWNLOAD') {
            ElMessage.error(res.data.message)
            return
          }
          //videoDownload(item.addressUrl, downloadFilename)
          return
        }
        const url = item.addressUrl
        const isCloudfront =
          url.includes('.cloudfront.') ||
          url.includes('res.vmeg.pro') ||
          url.includes('cdn.gthree.cn')
        if (isCloudfront) {
          videoDownload(url, downloadFilename, true)
        } else {
          videoDownload(res.data.data.addressUrl, downloadFilename, true)
        }
        item.downloadCount += 1
        projStore.$patch({
          total_remaining: projStore.total_remaining - costQuotas
        })

        if (done) {
          done()
        }
      })
      .catch((err) => {
        if (err.code === 'ERR_CANCELED' || err.status === 401) {
          return
        }
        //videoDownload(item.addressUrl, downloadFilename)
      })
      .finally(() => {
        if (instance) {
          instance.confirmButtonLoading = false
        }

        setTimeout(() => {
          downloadItem.value = null

          fetchSubscriptionPlanAndCreditDetails(projStore)
        }, 2000)
      })
  }

  return { redoTask, downloadVideo }
}
