<script setup lang="ts">
import { getGoodsMonthlyPrice } from '@/views/Home/utils/price'
import type { GoodsInfo } from '@/api'
import i18nHome from '@/locales/i18n-home'

defineProps<{ goodsInfo: GoodsInfo }>()
const { t } = i18nHome.global
</script>

<template>
  <div class="price-wrapper">
    <div class="price-pay">
      <div class="price">
        <small>{{ goodsInfo.packageConfig.currencySymbol }}</small
        >{{ getGoodsMonthlyPrice(goodsInfo.price, goodsInfo.packagePeriod) }}
      </div>
      <div class="period">/{{ t('mo') }}</div>
    </div>

    <div class="price-each-desc" v-if="!goodsInfo.packageConfig.originPrice">
      <div class="price">
        {{ goodsInfo.packageConfig.currencySymbol }}{{ goodsInfo.packageConfig.creditEach }}
      </div>
      <div class="period">/{{ t('credit') }}</div>
    </div>
  </div>
</template>

<style scoped lang="less">
.price-wrapper {
  height: 48px;
  margin: 20px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .price-pay {
    display: flex;
    align-items: baseline;
    gap: 4px;

    .price {
      font-size: 44px;
      font-weight: 700;
      line-height: 48px;

      small {
        font-size: 30px;
      }
    }

    .period {
      font-family:
        SF Pro Text,
        var(--el-font-family);
      font-size: 22px;
      font-weight: 500;
      line-height: 22px;
      color: #999999;
      white-space: nowrap;
    }
  }

  .price-each-desc {
    display: flex;

    .price {
      font-family:
        SF Pro Text,
        var(--el-font-family);
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }

    .period {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: var(--my-color-black-89-90);
      white-space: nowrap;
    }
  }
}
</style>
