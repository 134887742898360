<script setup lang="ts">
import IconVideoPlay from '@/components/icons/IconVideoPlay.vue'
import IconLoading from '@/components/icons/IconLoading.vue'

// 定义 props 类型
const props = defineProps<{
  loading?: boolean
}>()
</script>

<template>
  <div class="video-play-mask">
    <div class="play-btn" :class="{ 'is-loading': loading }">
      <IconVideoPlay v-if="!loading" class="play-icon" />
      <IconLoading v-else />
    </div>
  </div>
</template>

<style scoped lang="less">
.video-play-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.1);

  .play-btn {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--my-color-white-60);
    border-radius: 50%;
    color: var(--my-color-black-40);

    .play-icon {
      transform: scale(2);
    }

    &:hover {
      color: var(--el-color-primary);
    }
  }
  .is-loading {
    animation: rotate 1s infinite linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
