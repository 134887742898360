import axios from 'axios'
import { useRoute } from 'vue-router'

import { ElMessageBox } from 'element-plus'
import 'element-plus/es/components/message-box/style/css'

import { config, isAppPages } from '@/config'
import i18n from '@/locales/i18n'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { generateUUID, getCookie } from '@/utils'
import { getLocalStorage, getSessionStorage } from '@/utils/storage'

const { t } = i18n.global

const Service = axios.create({
  withCredentials: import.meta.env.SSR ? false : config.baseUrl.split('://')[1] !== location.host,
  baseURL: config.baseUrl
})

Service.defaults.timeout = 30 * 1000 // 30s

Service.interceptors.request.use(
  (conf) => {
    const userStore = useUserStore()
    if (config.useJwtInHeader && userStore.token) {
      if (userStore.exp > Math.round(Date.now() / 1000)) {
        conf.headers.Authorization = `Bearer ${userStore.token}`
      } else {
        console.warn('User token has expired.')
      }
    }
    if (!conf.params) {
      conf.params = {}
    }
    conf.params.req_id = generateUUID().replace(/-/g, '')
    return conf
  },
  (err) => {
    console.error(err)
    return Promise.reject(err)
  }
)

Service.interceptors.response.use(
  (res) => {
    if (res.data?.status === 401 || res.data?.code === 401) {
      /* BaseResponse: {
        "code": 401,
        "errorCode": "UNAUTHORIZED",
        "errorMsg": "Unauthorized, please log in first",
        "message": "未经授权，请先登录"
        "data": null
      } */
      if (!useUserStore().id) {
        if (isAppPages()) {
          gotoSignInPage()
        }
        return Promise.reject({ status: 401, message: 'Unauthorized' })
      }
      if (loadUserInfo()) {
        return Promise.reject({ status: 400, message: t('请求失败，请稍后再试') })
      }
      confirmSignIn()
      return Promise.reject({ status: 401, message: 'Unauthorized' })
    }
    return res
  },
  (err) => {
    console.error(err)
    if (!err.response) {
      return Promise.reject(err)
    }
    const { status } = err.response
    switch (status) {
      case 401:
        if (!useUserStore().id) {
          if (isAppPages()) {
            gotoSignInPage()
          }
          break
        }
        if (loadUserInfo()) {
          return Promise.reject({ status: 400, message: `${t('请求失败，请稍后再试')}.` })
        }
        confirmSignIn('.')
        break
    }
    return Promise.reject(err.response.data)
  }
)

const confirmSignIn = (flag: string = '') => {
  if (isAppPages()) {
    ElMessageBox.confirm(`${t('未登录或登录已过期，需先登录')}${flag}`, '', {
      showClose: false,
      showCancelButton: false,
      type: 'warning',
      confirmButtonText: t('登录')
    })
      .then(() => {
        gotoSignInPage()
      })
      .catch(() => {})
  }
}

const gotoSignInPage = () => {
  const route = useRoute()
  if (route) {
    router.push({ name: 'signin', params: { from: route.fullPath } })
  } else {
    let sourceQuery = ''
    const url = new URL(location.href)
    let utmSource = url.searchParams.get('utm_source')
    if (!utmSource) {
      utmSource = url.searchParams.get('ref')
    }
    if (!utmSource) {
      utmSource = getCookie('utm_source')
    }
    if (
      !utmSource &&
      document.referrer &&
      !document.referrer.includes('vmeg.pro') &&
      !document.referrer.includes('gthree.cn') &&
      !document.referrer.includes('localhost') &&
      !document.referrer.includes('192.168.')
    ) {
      utmSource = document.referrer
        .replace('http://', '')
        .replace('https://', '')
        .split('/')[0]
        .split('?')[0]
        .split('#')[0]
    }
    if (utmSource) {
      sourceQuery = `?utm_source=${utmSource}`
    }
    location.href = config.useHashHistory ? `/${sourceQuery}#/signin/-` : `/signin/-${sourceQuery}`
  }
}

const loadUserInfo = () => {
  const storage = config.saveSignedInfoToLocalStorage ? getLocalStorage() : getSessionStorage()
  const str = storage.getItem('userInfo')
  if (str) {
    try {
      const userStore = useUserStore()
      const data: typeof userStore = JSON.parse(str)
      if (data.exp > Math.round(Date.now() / 1000)) {
        const isNew = userStore.exp < data.exp
        userStore.$patch(data)
        if (isNew) {
          return true
        }
      } else {
        console.warn('User token has expired')
        storage.removeItem('userInfo')
      }
    } catch (e) {
      console.error(e)
    }
  }
  return false
}

export default Service
