export const getGoodsMonthlyPrice = (price: number, period: number) => {
  let str = (price / 100 / period).toFixed(2).replace('.00', '')
  if (str.includes('.') && str.endsWith('0')) {
    str = str.substring(0, str.length - 1)
  }
  return str
}

export const currencySymbol = '$'
export const freePlanCredits = 6
export const annuallyAmountSaved = 20
export const standard = 'Standard'
export const enterprise = 'Enterprise'
export const plansTable = [
  {
    title: 'Free',
    plain: true
  },
  {
    title: standard,
    plain: false
  },
  {
    title: enterprise,
    plain: true
  }
]
