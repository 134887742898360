<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 1.625C6.05127 1.625 5.6875 1.98877 5.6875 2.4375C5.6875 2.88623 6.05127 3.25 6.5 3.25H19.5C19.9487 3.25 20.3125 2.88623 20.3125 2.4375C20.3125 1.98877 19.9487 1.625 19.5 1.625H6.5ZM21.125 19.5V8.125C21.125 7.22754 20.3975 6.5 19.5 6.5H6.5C5.60254 6.5 4.875 7.22754 4.875 8.125V19.5C4.875 20.3975 5.60254 21.125 6.5 21.125H19.5C20.3975 21.125 21.125 20.3975 21.125 19.5ZM6.5 4.875C4.70508 4.875 3.25 6.33007 3.25 8.125V19.5C3.25 21.2949 4.70508 22.75 6.5 22.75H19.5C21.2949 22.75 22.75 21.2949 22.75 19.5V8.125C22.75 6.33008 21.2949 4.875 19.5 4.875H6.5ZM16.4537 12.5378C17.4477 13.0969 17.4477 14.5281 16.4537 15.0872L11.9295 17.632C10.9546 18.1804 9.75 17.4759 9.75 16.3574L9.75 11.2677C9.75 10.1491 10.9546 9.44459 11.9295 9.99297L16.4537 12.5378ZM15.4052 13.8125L11.375 11.5455V16.0795L15.4052 13.8125Z"
      fill="currentColor"
      fill-opacity="0.9"
    />
  </svg>
</template>
