<script setup lang="ts">
import { CircleCheckFilled, CircleCloseFilled } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { freePlanCredits, plansTable, standard } from '@/views/Home/utils/price'
import i18nHome from '@/locales/i18n-home'
import BlockTitle from '@/views/Home/components/Pricing/modules/BlockTitle.vue'
const { t } = i18nHome.global

const plansDetails = ref<
  {
    title: string
    features: {
      title: string
      values: {
        support?: boolean
        value?: string | number
        text?: string
      }[]
    }[]
  }[]
>([
  {
    title: 'Fundamental',
    features: [
      {
        title: 'No Watermark',
        values: [{ support: false }, { support: true }, { support: true }]
      },
      {
        title: 'Credit/Month',
        values: [
          { value: freePlanCredits },
          { value: [45, 135, 270].join(' / ') },
          { text: 'Customizable' }
        ]
      },
      {
        title: 'Speed',
        values: [{ text: 'Standard' }, { text: 'High Speed' }, { text: 'High Speed' }]
      },
      {
        title: 'Cloud Storage',
        values: [{ value: '1GB' }, { value: '100GB' }, { text: 'Customizable' }]
      },
      {
        title: 'Video Resolution',
        values: [{ value: '720P' }, { value: '720P, 1080P' }, { text: 'Customizable' }]
      }
    ]
  },
  {
    title: 'Clips to Videos',
    features: [
      {
        title: 'Video Duration Selection',
        values: [{ support: false }, { support: true }, { support: true }]
      },
      {
        title: 'Video Ratio',
        values: [{ value: '9:16' }, { value: '9:16, 16:9' }, { text: 'Customizable' }]
      },
      {
        title: 'Automatic Selling Point Generation',
        values: [{ support: false }, { support: true }, { support: true }]
      }
    ]
  },
  {
    title: 'Video Translation',
    features: [
      {
        title: 'Max Video Duration Per Video',
        values: [
          { value: `120 ${t('time.symbol.second')}` },
          { value: `120 ${t('time.symbol.second')}` },
          { text: 'Customizable' }
        ]
      },
      {
        title: 'Translation Proofread',
        values: [{ support: true }, { support: true }, { support: true }]
      },
      {
        title: 'With Lip Sync',
        values: [{ support: true }, { support: true }, { support: true }]
      },
      {
        title: 'AI Voice',
        values: [{ support: true }, { support: true }, { support: true }]
      },
      {
        title: 'Voice Clone',
        values: [{ support: true }, { support: true }, { support: true }]
      }
    ]
  }
])
</script>

<template>
  <div class="block block-price-details">
    <div class="content">
      <BlockTitle>{{ t('Plan Detail') }}</BlockTitle>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th
                :class="{ 'with-highlight-bg is-fitst-highlight-bg': plan.title == standard }"
                v-for="(plan, idx) in plansTable"
                :key="idx"
              >
                {{ t(plan.title) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(detail, i) in plansDetails" :key="i">
              <template v-for="(feature, idx) in detail.features" :key="idx">
                <tr
                  :class="{
                    'with-padding-top': !idx,
                    'with-padding-bottom': idx + 1 == detail.features.length
                  }"
                >
                  <th class="detail-title" :rowspan="detail.features.length" v-if="!idx">
                    {{ t(detail.title) }}
                  </th>
                  <th class="feature-title">{{ t(feature.title) }}</th>
                  <td
                    :class="{
                      'with-highlight-bg': plansTable[index].title == standard,
                      'is-last-highlight-bg':
                        i + 1 == plansDetails.length && idx + 1 == detail.features.length
                    }"
                    v-for="(item, index) in feature.values"
                    :key="index"
                  >
                    <div v-if="item.support !== undefined">
                      <el-icon class="supported" v-if="item.support">
                        <CircleCheckFilled />
                      </el-icon>
                      <el-icon v-else><CircleCloseFilled /></el-icon>
                    </div>
                    <div v-if="item.text">{{ t(item.text) }}</div>
                    <div v-if="item.value">{{ item.value }}</div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.block-price-details {
  .content {
    width: 1120px;
    margin: 0 auto;

    .table-wrapper {
      width: 100%;
      overflow-x: auto;

      table {
        border-collapse: collapse;
        width: 100%;

        font-size: 16px;
        line-height: 24px;

        .with-highlight-bg {
          background: var(--my-color-primary-8, #7b4dff14);

          &.is-fitst-highlight-bg {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }

          &.is-last-highlight-bg {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }

        thead {
          th {
            padding: 40px 20px;
            font-size: 24px;
            font-weight: 500;
          }
        }

        tbody {
          tr {
            height: 64px;

            &.with-padding-top {
              border-top: 1px solid var(--my-color-black-10);

              th {
                padding-top: 40px;
              }

              td {
                padding-top: 20px;
              }
            }

            &.with-padding-bottom {
              th {
                padding-bottom: 40px;
              }

              td {
                padding-bottom: 20px;
              }
            }

            th {
              max-width: 80px;
              padding: 20px 20px 20px 0;

              &.detail-title {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: var(--my-color-black-100);
                vertical-align: top;
              }

              &.feature-title {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: var(--my-color-black-60);
              }
            }

            td {
              white-space: nowrap;
              text-align: center;
            }
          }
        }

        .el-icon {
          font-size: 28px; // 26px
          color: var(--my-color-black-20);

          &.supported {
            color: var(--el-color-primary);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block {
    .content {
      width: calc(100% - var(--el-main-padding) * 2);
      padding-left: var(--el-main-padding);
      padding-right: var(--el-main-padding);
    }
  }

  .block-price-details {
    .content {
      .table-wrapper {
        table {
          font-size: 14px;
          line-height: 20px;

          thead {
            th {
              padding: 20px 10px;
              font-size: 20px;
            }
          }

          tbody {
            tr {
              height: 44px;

              &.with-padding-top {
                th {
                  padding-top: 20px;
                }

                td {
                  padding-top: 10px;
                }
              }

              &.with-padding-bottom {
                th {
                  padding-bottom: 20px;
                }

                td {
                  padding-bottom: 10px;
                }
              }

              th {
                max-width: 100px;
                padding: 10px 10px 10px 0;

                &.detail-title {
                  font-size: 16px;
                  line-height: 22px;
                }

                &.feature-title {
                  font-size: 14px;
                  line-height: 20px;
                }
              }

              td {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }

          .el-icon {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
