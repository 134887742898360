<script setup lang="ts">
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'

const { isMobile } = useClientInfo()
</script>

<template>
  <div class="product-item-skeleton" :class="{ mobile: isMobile }">
    <el-skeleton animated>
      <template #template>
        <div class="product-item-skeleton-container">
          <div class="thumbnail">
            <el-skeleton-item style="height: 100%" />
          </div>
          <div class="intro">
            <el-skeleton-item style="width: 164px; height: 16px" />
            <el-skeleton-item style="width: 224px; height: 12px" />
            <el-skeleton-item style="width: 110px; height: 12px" />
          </div>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<style scoped lang="less">
.product-item-skeleton {
  display: inline-block;
  overflow: hidden;
  width: 256px;

  .product-item-skeleton-container {
    width: 256px;
    height: 247px;
    background: #f3f3f3;
    border-radius: 12px;
    overflow: hidden;

    .thumbnail {
      height: 160px;
      overflow: hidden;
    }

    .intro {
      height: 76px;
      padding-top: 12px;
      padding-left: 12px;
    }
  }
}

.mobile.product-item-skeleton {
  width: 100%;
  height: auto;
  .product-item-skeleton-container {
    width: 100%;
    height: auto;

    .thumbnail {
      width: 100%;
      height: auto;
      aspect-ratio: calc(256 / 160);
    }
  }
}
</style>
