<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import { Loading } from '@element-plus/icons-vue'

import {
  userSignUp,
  sendValidateCode,
  getCaptchaBase64,
  queryDisplayConfig,
  DisplayContentType,
  type DisplayConfigItem
} from '@/api'
import router, { getDefaultStartRoute, routeNameMap } from '@/router'
import { useUserStore, updateUserStore } from '@/stores/user'
import { useProjStore, updateProjData, fetchSubscriptionPlanAndCreditDetails } from '@/stores/proj'
import { isValidEmail, isValidPhoneNumber, getCookie } from '@/utils'
import LoadingAnimation from '@/views/Dashboard/components/LoadingAnimation.vue'
import { useLoadingThrottle } from '@/views/Dashboard/hooks/useLoadingThrottle'
import { config } from '@/config'

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()

const nickName = ref(userStore.name)
const emailInput = ref(userStore.email)
const emailValidateCode = ref('')
const phoneInput = ref(userStore.phone)
const phoneValidateCode = ref('')
const isSaving = ref(false)

const captchaInput = ref<string>('')
const captchaId = ref<string>('')
const captchaSrc = ref('')
const loadingCaptcha = ref(false)

const { throttleWrapper } = useLoadingThrottle()

const addSignupOrSignin = () => {
  const name = nickName.value.trim()
  const email = emailInput.value.trim()
  const phone = phoneInput.value.trim()
  if ((route.params.from === 'register' && !name) || (!email && !phone)) {
    return
  }
  const evc = emailValidateCode.value.trim()
  const pvc = phoneValidateCode.value.trim()
  if (!evc && !pvc) {
    ElMessage.info(t('请输入验证码'))
    return
  }

  const url = new URL(location.href)
  let utmSource = url.searchParams.get('utm_source')
  if (!utmSource) {
    utmSource = url.searchParams.get('ref')
  }
  if (!utmSource) {
    utmSource = getCookie('utm_source')
  }
  if (
    !utmSource &&
    document.referrer &&
    !document.referrer.includes('vmeg.pro') &&
    !document.referrer.includes('gthree.cn') &&
    !document.referrer.includes('localhost') &&
    !document.referrer.includes('192.168.')
  ) {
    utmSource = document.referrer
      .replace('http://', '')
      .replace('https://', '')
      .split('/')[0]
      .split('?')[0]
      .split('#')[0]
  }

  isSaving.value = true
  userSignUp({
    name,
    email,
    emailValidateCode: evc,
    phone,
    phoneValidateCode: pvc,
    utmSource
  })
    .then((res) => {
      if (res.data.code || !res.data.data?.user) {
        console.error(res)
        ElMessage.error(
          res.data.message || (route.params.from === 'register' ? t('注册失败') : t('登录失败'))
        )
        return
      }

      emailValidateCode.value = ''
      phoneValidateCode.value = ''

      updateUserStore(userStore, res.data.data)
      updateProjData(projStore, res.data.data.projectInfo)

      fetchSubscriptionPlanAndCreditDetails(projStore)

      let from = String(route.params.from || '').replace(/^phone/, '')
      const linkPrefix = config.useHashHistory ? '#' : '' // 因为用了hash路由所以要加前缀
      if (from && !['-', 'register'].includes(from)) {
        window.open((linkPrefix + '/' + from).replace(/\/+/, '/'), '_self')
        return
      }
      if (userStore.roles?.includes('admin')) {
        window.open(linkPrefix + '/admin/user-projects', '_self')
        return
      }

      //router.push({ name: 'home' })
      // router.push({ name: 'vmeg.create-video', params: { projId: projStore.projId || '-' } })
      // router.push({ name: 'vmeg.video-translation', params: { projId: projStore.projId || '-' } })
      window.open(linkPrefix + getDefaultStartRoute().path, '_self')
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(route.params.from === 'register' ? t('注册异常') : t('登录异常'))
    })
    .finally(() => {
      isSaving.value = false
    })
}
const sendValidateCodeReady = (type: 'email' | 'phone') => {
  return (
    !validateCodeCountdown.value &&
    captchaInput.value &&
    (type === 'email' ? isValidEmail(emailInput.value) : isValidPhoneNumber(phoneInput.value))
  )
}
const validateCodeCountdown = ref(0)
let doValidateCodeTimer: ReturnType<typeof setInterval> | null = null
const sendValidateCodeAction = (type: 'email' | 'phone') => {
  if (!sendValidateCodeReady(type)) {
    return
  }
  validateCodeCountdown.value = -1
  const captchaCode = captchaInput.value.replace(/\s+/g, '')
  if (captchaCode.length !== 6 || !/[a-zA-Z0-9]+$/.test(captchaCode)) {
    setTimeout(() => {
      ElMessage.error(t('图形验证码不正确') + /^[a-zA-Z0-9]+$/.test(captchaCode))
      validateCodeCountdown.value = 0
    }, 1000)
    return
  }

  sendValidateCode({
    type,
    target: type === 'email' ? emailInput.value : phoneInput.value,
    captchaId: captchaId.value,
    captchaCode: captchaCode
  })
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('获取验证码失败'))
        validateCodeCountdown.value = 0
        return
      }
      ElMessage.success(res.data.message || t('验证码已发送'))
      validateCodeCountdown.value = 60

      doValidateCodeTimer = setInterval(doValidateCodeCountdown, 1000)
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('获取验证码异常'))
      if (err.code === 429) {
        // TOO_MANY_REQUESTS
        doValidateCodeTimer = setInterval(doValidateCodeCountdown, 1000)
      } else {
        validateCodeCountdown.value = 0
      }
    })
}
const doValidateCodeCountdown = () => {
  if (validateCodeCountdown.value > 0) {
    validateCodeCountdown.value -= 1
  } else if (doValidateCodeTimer) {
    clearInterval(doValidateCodeTimer)
  }
}

const onSubmit = () => {
  addSignupOrSignin()
}

const clearCaptcha = () => {
  captchaId.value = ''
  captchaSrc.value = ''
}
const initCaptcha = async () => {
  if (loadingCaptcha.value) return

  clearCaptcha()
  loadingCaptcha.value = true
  try {
    await throttleWrapper(async () => {
      const rsp = await getCaptchaBase64()
      captchaId.value = rsp.data.data.captchaId
      captchaSrc.value = rsp.data.data.captchaSrc
    })
  } catch (err) {
    console.log('dd')
  } finally {
    loadingCaptcha.value = false
  }
}

onMounted(() => {
  initCaptcha()
})
</script>

<template>
  <div class="my-workspace my-large-form-item user-page">
    <div class="user-wrapper">
      <div class="user-header">{{ route.params.from == 'register' ? t('注册') : t('登录') }}</div>
      <el-form label-position="top">
        <el-form-item :label="t('昵称')" v-if="route.params.from == 'register'">
          <el-input :placeholder="t('请输入昵称')" v-model="nickName" clearable />
        </el-form-item>
        <template v-if="!String(route.params.from).startsWith('phone')">
          <el-form-item :label="t('邮箱')">
            <el-input
              name="email"
              autocomplete
              :placeholder="t('请输入邮箱')"
              type="email"
              required
              v-model="emailInput"
              clearable
            />
          </el-form-item>
          <el-form-item :label="t('图形验证码')">
            <el-input
              name="captcha"
              :placeholder="t('请输入图形验证码')"
              required
              v-model="captchaInput"
              clearable
              class="captcha-input"
            >
              <template #append>
                <div class="captcha-wrapper" @click="initCaptcha">
                  <LoadingAnimation height="40px" transform="scale(0.4)" v-if="loadingCaptcha" />
                  <template v-else>
                    <el-image :src="captchaSrc" alt="">
                      <template #placeholder>
                        <div>
                          <LoadingAnimation height="40px" />
                        </div>
                      </template>
                    </el-image>
                  </template>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="t('验证码')">
            <el-input :placeholder="t('请输入验证码')" v-model="emailValidateCode" clearable>
              <template #append>
                <el-tooltip
                  :content="t('请先输入正确的邮箱和图形验证码')"
                  :disabled="isValidEmail(emailInput)"
                >
                  <el-link
                    :disabled="!sendValidateCodeReady('email')"
                    @click="sendValidateCodeAction('email')"
                  >
                    <el-icon class="is-loading" v-if="validateCodeCountdown == -1">
                      <Loading />
                    </el-icon>
                    <span v-else>
                      {{
                        validateCodeCountdown > 0
                          ? `${validateCodeCountdown}${t('time.symbol.second', 's')}`
                          : t('获取验证码')
                      }}
                    </span>
                  </el-link>
                </el-tooltip>
              </template>
            </el-input>
          </el-form-item>
        </template>
        <template v-if="String(route.params.from).startsWith('phone')">
          <el-form-item :label="t('手机号码')">
            <el-input
              name="phone"
              autocomplete
              :placeholder="t('请输入手机号码')"
              type="tel"
              required
              v-model="phoneInput"
              clearable
            />
          </el-form-item>
          <el-form-item :label="t('图形验证码')">
            <el-input
              name="captcha"
              :placeholder="t('请输入图形验证码')"
              required
              v-model="captchaInput"
              clearable
              class="captcha-input"
            >
              <template #append>
                <div class="captcha-wrapper" @click="initCaptcha">
                  <LoadingAnimation height="40px" transform="scale(0.4)" v-if="loadingCaptcha" />
                  <template v-else>
                    <el-image :src="captchaSrc" alt="">
                      <template #placeholder>
                        <div>
                          <LoadingAnimation height="40px" />
                        </div>
                      </template>
                    </el-image>
                  </template>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item :label="t('验证码')">
            <el-input :placeholder="t('请输入验证码')" v-model="phoneValidateCode" clearable>
              <template #append>
                <el-tooltip
                  :content="t('请先输入正确的手机号码和图形验证码')"
                  :disabled="isValidPhoneNumber(phoneInput)"
                >
                  <el-link
                    :disabled="!sendValidateCodeReady('phone')"
                    @click="sendValidateCodeAction('phone')"
                  >
                    <el-icon class="is-loading" v-if="validateCodeCountdown == -1">
                      <Loading />
                    </el-icon>
                    <span v-else>
                      {{
                        validateCodeCountdown > 0
                          ? `${validateCodeCountdown}${t('time.symbol.second', 's')}`
                          : t('获取验证码')
                      }}
                    </span>
                  </el-link>
                </el-tooltip>
              </template>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" :loading="isSaving" @click="onSubmit">
            {{ route.params.from == 'register' ? t('注册') : t('登录') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<style scoped lang="less">
.my-workspace {
  height: calc(
    100vh - var(--el-header-height) - var(--el-footer-height) - var(--el-main-padding) -
      var(--el-main-padding)
  );
  display: flex;

  &.my-large-form-item {
    --el-component-size: 40px;

    .el-form-item__label,
    .el-form-item__content {
      line-height: var(--el-component-size);
      // min-height: var(--el-component-size);
    }

    .el-button,
    .el-radio {
      height: var(--el-component-size);
    }
  }

  .el-form {
    width: 320px;
    margin: 30px auto;

    :deep(.el-input-group__append),
    :deep(.el-input-group__prepend) {
      background-color: var(--el-fill-color-blank);
    }

    .el-form-item__content {
      a.el-link,
      a.el-link:hover {
        color: var(--el-color-primary);
        text-decoration: none;
        user-select: none;

        &.is-underline:hover:after {
          display: none;
        }
      }
    }
  }
}

.user-page {
  .user-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    .user-header {
      font-size: 32px;
      color: var(--el-text-color-regular);
      user-select: none;
    }

    .el-form {
      margin-top: 36px;
    }

    .el-button {
      width: 100%;
      margin-top: 50px;
    }

    .el-link.is-disabled {
      color: var(--el-color-primary-light-3) !important;
    }
  }
}
</style>

<style>
.captcha-input {
  .captcha-wrapper {
    cursor: pointer;
    width: 178px;
    height: 38px;
    margin: 2px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
    }
  }

  .el-input-group__append {
    padding: 0 !important;
  }
}
</style>
