<script setup lang="ts">
import { computed, onMounted, provide, ref } from 'vue'
import { config as siteConf, keepAliveViews } from '@/config'
import router from '@/router'
import { getSignedInfo } from '@/api'
import { updateUserStore, useUserStore } from '@/stores/user'
import { fetchSubscriptionPlanAndCreditDetails, updateProjData, useProjStore } from '@/stores/proj'
import en from 'element-plus/dist/locale/en.mjs'
import { config as localeConfig } from '@/locales'
import { getCookie, getDefaultBrowserLanguage, setCookie } from '@/utils'
import zhCn from 'element-plus/dist/locale/zh-CN.mjs'
import i18nHome from '@/locales/i18n-home'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import LoadingAnimation from '@/views/Dashboard/components/LoadingAnimation.vue'
import GloablLoadingAnimation from '@/views/Dashboard/components/GloablLoadingAnimation.vue'
import { useSignCheck } from '@/views/Dashboard/hooks/useSignCheck'
import { getLocalStorage, getSessionStorage } from '@/utils/storage'

const config = siteConf

const { t, locale: i18nLocale } = useI18n()
const route = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()
const { checkSign, loading: loadingSignInfo } = useSignCheck()

const localeSetting = ref(en)
const setLocale = (locale: string, notSetCookie: boolean = false) => {
  if (!locale || localeConfig.disabled) {
    if (!locale) {
      notSetCookie = true
      setCookie(localeConfig.localeCookieName, locale)
    }
    locale = getDefaultBrowserLanguage(localeConfig.disabled)
  }
  if (locale === 'zh' || String(locale).startsWith('zh-')) {
    localeSetting.value = zhCn
  } else {
    localeSetting.value = en
  }
  i18nLocale.value = locale
  // @ts-expect-error
  i18nHome.global.locale.value = locale
  if (!notSetCookie) {
    setCookie(localeConfig.localeCookieName, locale)
  }

  if (!import.meta.env.SSR) {
    const routeTitle = route.meta.title as (() => string) | undefined
    const routeIntro = route.meta.intro as (() => string) | undefined
    document.title = `${config.siteTitle}${routeIntro ? `-${routeIntro()}` : routeTitle ? `-${routeTitle()}` : ''}`
    document.documentElement.setAttribute('lang', locale)
  }
}
const locale =
  getCookie(localeConfig.localeCookieName) || getDefaultBrowserLanguage(localeConfig.disabled) // 首先从缓存里拿，没有的话就用浏览器语言
if (localeConfig.locales.includes(locale) || localeConfig.locales.includes(locale.split('-')[0])) {
  setLocale(locale, true)
}

const storage = import.meta.env.SSR
  ? {
      getItem: () => {},
      setItem: () => {},
      removeItem: () => {}
    }
  : config.saveSignedInfoToLocalStorage
    ? getLocalStorage()
    : getSessionStorage()
const cachedViews = ref<string[]>([])
provide('cachedViews', cachedViews)

const userInfoKey = 'userInfo'
const projectInfoKey = 'projectInfo'

const clearUserAndProjectCache = () => {
  storage.removeItem(userInfoKey)
  storage.removeItem(projectInfoKey)
}

const initUserAndProjectInfo = () => {
  const userStr = storage.getItem(userInfoKey)
  const projStr = storage.getItem(projectInfoKey)

  if (userStr && projStr) {
    try {
      const userCache: typeof userStore = JSON.parse(userStr)
      const projCache: typeof projStore = JSON.parse(projStr)

      if (userCache.exp > Math.round(Date.now() / 1000)) {
        userStore.$patch(userCache)
      } else {
        console.warn('User token has expired')
        return clearUserAndProjectCache()
      }

      if (projCache) {
        projStore.$patch(projCache)
      } else {
        console.warn('projectInfoKey has expired')
        return clearUserAndProjectCache()
      }
    } catch (e) {
      clearUserAndProjectCache()
      console.error(e)
    }
  }
}
const subscribeStoreChanges = () => {
  userStore.$subscribe((mutation, state) => {
    if (state.id) {
      cachedViews.value = keepAliveViews
      setLocale(state.locale)

      storage.setItem(
        userInfoKey,
        JSON.stringify(
          Object.assign({}, state, {
            token: config.useJwtInHeader ? state.token : ''
          })
        )
      )
    } else {
      cachedViews.value = []
      storage.removeItem(userInfoKey)
      if (route.meta.requiresAuth) {
        router.push({ name: 'home' })
      }
    }
  })

  projStore.$subscribe((mutation, state) => {
    if (state.projId) {
      storage.setItem(projectInfoKey, JSON.stringify(Object.assign({}, state)))
    } else {
      storage.removeItem(projectInfoKey)
    }
  })
}

const routeNeedCheckAuth = () => {
  return !!route.matched?.find((i) => i.meta.requiresAuth)
}

const checkSignedInfo = async () => {
  try {
    await checkSign()
  } catch (err: any) {
    if (err.code === 'ERR_CANCELED' || err.status === 401) {
      return
    }

    if (routeNeedCheckAuth()) {
      router.push({ name: 'signin', params: { from: route.fullPath } })
    }
  }
}

onMounted(() => {
  // init user info and subscriber from cache
  initUserAndProjectInfo()
  subscribeStoreChanges()

  if (!import.meta.env.SSR) {
    checkSignedInfo()
  }
})
</script>
<template>
  <div v-if="routeNeedCheckAuth() && !userStore.id && loadingSignInfo">
    <GloablLoadingAnimation />
  </div>
  <router-view></router-view>
</template>
