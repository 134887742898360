<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.81044 4.34191C8.81044 3.20736 10.0381 2.49777 11.0212 3.06406L17.9938 7.08036C18.9787 7.64764 18.9787 9.0688 17.9938 9.63607L11.0212 13.6524C10.0381 14.2187 8.81044 13.5091 8.81044 12.3745L8.81044 4.34191Z"
      fill="#FFB321"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.82719 0.226562C5.1846 0.226562 6.28499 1.32696 6.28499 2.68437V23.0841C6.28499 24.4415 5.1846 25.5419 3.82719 25.5419C2.46978 25.5419 1.36938 24.4415 1.36938 23.0841V2.68437C1.36938 1.32696 2.46978 0.226562 3.82719 0.226562Z"
      fill="#FF6B86"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.6591 11.785C24.3308 12.9645 23.9191 14.4653 22.7395 15.137L5.04334 25.214C3.86377 25.8857 2.36303 25.474 1.69133 24.2944C1.01963 23.1148 1.43134 21.6141 2.61091 20.9424L20.3071 10.8654C21.4867 10.1937 22.9874 10.6054 23.6591 11.785Z"
      fill="#7B4DFF"
    />
  </svg>
</template>
