<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import CreaditInfoV2 from '@/components/CreditInfoV2/CreaditInfoV2.vue'
import { User } from '@element-plus/icons-vue'
import UserMenuInfo from '@/components/UserMenuInfo/UserMenuInfo.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import IconLogo from '@/components/icons/IconLogo.vue'
import IconHamburger from '@/components/icons/IconHamburger.vue'
import { onMounted, ref, watch } from 'vue'
import DashboardMenu from '@/views/Dashboard/components/DashboardMenu.vue'
import DashboardMenuSideBar from '@/views/Dashboard/components/DashboardMenuSideBar.vue'
import router from '@/router'
import IconClose from '@/components/icons/IconClose.vue'
import IconCloseInherits from '@/components/icons/IconCloseInherits.vue'
import UserDrawerInfo from '@/components/UserMenuInfo/UserDrawerInfo.vue'
import { userSignOut } from '@/api'
import { useProjStore } from '@/stores/proj'

defineProps<{ menuShowShadow?: boolean }>()
const route: any & { meta: any } = useRoute()
const userStore = useUserStore()
const projStore = useProjStore()
const showRouteDrawer = ref(false)
const showUserDrawer = ref(false)
const { isMobile } = useClientInfo()

const handleRouterTriggerClick = () => {
  showRouteDrawer.value = !showRouteDrawer.value
  showUserDrawer.value = false
}

const handleUserTriggerClick = () => {
  showUserDrawer.value = !showUserDrawer.value
  showRouteDrawer.value = false
}

const doSignOut = () => {
  userSignOut().finally(() => {
    userStore.$reset()
    projStore.$reset()
  })
  router.push({ name: 'home' })
}

onMounted(() => {
  watch(
    () => route.name,
    () => {
      showRouteDrawer.value = false
      showUserDrawer.value = false
    }
  )
})
</script>

<template>
  <div class="home-topbar" :class="{ mobile: isMobile, 'with-shadow': menuShowShadow }">
    <!--  web topbar  -->
    <template v-if="!isMobile">
      <div class="title">{{ route.meta.title() }}</div>
      <div class="credit-info" v-if="userStore.id">
        <CreaditInfoV2 :isInMenu="false" v-if="userStore.id" />
      </div>
      <div class="user-info">
        <UserMenuInfo />
      </div>
    </template>

    <!--  mobile topbar  -->
    <template v-else>
      <div class="logo" @click="router.push({ name: 'home' })"><IconLogo /></div>
      <div class="title">{{ route.meta.title() }}</div>
      <div class="op-wrapper">
        <el-avatar :src="userStore.avatar" :size="28" @click="handleUserTriggerClick" />
        <div class="menu-hamburger" @click="handleRouterTriggerClick">
          <IconCloseInherits v-if="showRouteDrawer" class="close-icon" />
          <IconHamburger v-else />
        </div>
      </div>
    </template>
  </div>
  <template v-if="isMobile">
    <el-drawer
      v-if="isMobile"
      v-model="showRouteDrawer"
      size="80%"
      :with-header="false"
      modal-class="menu-drawer-container mobile"
    >
      <DashboardMenuSideBar />
    </el-drawer>

    <el-drawer
      v-if="isMobile"
      v-model="showUserDrawer"
      size="auto"
      direction="btt"
      :with-header="false"
      modal-class="user-drawer-container mobile"
    >
      <UserDrawerInfo :on-cancel="handleUserTriggerClick" :on-signout="doSignOut" />
    </el-drawer>
  </template>
</template>

<style scoped lang="less">
.home-topbar {
  padding: 22px 32px 12px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  transition: box-shadow 0.2s ease;
  background-color: #fff;

  .title {
    font-size: 20px;
    color: rgba(#000000, 0.9);
    flex: 1;
  }

  .user-info {
    width: 32px;
    height: 32px;
  }

  &.with-shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  }
}

.mobile.home-topbar {
  height: 58px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2222; // 需要改在drawer上面

  .logo {
    position: absolute;
    width: 76px;
    height: 26px;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .op-wrapper {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;

    .menu-hamburger {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      border-left: 1px solid #d9d9d9;
      padding-left: 10px;
      margin-left: 10px;

      svg {
        color: var(--my-color-black-90);
      }

      .close-icon {
        transform: scale(2);
      }
    }
  }
}
</style>

<style>
.mobile.menu-drawer-container {
  .el-drawer__body {
    padding: 0;
    padding-top: calc(env(safe-area-inset-top) + 58px);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.user-drawer-container {
  .el-drawer {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .el-drawer__body {
    padding: 0;
  }
}
</style>
