<script setup lang="ts">
import { ref, onBeforeUpdate, onUnmounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { ElMessage } from 'element-plus'
import 'element-plus/es/components/message/style/css'

import IconEmpty from '@/components/icons/IconEmpty.vue'

import SoundPlayer from '@/components/SoundPlayer.vue'

import { type VoiceTemplate, getVoiceTemplateList } from '@/api'

const { t } = useI18n()

const emit = defineEmits(['update:modelValue', 'openCloneVoice', 'selected:done', 'stopAllSound'])
const props = withDefaults(
  defineProps<{
    modelValue: boolean // v-model默认的名字
    onOpenCloneVoice?: () => void
    'onSelected:done': (materials: VoiceTemplate[]) => void
    onStopAllSound?: () => void
    language?: string
    limit?: number
  }>(),
  {
    limit: 1
  }
)

const dialogVisible = computed({
  get: () => props.modelValue,
  set: (nv) => {
    emit('update:modelValue', nv)
  }
})

const refs = ref<(typeof SoundPlayer)[]>([])

// make sure to reset the refs before each update
onBeforeUpdate(() => {
  refs.value = []
})

const selectedList = ref<VoiceTemplate[]>([])
const selectedLanguage = ref('')
const tagGroupList = [t('语言'), t('性别'), t('年龄')]
const tagList = ref<VoiceTemplate['tags'][]>([])
const tagFiler = ref<string[]>([])
const dataListDisplay = ref<VoiceTemplate[]>([])

let requestTask: AbortController | null = null
const isLoading = ref(false)
const currentPage = ref(1)
const dataList = ref<VoiceTemplate[]>([])
const total = ref(0)
const background = ref(false)
const pagerCount = ref(7)
const layout = ref('prev, pager, next, jumper, ->, total')
const pageSizes = [1000, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const pageSize = ref(pageSizes[0])
/*const searchName = ref('')
const searchSubmitedText = ref('')
const isSearching = ref(false)*/

// 有一些tts voiceid有问题，先在前端关掉选择入口
// TODO 后面看看配置在哪里
const bannedIds = ['mercury_alvaro', 'mercury_klarissa']

const fetchData = () => {
  tagList.value = []
  tagFiler.value = []
  dataListDisplay.value = []

  abortRequest()
  dataList.value = []
  total.value = 0
  isLoading.value = true

  const controller = new AbortController()
  requestTask = controller

  //searchSubmitedText.value = searchName.value

  getVoiceTemplateList(
    {
      language: selectedLanguage.value.split('-')[0],
      //searchName: searchName.value,
      currentPage: currentPage.value,
      pageSize: pageSize.value,
      orderBy: 'name',
      order: 'asc'
    },
    {
      signal: controller.signal
    }
  )
    .then((res) => {
      if (res.data.code) {
        ElMessage.error(res.data.message || t('加载异常'))
        return
      }
      total.value = res.data.data.total
      if (res.data.data.current === currentPage.value && res.data.data.size === pageSize.value) {
        dataList.value = res.data.data.records?.filter((i) => !bannedIds.includes(i.voiceId)) || []
      }

      if (dataList.value.length) {
        const groups: {
          [key: string]: VoiceTemplate[]
        } = {}
        dataList.value.forEach((item) => {
          if (!groups[item.provider]) {
            groups[item.provider] = []
          }
          groups[item.provider].push(item)
        })
        dataList.value = []
        for (const key in groups) {
          dataList.value.push(...groups[key])
        }
        selectedList.value = [dataList.value[0]]
        dataList.value.forEach((item) => {
          item.tags.forEach((tag, index) => {
            if (!tagList.value[index]) {
              tagList.value[index] = []
            }
            if (!tagList.value[index].includes(tag)) {
              tagList.value[index].push(tag)
            }
          })
        })
        tagList.value.forEach((tags) => {
          tags.sort()
        })
        dataListDisplay.value = dataList.value
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_CANCELED' || err.status === 401) {
        return
      }
      ElMessage.error(err.message || t('加载失败'))
    })
    .finally(() => {
      isLoading.value = false
      //isSearching.value = false
    })
}
const handleSizeChange = (val: number) => {
  pageSize.value = val
  currentPage.value = 1
  fetchData()
}
const handleCurrentChange = (val: number) => {
  currentPage.value = val
  fetchData()
}
const abortRequest = () => {
  if (requestTask) {
    requestTask.abort()
    requestTask = null
  }
}
onUnmounted(() => {
  abortRequest()
})
/*const searchSubmit = () => {
  isSearching.value = true
  currentPage.value = 1
  fetchData()
}*/
const onFilterChange = (tags: string[]) => {
  selectedList.value = []
  if (!tags.length) {
    dataListDisplay.value = dataList.value
    return
  }
  dataListDisplay.value = []
  dataList.value.forEach((item) => {
    let bad = false
    tags.some((tag) => {
      if (!item.tags.includes(tag)) {
        bad = true
      }
      return bad
    })
    if (!bad) {
      dataListDisplay.value.push(item)
    }
  })
}
const groupWithSelected = (index: number, tagValue: string) => {
  let selected = false
  tagFiler.value.some((tag) => {
    if (tag !== tagValue && tagList.value[index].includes(tag)) {
      selected = true
    }
    return selected
  })
  return selected
}

const getData = () => {
  if (props.language) {
    if (selectedLanguage.value !== props.language) {
      tagList.value = []
      tagFiler.value = []
      dataListDisplay.value = []
      selectedList.value = []

      currentPage.value = 1
      dataList.value = []
      total.value = 0
    }

    selectedLanguage.value = props.language
  }

  if (!dataList.value.length) {
    fetchData()
    return
  }
}

const stopAllSound = (exclude?: string) => {
  refs.value.forEach((ref) => {
    if (!ref) {
      console.error('!ref')
      return
    }
    if (exclude && ref.$el.id === exclude) {
      return
    }
    ref.stop()
  })
  if (exclude) {
    emit('stopAllSound')
  }
}

const openCloneVoice = () => {
  stopAllSound()
  dialogVisible.value = false
  emit('openCloneVoice')
}

const selectedCancel = () => {
  //selectedList.value = []
  dialogVisible.value = false
}
const selectedDone = () => {
  emit('selected:done', [...selectedList.value])
  selectedCancel()
}

const isInSelectedList = (material: VoiceTemplate, materials?: VoiceTemplate[]) => {
  let found = false
  if (!materials) {
    materials = selectedList.value
  }
  materials.some((obj) => {
    found = obj.id === material.id
    return found
  })
  return found
}

const selectItem = (material: VoiceTemplate) => {
  if (isInSelectedList(material)) {
    selectedList.value.some((obj, index) => {
      if (obj.id === material.id) {
        selectedList.value.splice(index, 1)
        return true
      }
      return false
    })
    return
  }
  if (props.limit === 1) {
    selectedList.value = [material]
    return
  }
  if (props.limit <= selectedList.value.length) {
    return
  }
  selectedList.value.push(material)
}
</script>

<template>
  <div class="video-template-selection">
    <el-dialog
      v-model="dialogVisible"
      :title="t('选择AI声音')"
      width="912px"
      draggable
      align-center
      @open="getData"
      @close="stopAllSound"
    >
      <el-form inline>
        <el-form-item :label="t('筛选')">
          <el-select v-model="tagFiler" multiple clearable @change="onFilterChange">
            <el-option-group v-for="(group, index) in tagGroupList" :key="index" :label="group">
              <el-option
                v-for="tag in tagList[index]"
                :key="tag"
                :label="tag"
                :value="tag"
                :disabled="groupWithSelected(index, tag)"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="openCloneVoice" v-if="onOpenCloneVoice">
            {{ t('声音克隆') }}
          </el-button>
        </el-form-item>
      </el-form>

      <div class="lists" v-loading="isLoading">
        <div
          class="list-item-wrapper"
          :class="{ selected: isInSelectedList(item) }"
          v-for="(item, index) in dataListDisplay"
          :key="item.id"
          @click="selectItem(item)"
        >
          <SoundPlayer
            :ref="(el) => (refs[index] = el as unknown as typeof SoundPlayer)"
            :src="item.audio"
            @play="stopAllSound(item.id)"
            :id="item.id"
          />
          <div class="list-item-content">
            <div class="list-item-title">{{ item.name }}</div>
            <div class="list-item-tags">
              <el-tag
                type="info"
                effect="plain"
                round
                v-for="(tag, index) in item.tags"
                :key="index"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>

      <el-empty class="g-empty" v-if="!isLoading && !dataListDisplay.length">
        <template #image>
          <el-icon><IconEmpty /></el-icon>
        </template>
      </el-empty>
      <el-pagination
        class="g-pagination"
        :background="background"
        v-model:page-size="pageSize"
        :total="total"
        :pager-count="pagerCount"
        v-model:current-page="currentPage"
        :layout="layout"
        :page-sizes="pageSizes"
        :disabled="isLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="total > pageSizes[0]"
      />
      <template #footer>
        <el-button @click="selectedCancel">{{ t('取消') }}</el-button>
        <el-button type="primary" :disabled="!selectedList.length" @click="selectedDone">
          <span>{{ t('选择') }}</span>
          <span v-if="selectedList.length && props.limit > 1">({{ selectedList.length }})</span>
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.video-template-selection {
  .el-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .el-form-item {
      &:first-child {
        min-width: 300px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .list-item-wrapper {
    display: flex;
    align-items: center;
    height: 76px;
    border: 1px solid var(--my-color-black-20);
    border-radius: 8px;
    gap: 12px;
    padding: 0 14px;

    .list-item-content {
      font-family:
        SF Pro Text,
        var(--el-font-family);

      .list-item-title {
        font-size: 16px;
        line-height: 22px;
        color: var(--my-color-black-89-90);
      }

      .list-item-tags {
        font-size: 14px;
        line-height: 20px;
        color: var(--my-color-black-40);
        margin-top: 6px;

        .el-tag {
          transition: none;

          &:not(:first-child) {
            margin-left: 4px;
          }
        }
      }
    }
  }

  .lists {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .selected {
      border: 1px solid var(--el-color-primary);
    }

    .list-item-wrapper {
      min-width: 390px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .video-template-selection {
    .lists {
      display: block;
      height: auto;

      .list-item-wrapper {
        min-width: unset;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>

<style lang="less">
@media screen and (max-width: 768px) {
  .video-template-selection {
    .el-overlay {
      z-index: 2230 !important;
    }
    .el-dialog__header {
      position: sticky;
      top: 0;
      background: #fff;
      padding: 12px 0;
      z-index: 1;
    }
    .el-dialog__footer {
      position: sticky;
      bottom: 0;
      background: #fff;
      padding: 12px 0;
      z-index: 1;
    }
  }
}
</style>
