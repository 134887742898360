import { getCookie, getDefaultBrowserLanguage } from '@/utils/index'
import { config as localeConfig } from '@/locales'

export const formatUserTime = (createTime: string | null = '') => {
  if (!createTime) return '-'
  const timeString = (createTime || '').replace(' ', 'T').split('.')[0] + '+00:00'
  const date = new Date(timeString)

  // type can't be imported: DateTimeFormatOptions
  const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  const locale =
    getCookie(localeConfig.localeCookieName) || getDefaultBrowserLanguage(localeConfig.disabled)
  const parts = new Intl.DateTimeFormat(locale, options).formatToParts(date)
  let year, month, day, hour, minute

  parts.forEach((part) => {
    if (part.type === 'year') year = part.value
    else if (part.type === 'month') month = part.value
    else if (part.type === 'day') day = part.value
    else if (part.type === 'hour') hour = part.value
    else if (part.type === 'minute') minute = part.value
  })

  return `${year}/${month}/${day} ${hour}:${minute}`
}
