<script lang="ts" setup>
import LogoIcon from '@/assets/logo.svg'
import DashboardMenuSideBar from '@/views/Dashboard/components/DashboardMenuSideBar.vue'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'
import router from '@/router'
import { ref } from 'vue'
import HomeIcon from '@/components/icons/IconHome.vue'
import ProductsIcon from '@/components/icons/IconAllProducts.vue'
import TasksIcon from '@/components/icons/IconMyTasks.vue'

const { isMobile } = useClientInfo()
</script>

<template>
  <!--  web: side menu-->
  <div class="command-menu" v-if="!isMobile">
    <div class="logo" @click="router.push({ name: 'home' })">
      <img :src="LogoIcon" alt="" />
    </div>
    <DashboardMenuSideBar />
  </div>
</template>

<style scoped>
.command-menu {
  padding: 22px 20px;
  width: 240px;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 2;

  .logo {
    cursor: pointer;
    img {
      width: 98px;
      height: 32px;
    }

    margin-left: 16px;
    margin-bottom: 38px;
  }

  border-right: 1px #eee solid;
}
</style>
