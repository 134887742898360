<script setup lang="ts"></script>

<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.39258 4.71845C7.80677 4.16541 8.45733 3.83984 9.14828 3.83984H16.8568C17.5478 3.83984 18.1983 4.16541 18.6125 4.71845L22.4358 9.82363C22.7138 10.1948 22.6889 10.7111 22.3764 11.0537L14.1922 20.0291C13.5667 20.7151 12.4873 20.7164 11.8601 20.0319L3.63246 11.0524C3.31862 10.7099 3.29301 10.1925 3.57149 9.82064L7.39258 4.71845ZM9.14828 2.08984C7.90607 2.08984 6.73648 2.67515 5.99185 3.66943L2.17076 8.77162C1.38783 9.81703 1.45984 11.2717 2.34219 12.2346L10.5698 21.2141C11.8917 22.6569 14.1668 22.6541 15.4853 21.2082L23.6695 12.2328C24.5479 11.2695 24.618 9.81805 23.8366 8.77461L20.0132 3.66943C19.2686 2.67515 18.099 2.08984 16.8568 2.08984H9.14828ZM10.5506 8.91644C10.2089 8.57473 9.65487 8.57473 9.31317 8.91644C8.97146 9.25815 8.97146 9.81217 9.31317 10.1539L12.3816 13.2223C12.7233 13.5641 13.2774 13.5641 13.6191 13.2223L16.6875 10.1539C17.0293 9.81217 17.0293 9.25815 16.6875 8.91644C16.3458 8.57473 15.7918 8.57473 15.4501 8.91644L13.0004 11.3662L10.5506 8.91644Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="less"></style>
