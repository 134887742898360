<script setup lang="ts">
import i18nHome from '@/locales/i18n-home'

const { t } = i18nHome.global
</script>

<template>
  <div class="block block-slogan">
    <div class="content">
      <div class="beta-flag">
        <div class="plan-info-wrapper">
          <div class="plan-info-content">{{ t('Beta Pricing') }}</div>
        </div>
      </div>
      <div class="title">{{ t('Pricing Plans') }}</div>
      <div class="subtitle">
        {{ t('Discover the perfect fit for your needs - our pricing plans have you covered.') }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.block-slogan {
  padding: 10px 0 40px;

  .content {
    width: 1120px;
    margin: 0 auto;

    .beta-flag {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      .plan-info-wrapper {
        --linear-gradient: linear-gradient(
          251.55deg,
          #ff763c -18.91%,
          #ff3c82 16.21%,
          #7b4dff 61.82%
        );

        //border: 0.5px solid transparent;
        //border-image-source: var(--linear-gradient);
        //border-image-slice: 1;
        width: 96px;
        height: 26px;
        background: url(@/assets/home/border.svg);
        text-align: center;

        .plan-info-content {
          font-family:
            SF Pro Text,
            var(--el-font-family);
          font-size: 14px;
          line-height: 18px;
          //padding: 4px 8px;
          padding: 4px 0;

          background: var(--linear-gradient);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }
      }
    }

    .title {
      font-size: 60px;
      font-weight: 700;
      line-height: 66px;
      text-align: center;
      color: var(--my-color-black-89-90);

      &,
      > div {
        font-family:
          Alimama FangYuanTi VF,
          '阿里妈妈方圆体 VF',
          var(--el-font-family);
      }
    }

    .subtitle {
      margin: 16px auto 0;

      font-size: 24px;
      line-height: 34px;
      opacity: 0.6;
      color: var(--my-color-black-89-90);
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .block {
    .content {
      width: calc(100% - var(--el-main-padding) * 2);
      padding-left: var(--el-main-padding);
      padding-right: var(--el-main-padding);
    }
  }

  .block-slogan {
    padding: 10px 0 30px;

    .content {
      .beta-flag {
        margin-bottom: 0;
      }

      .title {
        font-size: 36px;
      }

      .subtitle {
        margin: 12px auto 0;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
</style>
