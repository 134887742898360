<script setup lang="ts">
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import IconClose from '@/components/icons/IconClose.vue'
import { useI18n } from 'vue-i18n'
import { videoDownload } from '@/common'
import { type CSSProperties, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useClientInfo } from '@/views/Dashboard/hooks/useClientInfo'

const props = defineProps<{
  show: boolean
  videoUrl?: string
  posterUrl?: string
  title?: string
  description?: string
  autoplay?: boolean
  enableDownload?: boolean
  styles?: CSSProperties
  objectFit?: 'cover' | 'contain'
}>()

const { t } = useI18n()
const { isMobile } = useClientInfo()
const emit = defineEmits(['close'])

const downloading = ref(false)
const handleDownload = async () => {
  if (props.enableDownload) {
    downloading.value = true
    try {
      videoDownload(props.videoUrl, Date.now() + '-' + props.title, true)
    } finally {
      setTimeout(() => {
        // 在移动设备浏览器上面会有一些阻断确认和明显的下载标识，提示不是很有必要，而且容易在确认之前就提示了
        !isMobile && ElMessage.success('Download succeed!')
        downloading.value = false
      }, 3000)
    }
  }
}
</script>

<template>
  <el-dialog
    :model-value="show"
    :title="title"
    width="760"
    @close="emit('close')"
    :show-close="false"
    :fullscreen="isMobile"
    :modal-class="isMobile ? 'video-preview-container mobile' : 'video-preview-container'"
  >
    <template #header>
      <div class="video-preview-dialog-header">
        <span class="title">{{ title }}</span>
        <IconClose @click="emit('close')" class="close-icon" />
      </div>
    </template>
    <template #footer>
      <div class="video-preview-dialog-footer">
        <el-button
          type="primary"
          @click="handleDownload"
          v-if="enableDownload"
          :loading="downloading"
          :disabled="downloading"
          >{{ t('下载') }}</el-button
        >
        <slot name="footer" />
      </div>
    </template>
    <div class="video-preview-dialog-content" :class="objectFit || 'contain'" :style="styles">
      <VideoPlayer
        :src="videoUrl"
        :posterUrl="posterUrl"
        :controls="true"
        :autoplay="autoplay"
        class="video-preview-dialog-player"
        :plays-inline="isMobile"
      />
      <div v-if="description" class="video-desc">{{ description }}</div>
    </div>
  </el-dialog>
</template>

<style lang="less">
.video-preview-container {
  .el-dialog__header {
    padding-bottom: 12px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-dialog__footer {
    padding-top: 32px;
  }
}
.video-preview-dialog-header {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  align-items: center;

  .title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close-icon {
    cursor: pointer;
  }
}

.video-preview-dialog-footer {
  display: flex;
  justify-content: center;
}

.video-preview-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1;

  .v-video-player {
    background-color: #f0f0f0; /* 修改为你想要的颜色 */
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &.cover {
    .v-video-player {
      .vjs-tech {
        object-fit: cover; /* 设置object-fit */
      }
    }
  }

  &.contain {
    .v-video-player {
      .vjs-tech {
        object-fit: contain; /* 设置object-fit */
      }
    }
  }

  .vjs-big-play-button {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .video-desc {
    margin-top: 16px;
    font-size: 14px;
    color: var(--my-color-black-90);
  }
}

.video-preview-container.mobile {
  .is-fullscreen {
    border-radius: 0;
    padding-top: calc(env(safe-area-inset-top) + 74px);
  }
}
</style>
